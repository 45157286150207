import { Hook, Unhook } from "console-feed";
import { useEffect } from "react";
import useConsoleFeedStore from "./consoleFeedStore";

/**
 * Place this in to the root of app to start capturing logs as early as possible.
 * Then use ConsoleFeedLog component to display the logs.
 */
const ConsoleFeedHook = () => {
  const actions = useConsoleFeedStore((state) => state.actions);

  useEffect(() => {
    const hookedConsole = Hook(window.console, (log) => actions.add(log), false);
    return () => {
      Unhook(hookedConsole);
    };
  }, [actions]);

  return null;
};

export default ConsoleFeedHook;
