import Player from "../Player";
import PlayersManager, { PlayersManagerEvents } from "../PlayersManager";

export type HostPlayersManagerEvents = PlayersManagerEvents;

class HostPlayersManager extends PlayersManager<HostPlayersManagerEvents> {
  private static playerIdCounter = 1; // 0 is reserved for host

  createPlayer(playerName: string, peerId: string): Player {
    return new Player(HostPlayersManager.playerIdCounter++, playerName, peerId);
  }

  addPlayer(player: Player): void {
    if (this.getPlayerByPlayerId(player.getPlayerId())) {
      throw new Error("Player with id already exists");
    }
    if (this.getPlayerByName(player.getName())) {
      throw new Error("Player with name already exists");
    }
    if (this.getPlayerByPeerId(player.getPeerId())) {
      throw new Error("Player with peerId already exists");
    }

    this.players.push(player);
    this.dispatchChange();
  }

  setPlayerInactive(player: Player): void {
    player.setActive(false);
    this.dispatchChange();
  }

  setPlayerActive(player: Player): void {
    player.setActive(true);
    this.dispatchChange();
  }
}

export default HostPlayersManager;
