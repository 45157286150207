import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import GameItem from "./GameItem";
import { GAME_ENTRY_DATA } from "../../../../common/client/constants";
import * as arrayUtils from "../../../../common/utils/array";
import { GameEntryData } from "../../../../common/client/GameEntry";
import { IS_DEV } from "../../../constants";

const ITEMS_PER_SLIDE = 4;

const gameEntryData = !IS_DEV ? GAME_ENTRY_DATA.filter((ge) => !["testgame"].includes(ge.id)) : GAME_ENTRY_DATA;

const GameItems = () => {
  const navigate = useNavigate();
  const handleGameSelect = (gameEntryData: GameEntryData) => {
    navigate(`/host/games/${gameEntryData.id}`);
  };

  return (
    <div className="relative flex h-full w-full overflow-hidden">
      <Swiper
        className="absolute bottom-[50px] left-[155px] right-[155px] top-[50px]"
        allowTouchMove={false}
        slidesPerView="auto"
        spaceBetween={20}
        direction="vertical"
      >
        {arrayUtils.chunk(gameEntryData, ITEMS_PER_SLIDE).map((items, i) => (
          <SwiperSlide key={i}>
            <div className="flex">
              {items.map((gameEntryData, i2) => (
                <GameItem
                  key={gameEntryData.id}
                  swiperIndex={i}
                  forceFocus={i === 0 && i2 === 0}
                  gameEntryData={gameEntryData}
                  onSelect={() => handleGameSelect(gameEntryData)}
                />
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GameItems;
