import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { DataConnection, PeerError } from "peerjs";
import { CLIENT_CONNECTION_STATUS } from "./constants";
import { PlayerClientConnectionStats } from "./player/PlayerClientConnection";

export type ClientConnectionStatus = (typeof CLIENT_CONNECTION_STATUS)[keyof typeof CLIENT_CONNECTION_STATUS];

export type ConnectedEventData = {
  metadata: unknown;
};

export type ConnectionErrorEventData = PeerError<
  "not-open-yet" | "message-too-big" | "negotiation-failed" | "connection-closed"
>;

export type DataEventData = unknown;

export type StatusChangeEventData = {
  status: ClientConnectionStatus;
};

export interface ClientConnectionEvents extends DefaultListener {
  connection_data: (data: DataEventData) => void;
  connection_error: (error: ConnectionErrorEventData) => void;
  status_change: (data: StatusChangeEventData) => void;
  disconnect: (data: { clientConnection: ClientConnection }) => void;
  connected: (data: ConnectedEventData) => void;
  error: (error: Error) => void;
  peer_connection_stats: (data: PlayerClientConnectionStats) => void;
}

class ClientConnection<T extends ClientConnectionEvents = ClientConnectionEvents> extends EventEmitter<T> {
  protected connectionStatus: ClientConnectionStatus = CLIENT_CONNECTION_STATUS.DISCONNECTED;
  protected reliableConnection: DataConnection | null = null;
  protected unreliableConnection: DataConnection | null = null;
  private lastReceivedDataTime = 0;
  private connectionInactiveThresholdTime = 5000;

  private monitorConnectionActivityInterval: ReturnType<typeof setInterval> | null = null;

  constructor() {
    super();
    this.lastReceivedDataTime = Date.now();
    this.startMonitoringConnectionActivity();
  }

  protected debug(...args: unknown[]) {
    console.debug("ClientConnection -", ...args);
  }

  protected setupCommonConnectionCallbacks(connection: DataConnection) {
    connection.on("data", (data) => {
      this.lastReceivedDataTime = Date.now();
      if (this.connectionStatus === CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE) {
        this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE);
      }

      this.dispatch("connection_data", data);
    });

    connection.on("error", (error) => {
      this.debug("Connection error", error);
      this.dispatch("connection_error", error);
    });

    connection.on("close", () => {
      this.debug("Connection closed");
      this.disconnect();
    });
  }

  protected setStatus(status: ClientConnectionStatus) {
    this.debug(`setStatus() - status: ${this.connectionStatus} -> ${status}`);

    if (this.connectionStatus === status) {
      console.warn("setConnectionStatus - status already set to ", status);
      return;
    }
    this.connectionStatus = status;
    this.dispatch("status_change", { status });
  }

  private startMonitoringConnectionActivity() {
    /**
     * This monitoring relies on the fact that there is a ping check or heartbeat that
     * continuously sends data to the client. If the client does not receive any data
     * for a certain amount of time, it is considered inactive.
     *
     * We can then inform users that player X has inactive connection so they can reconnect
     * or check that the phone is in a state where it can receive/send data
     * (e.g. screen awake and GamingCouch app is in the foreground).
     */
    this.monitorConnectionActivityInterval = setInterval(() => {
      if (this.connectionStatus !== CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE) {
        return;
      }

      const timeSinceLastData = Date.now() - this.lastReceivedDataTime;
      const isActive = timeSinceLastData <= this.connectionInactiveThresholdTime;
      if (!isActive && this.connectionStatus === CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE) {
        this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE);
      }
    }, 1000);
  }

  disconnect() {
    this.debug("disconnect()");

    this.reliableConnection?.close();
    this.reliableConnection = null;

    this.unreliableConnection?.close();
    this.unreliableConnection = null;

    if (this.connectionStatus !== CLIENT_CONNECTION_STATUS.DISCONNECTED) {
      this.setStatus(CLIENT_CONNECTION_STATUS.DISCONNECTED);
      this.dispatch("disconnect", { clientConnection: this });
    }
  }

  sendMessage(message: unknown, reliable = true) {
    const connection = reliable ? this.reliableConnection : this.unreliableConnection;

    if (!connection) {
      throw new Error(reliable ? "Reliable connection not initialized" : "Unreliable connection not initialized");
    }

    void connection.send(message);
  }

  onConnected(callback: (data: ConnectedEventData) => void) {
    return this.on("connected", (data) => {
      callback({ metadata: data.metadata });
    });
  }

  onDisconnected(callback: () => void) {
    return this.on("disconnect", callback);
  }

  onError(callback: (error: ConnectionErrorEventData) => void) {
    return this.on("connection_error", (error) => {
      callback(error);
    });
  }

  onData(callback: (data: DataEventData) => void) {
    return this.on("connection_data", (data) => {
      callback(data);
    });
  }

  onStatusChange(callback: (data: StatusChangeEventData) => void) {
    return this.on("status_change", (data) => {
      callback(data);
    });
  }

  getStatus() {
    return this.connectionStatus;
  }

  destroy() {
    this.debug("destroy()");

    if (this.monitorConnectionActivityInterval) {
      clearInterval(this.monitorConnectionActivityInterval);
    }
  }
}

export default ClientConnection;
