import { FrameEventData, FrameEventMessage } from "@shared/src/gc/events/FrameEventEmitter";
import { ScreenOrientationMode } from "@shared/src/gc/types";
import { BaseClientType } from "../../BaseClient";
import ClientPlugin, { ClientPluginEvents } from "../ClientPlugin";
import { IS_DEV } from "./../../../../domain/constants";

const FORCE_ORIENTATION_CLASS = "force-orientation";

const forceOrientationStyles = `
html.${FORCE_ORIENTATION_CLASS} {
  transform: rotate(-90deg);
  transform-origin: left top;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100dvh;
  height: 100dvw;
}

html.${FORCE_ORIENTATION_CLASS} body {
  width: 100dvh;
  height: 100dvw;
}
`;

export type ForceScreenOrientationPluginEvents = ClientPluginEvents & {
  mode_change: (data: { mode: ScreenOrientationMode }) => void;
};

class ForceScreenOrientationPlugin extends ClientPlugin<ForceScreenOrientationPluginEvents> {
  private mode: ScreenOrientationMode = "none";

  constructor() {
    super("ForceScreenOrientationPlugin");
  }

  setup(client: BaseClientType): void {
    super.setup(client);

    const style = document.createElement("style");
    style.innerHTML = forceOrientationStyles;
    document.head.appendChild(style);

    window.addEventListener("resize", this.handleModeChange);
    this.handleModeChange();
  }

  private handleModeChange = () => {
    if (this.mode === "none") {
      document.documentElement.classList.remove(FORCE_ORIENTATION_CLASS);
      return;
    }

    const width = window.innerWidth;
    const height = window.innerHeight;

    const condition = this.mode === "portrait" ? width > height : width < height;

    if (condition) {
      document.documentElement.classList.add(FORCE_ORIENTATION_CLASS);
    } else {
      document.documentElement.classList.remove(FORCE_ORIENTATION_CLASS);
    }

    try {
      void screen.orientation?.lock(this.mode === "portrait" ? "portrait-primary" : "landscape-primary").then(
        () => {},
        (failure) => !IS_DEV && console.warn(failure),
      );
    } catch (e) {
      console.warn("Failed to lock screen orientation", e);
    }
  };

  handleFrameEvent = (frameEvent: FrameEventMessage) => {
    switch (frameEvent.eventName) {
      case "force_screen_orientation": {
        const mode = frameEvent.data as FrameEventData<"force_screen_orientation">;
        this.setMode(mode);
        break;
      }
    }
  };

  setMode(mode: ScreenOrientationMode) {
    this.mode = mode;
    this.handleModeChange();
    this.dispatch("mode_change", { mode: mode });
  }

  getMode(): ScreenOrientationMode {
    return this.mode;
  }
}

export default ForceScreenOrientationPlugin;
