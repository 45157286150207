/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import YouTube, { YouTubePlayer } from "react-youtube";

import { createContext, useState } from "react";
import { assert } from "../../../common/logger/assert";

const videos = Object.freeze([
  {
    videoId: "j5vmhDCDDno",
    chapters: [
      {
        title: "Infraction - Disco Nights",
        timestamp: 0,
      },
    ],
  },
]);

// const videosPhonk = Object.freeze([
//   {
//     videoId: "ZjSe25Kw_aM",
//     chapters: [
//       {
//         title: "HXVRMXN - CHIMERA ft. LxrdOfDoоm",
//         timestamp: 0,
//       },
//       {
//         title: "ARTHUR DISTONE - ERROR",
//         timestamp: 207,
//       },
//       {
//         title: "SAKVREYE - ＳＴＲＯＢＥ ストロボ",
//         timestamp: 367,
//       },
//       {
//         title: "RYDER SPOT - PURSUIT",
//         timestamp: 495,
//       },
//       {
//         title: "ANTXRES - KARMA",
//         timestamp: 685,
//       },
//       {
//         title: "LXVIATHXN - SOLAR",
//         timestamp: 887,
//       },
//       {
//         title: "KEDELA - ETERNAL PAST",
//         timestamp: 1046,
//       },
//       {
//         title: "GRAVECHILL - GOD IS DEАD",
//         timestamp: 1182,
//       },
//       {
//         title: "DCLXVI - GLOW",
//         timestamp: 1380,
//       },
//       {
//         title: "FINIVOID - INFINITE",
//         timestamp: 1548,
//       },
//       {
//         title: "1ODUM DEFECT - SKY WAY /w LXVIATHXN",
//         timestamp: 1680,
//       },
//       {
//         title: "BLADEFEAR - FALLE",
//         timestamp: 1852,
//       },
//       {
//         title: "Dartul - Sirius.",
//         timestamp: 2062,
//       },
//       {
//         title: "HXVRMXN - HAVOC ft. bonеles_s",
//         timestamp: 2270,
//       },
//       {
//         title: "ANTICXLTXRE - UNPRESENCE",
//         timestamp: 2393,
//       },
//       {
//         title: "LVTE BLOOMER - SADNESS AND SORROW",
//         timestamp: 2518,
//       },
//       {
//         title: "CASPER - IMMINENT",
//         timestamp: 2726,
//       },
//       {
//         title: "SXLECT - FLUID",
//         timestamp: 2875,
//       },
//       {
//         title: "DVRST - UPSILON",
//         timestamp: 3025,
//       },
//       {
//         title: "FRXXMAN - Beyond",
//         timestamp: 3188,
//       },
//       {
//         title: "NANEBO - TIME",
//         timestamp: 3303,
//       },
//       {
//         title: "l19u1d - INCEPTION",
//         timestamp: 3433,
//       },
//       {
//         title: "SCXR SOUL - STORM",
//         timestamp: 3651,
//       },
//       {
//         title: "V21 - CARBON",
//         timestamp: 3801,
//       },
//     ],
//   },
// ]);

type VideoType = (typeof videos)[0];
type ChapterType = (typeof videos)[0]["chapters"][0];

export const RadioContext = createContext<{
  isLoading: boolean;
  play: () => void;
  toggleMute: () => Promise<void>;
  isMuted: boolean;
  isEnabled: boolean;
}>({
  isLoading: true,
  play: () => {},
  toggleMute: async () => {},
  isMuted: false,
  isEnabled: true,
});

const RadioProvider = ({ children, isEnabled = true }: React.PropsWithChildren<{ isEnabled: boolean }>) => {
  const [video] = useState<VideoType>(videos[0]);
  const [chapter] = useState<ChapterType>(video.chapters[Math.floor(Math.random() * videos[0].chapters.length)]);
  const [player, setPlayer] = useState<YouTubePlayer>(null);
  const [isClickOverlayActive, setIsClickOverlayActive] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const isLoading = !player;

  const play = () => {
    assert(player, "Player is not ready");
    void player.seekTo(chapter.timestamp, true);
    void player.playVideo();

    isClickOverlayActive && setIsClickOverlayActive(false);
  };

  const toggleMute = async () => {
    assert(player, "Player is not ready");
    const isMuted = await player.isMuted();
    void player[isMuted ? "unMute" : "mute"]();
    setIsMuted(!isMuted);
  };

  const contextValue = {
    isLoading,
    play,
    toggleMute,
    isMuted,
    isEnabled: isEnabled,
  };

  if (!isEnabled) {
    return <RadioContext.Provider value={contextValue}>{children}</RadioContext.Provider>;
  }

  return (
    <>
      <YouTube
        videoId={video.videoId}
        id="radio"
        className="hidden"
        onReady={(event) => {
          void event.target.playVideo();
          setPlayer(event.target);
        }}
        onStateChange={(event) => {
          if (event.data === 0) {
            void event.target.playVideo(); // replay
          }
        }}
      />
      {!isLoading && isClickOverlayActive && (
        <div
          className="absolute inset-0 z-50 bg-slate-300 opacity-0"
          onClick={() => {
            play();
          }}
        />
      )}
      <RadioContext.Provider value={contextValue}>{children}</RadioContext.Provider>
    </>
  );
};

export default RadioProvider;
