import Player from "../../../../../common/client/Player";
import { CLIENT_CONNECTION_STATUS } from "../../../../../common/client/constants";
import HostClient from "../../../../../common/client/host/HostClient";
import { assert } from "../../../../../common/logger/assert";
import PlayerCard from "../../../player/PlayerCard";
import PlayerCardStats from "../../../player/PlayerCardStats";

interface Props {
  player: Player;
}

const HeaderPlayerCard = ({ player }: Props) => {
  const playerStatsByPlayerId = HostClient.party.partyStats.getStats();
  const stats = playerStatsByPlayerId.get(player.getPlayerId());
  const isPartyLeader = HostClient.party.isPartyLeader(player);
  assert(stats, "expected stats");

  const isInactive =
    HostClient.getPlayerConnectionStatus(player.getPlayerId()) === CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE;

  return (
    <PlayerCard player={player} isInactive={isInactive} isPartyLeader={isPartyLeader}>
      <PlayerCardStats stats={stats} />
    </PlayerCard>
  );
};

export default HeaderPlayerCard;
