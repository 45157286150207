import { HostContext } from "./HostProvider";

import { useContext } from "react";
import { useStore } from "zustand";
import { HostState } from "./createHostStore";

export function useHostStore<T>(selector: (state: HostState) => T): T {
  const store = useContext(HostContext);
  if (!store) throw new Error("Missing HostContext.Provider in the tree");
  return useStore(store, selector);
}
