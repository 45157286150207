import { useEffect } from "react";
import { usePrevious } from "react-use";
import { H4 } from "../../../common/ui/heading/Heading";
import { TIP_TYPE } from "./constants";
import { Tip } from "./types";
import { useHostActions } from "../useHostActions";
import { useHostStore } from "../useHostStore";

function getTipTextElement(tip: Tip) {
  switch (tip.type) {
    case TIP_TYPE.INFO:
      return <H4>{tip.text}</H4>;
    case TIP_TYPE.WARNING:
      return <H4 className="animate-pulse text-orange-500 duration-1000">{tip.text}</H4>;
    case TIP_TYPE.ERROR:
      return <H4 className="text-red-500">{tip.text}</H4>;
    default:
      throw new Error(`Unknown tip type: ${(tip as { type?: string }).type || "undefined"}`);
  }
}

const TipOverlay = () => {
  const actions = useHostActions();
  const tip = useHostStore((state) => state.tip);
  const previousTip = usePrevious<Tip | null>(tip || null); // keep book of the prev tip as we animate out

  useEffect(() => {
    if (tip) {
      const timeout = setTimeout(() => {
        actions.clearTip();
      }, tip.timeMs);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tip, actions]);

  if (!tip && !previousTip) {
    return null;
  }

  const isVisible = tip ? true : false;
  const highlight = tip?.highlight;

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div
        className={`absolute bottom-0 left-0 right-0 z-tipOverlay h-[86px] translate-y-[100%] flex-col justify-center bg-indigo-950 transition-transform ${isVisible ? "animate-[yTo0_.1s_ease-out_forwards]" : "animate-[yTo100_.1s_ease-in_forwards]"}`}
      >
        <div className={`flex h-full items-center justify-center ${highlight ? "animate-pulse duration-500" : ""}`}>
          {getTipTextElement((tip || previousTip) as Tip)}
        </div>
      </div>
    </div>
  );
};

export default TipOverlay;
