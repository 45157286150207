import React, { useState } from "react";
import Button from "../../../common/ui/button/Button";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  isRound?: boolean;
}

const ControllerButton = ({ children, onClick, isRound }: Props) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <Button
      isRound={isRound}
      pressed={isPressed}
      onPointerDown={() => {
        setIsPressed(true);
        onClick();
      }}
      onPointerUp={() => {
        setIsPressed(false);
      }}
      onPointerLeave={() => {
        setIsPressed(false);
      }}
    >
      {children}
    </Button>
  );
};

export default ControllerButton;
