import { useEffect, useRef } from "react";
import { usePlayerStore } from "../../usePlayerStore";
import { getSeverityIndex } from "./playerLatency";
import { LATENCY_SEVERITY_COLORS } from "./constants";
import { CircularArray } from "../../../../common/dataStructures/CircularArray";
import { DataPoint } from "../../../../common/client/plugins/latency/types";

const drawLatencyHistoryGraph = (
  canvas: HTMLCanvasElement,
  maxPoints: number,
  dataPoints: CircularArray<DataPoint>,
) => {
  const context = canvas.getContext("2d");
  if (!context) {
    return;
  }

  const stepXValue = 1000;
  const stepXWidth = canvas.width / maxPoints;
  const yMaxValue = 1000;
  const widthX = maxPoints * stepXWidth;
  let offsetX = -1;

  context.clearRect(0, 0, canvas.width, canvas.height);

  const startTime = dataPoints[0].timestamp;

  let prevPositionX = -1;
  let prevPositionY = -1;

  for (let i = dataPoints.length - 1; i >= 0; i--) {
    const dataPoint = dataPoints[i];
    const latency = dataPoint.latency;
    const timestamp = dataPoint.timestamp;

    let positionX = stepXWidth * ((timestamp - startTime) / stepXValue);

    // we need largest x pos to offset the graph in order to fill graph from right hand side
    if (i === dataPoints.length - 1) {
      offsetX = positionX;
    }

    // fill graph from right hand side
    positionX = widthX - offsetX + positionX;

    context.beginPath();
    context.moveTo(prevPositionX, prevPositionY);

    const positionY = canvas.height - (Math.min(yMaxValue, latency) / yMaxValue) * canvas.height;

    if (i === dataPoints.length - 1) {
      context.moveTo(positionX, positionY);
    } else {
      context.lineTo(positionX, positionY);
    }

    context.strokeStyle = LATENCY_SEVERITY_COLORS[getSeverityIndex(latency)];
    context.lineWidth = 2;
    context.stroke();

    prevPositionX = positionX;
    prevPositionY = positionY;
  }

  context.closePath();

  context.fillStyle = "gray";
  context.font = "12px Arial";
  context.textAlign = "center";

  // Y axis legend
  for (let i = 0; i < 6; i++) {
    const ms = i * 200;
    context.fillText(`${ms}ms`, 20, canvas.height - (canvas.height / 5) * i - 3);

    context.beginPath();
    context.moveTo(0, canvas.height - (canvas.height / 5) * i);
    context.lineTo(canvas.width, canvas.height - (canvas.height / 5) * i);
    context.strokeStyle = "gray";
    context.lineWidth = 1;
    context.stroke();
  }
};

const PlayerLatencyHistory = () => {
  const networkLatency = usePlayerStore((state) => state.networkLatency);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    if (!networkLatency.dataPoints) {
      return;
    }

    const maxPoints = networkLatency.dataPoints.size;

    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    drawLatencyHistoryGraph(canvas, maxPoints, networkLatency.dataPoints);
  }, [networkLatency]);

  return <canvas ref={canvasRef} className="z-latencyIndicator h-[200px] w-full bg-black bg-opacity-50" />;
};

export default PlayerLatencyHistory;
