import { H3 } from "../../../common/ui/heading/Heading";

const PlayerSetupGameView = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black">
      <H3>Preparing game...</H3>
    </div>
  );
};

export default PlayerSetupGameView;
