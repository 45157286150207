import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GameEntry from "../../../../common/client/GameEntry";
import PlaylistBlueprint from "../../../../common/client/plugins/playlist/PlaylistBlueprint";
import { assert } from "../../../../common/logger/assert";
import { H3 } from "../../../../common/ui/heading/Heading";
import { IS_DEV } from "../../../constants";
import { playSound, SOUNDS } from "../../../sounds/ui/sounds";
import CoverImageSwap from "../../animation/CoverImageSwap";
import TvNoiseEffectOverlay from "../../animation/TvNoiseEffectOverlay";
import Spinbox from "../../spatialNavigation/ui/Spinbox";
import { TIP_TYPE } from "../../tip/constants";
import { useHostActions } from "../../useHostActions";
import { useHostStore } from "../../useHostStore";
import DetailsBoxes from "../DetailsBoxes";
import useGameEntryCarousel from "./../useGameEntryCarousel";
import GameEntryRow from "./GameEntryRow";
import StartButton from "./StartButton";

interface Props {
  playlistBlueprint: PlaylistBlueprint;
  options?: {
    warmupRound?: "on" | "off";
    randomModifiers?: "on" | "off";
    gameRounds?: number;
  };
}

const PlaylistPage = ({ playlistBlueprint, options }: Props) => {
  const navigate = useNavigate();
  const actions = useHostActions();
  const players = useHostStore((state) => state.players);
  const gameEntry = useGameEntryCarousel({
    enabled: true,
    playlistBlueprint,
    intervalMs: 4000,
  });

  const [warmupRound, setWarmupRound] = useState<string>(options?.warmupRound || IS_DEV ? "off" : "on");
  const [randomModifiers, setRandomModifiers] = useState<string>(options?.randomModifiers || "on");
  const [gameRounds, setGameRounds] = useState<number>(options?.gameRounds || playlistBlueprint.getNumberOfRounds());

  useEffect(() => {
    setFocus("startPlaylist");

    return () => {
      actions.clearTip();
    };
  }, [actions]);

  const showMinimumPlayersTip = () => {
    actions.setTip({
      text: `This playlist requires at least ${playlistBlueprint.getMinPlayers()} players.`,
      type: TIP_TYPE.WARNING,
    });
  };

  return (
    <div className="flex w-full flex-col justify-center">
      <div className="flex h-[100px] items-center justify-center bg-violet-700">
        <H3>{playlistBlueprint.getName()}</H3>
      </div>
      <div className="relative flex h-[690px] overflow-hidden bg-indigo-950">
        <div className={`absolute left-[150px] flex h-full w-full justify-center bg-contain bg-center bg-no-repeat`}>
          <div className="absolute flex aspect-video h-full overflow-hidden bg-[#17163c]">
            <CoverImageSwap src={gameEntry.getCoverImage()} />
            <TvNoiseEffectOverlay />
          </div>
          <div className="absolute left-[342px] h-full w-[300px] bg-gradient-to-r from-indigo-950 to-transparent" />
          <div className="absolute right-[342px] flex h-full w-[300px] justify-end bg-gradient-to-r from-transparent to-indigo-950" />
        </div>
        <div className="absolute flex w-full">
          <div className="flex flex-1 flex-col">
            <div className="flex w-[600px] flex-col gap-2 p-4">
              {playlistBlueprint.getGameEntryIds().map((gameEntryId, index) => {
                const entry = GameEntry.getGameEntryById(gameEntryId);
                assert(entry, "expected gameEntry");
                return (
                  <GameEntryRow
                    key={index}
                    gameEntry={entry}
                    isSelected={entry.getGameId() === gameEntry.getGameEntryId()}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end px-2 py-12">
            <div className="bg-indigo-950 bg-opacity-40">
              <DetailsBoxes playlistBlueprint={playlistBlueprint} />
            </div>
          </div>
          <div className="m-10 flex w-[300px] flex-col space-y-6">
            <Spinbox
              label="Game rounds"
              value={gameRounds}
              values={Array.from({ length: 10 }, (_, i) => i + 1)}
              onChange={(value) => setGameRounds(value)}
              onFocus={() => {
                actions.setTip("Number of rounds for each game.");
              }}
            />
            <Spinbox<string>
              label="Warmup round"
              value={warmupRound}
              values={["on", "off"] as const}
              onChange={(value) => setWarmupRound(value)}
              onFocus={() => {
                actions.setTip("Apply one non scoring warmup round before each game.");
              }}
            />
            <Spinbox
              label="Random modifiers"
              value={randomModifiers}
              values={["on", "off"] as const}
              onChange={(value) => setRandomModifiers(value)}
              onFocus={() => {
                actions.setTip("Should randomized modifiers be applied occasionally?");
              }}
            />
            <StartButton
              focusKey="startPlaylist"
              onActionEnter={() => {
                if (players.length < playlistBlueprint.getMinPlayers()) {
                  playSound(SOUNDS.BUTTON_PRESS_INVALID);
                  showMinimumPlayersTip();
                  return;
                }
                playSound(SOUNDS.BUTTON_PRESS, { throttleMs: 50 });
                navigate("/host", { replace: true });
                actions.startPlaylistFromBlueprint(
                  new PlaylistBlueprint({
                    ...playlistBlueprint.toData(),
                    numberOfRounds: gameRounds,
                    isWarmupEnabled: warmupRound === "on",
                  }),
                );
              }}
              onFocus={() => {
                if (players.length < playlistBlueprint.getMinPlayers()) {
                  showMinimumPlayersTip();
                  return;
                }

                if (players.length > playlistBlueprint.getMaxPlayers()) {
                  actions.setTip({
                    text: `This playlist supports a maximum of ${playlistBlueprint.getMaxPlayers()} players.`,
                    type: TIP_TYPE.WARNING,
                  });
                  return;
                }

                actions.setTip("Start the playlist with the selected settings.");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistPage;
