import Player from "../../../common/client/Player";
import { AVATAR_BY_PLAYER_COLOR } from "./constants";

const PlayerPlacementCard = ({ player, placementNumber }: { player: Player; placementNumber: number }) => {
  const placementName = ["1st", "2nd", "3rd"] as const;

  const scaleByPlacement = [
    "scale-100",
    "scale-[85%]",
    "scale-75",
    "scale-50",
    "scale-50",
    "scale-50",
    "scale-50",
    "scale-50",
  ][placementNumber];

  return (
    <div className={`flex w-[280px] origin-bottom-left flex-col text-center ${scaleByPlacement}`}>
      <div className="h-14">
        {placementName[placementNumber] && (
          <span className="text-5xl font-bold shadow-black text-shadow">{placementName[placementNumber]}</span>
        )}
      </div>
      <div className={`relative flex w-full flex-col items-center`}>
        <div className={`absolute bg-player-${player.getColor()}-muted h-32 w-full`} />
        <img
          className="pointer-events-none relative top-4 h-36 w-[170px]"
          src={AVATAR_BY_PLAYER_COLOR[player.getColor()]}
          alt=""
        />
      </div>
      <div className="pt-6 text-4xl font-bold shadow-black text-shadow">{player.getName()}</div>
    </div>
  );
};

export default PlayerPlacementCard;
