import { LATENCY_SEVERITY_STEPS } from "./constants";

export const getSeverityIndex = (latency: number) => {
  let severityIndex = 0;

  for (let i = 0; i < LATENCY_SEVERITY_STEPS.length; i++) {
    if (latency < LATENCY_SEVERITY_STEPS[i]) {
      severityIndex = Math.max(severityIndex, i - 1);
      break;
    }

    severityIndex = i;
  }

  return severityIndex;
};
