import { HudConfig, HudScreenPointData, PlayersHudData } from "@shared/src/gc/hud/types";
import { createStore } from "zustand";
import { combine } from "zustand/middleware";
import GameEntry from "../../common/client/GameEntry";
import { PeerStatus } from "../../common/client/PeerManager";
import Player from "../../common/client/Player";
import HostClient, { SharedHostState } from "../../common/client/host/HostClient";
import { ClientConnectionStatus } from "../../common/client/ClientConnection";
import { assert } from "../../common/logger/assert";
import PlaylistBlueprint from "../../common/client/plugins/playlist/PlaylistBlueprint";
import { TIP_TYPE } from "./tip/constants";
import { Tip, TipType } from "./tip/types";
import { PLAYLIST_STATES } from "../../common/client/plugins/playlist/PlaylistHostPlugin";

export interface HostProps {
  playerCount: number;
  connectionCount: number;
  hostState: SharedHostState;
  gameEntry: GameEntry | null;
  players: Player[];
  clientConnectionStatusByPeerId: Record<string, ClientConnectionStatus>; // TODO: Move this connection status to player obj, so it's directly accessible via players and players changes?
  peerServerConnectionStatus: PeerStatus | null;
  waitingGameSetupPlayerIds: number[];
  hudConfig: HudConfig | null;
  playersHudData: PlayersHudData | null;
  hudScreenPointData: HudScreenPointData | null;
  tip: Tip | null;
  notifications: string[];
}

export interface HostState extends HostProps {
  actions: {
    startPlaylistFromBlueprint: (playlistBlueprint: PlaylistBlueprint) => void;
    setTip: (
      textOrOptions: string | { text: string; type?: TipType; timeMs?: number | null; highlight?: boolean },
    ) => void;
    clearTip: () => void;
    addNotification: (message: string) => void;
    clearFirstNotification: () => void;
  };
}

export type HostStore = ReturnType<typeof createHostStore>;

const createHostStore = () => {
  const DEFAULT_PROPS: HostProps = {
    playerCount: 0,
    connectionCount: 0,
    hostState: HostClient.getSharedState(),
    gameEntry: null,
    players: [],
    clientConnectionStatusByPeerId: {},
    waitingGameSetupPlayerIds: [],
    peerServerConnectionStatus: null,
    hudConfig: null,
    playersHudData: null,
    hudScreenPointData: null,
    tip: null,
    notifications: [],
  };

  return createStore<HostState>(
    combine(DEFAULT_PROPS, (setState, getState) => {
      HostClient.setup();
      window.hostClient = HostClient;

      const actions = {
        startPlaylistFromBlueprint(playlistBlueprint: PlaylistBlueprint) {
          HostClient.startPlaylistFromBlueprint(playlistBlueprint);
        },
        setTip(textOrOptions: string | { text: string; type?: TipType; timeMs?: number | null; highlight?: boolean }) {
          const inputOptions = typeof textOrOptions === "string" ? { text: textOrOptions } : textOrOptions;
          setState({
            tip: {
              text: inputOptions.text,
              type: inputOptions.type || TIP_TYPE.INFO,
              timeMs: inputOptions.timeMs === null ? 900000 : inputOptions.timeMs || 3000,
              highlight: inputOptions.highlight,
            },
          });
        },
        clearTip() {
          setState({
            tip: null,
          });
        },
        addNotification(message: string) {
          setState((state) => {
            return {
              notifications: [...state.notifications, message],
            };
          });
        },
        clearFirstNotification() {
          setState((state) => {
            const notifications = [...state.notifications];
            notifications.shift();
            return {
              notifications,
            };
          });
        },
      };

      HostClient.on("party_change", (state: { playerIds: number[] }) => {
        setState({
          playerCount: state.playerIds.length,
          players: state.playerIds.map((playerId) => {
            const player = HostClient.playersManager.getPlayerByPlayerId(playerId);
            assert(player, "expected player");
            return player.clone();
          }),
        });
      });

      HostClient.on("party_player_joined", (data: { playerId: number }) => {
        console.log("party_player_joined", getState());

        // Only display player joined notifications during play state if the game is paused
        const hostState = getState().hostState;
        if (hostState.playlist?.state === PLAYLIST_STATES.PLAY && !hostState.playlist?.isPaused) {
          return;
        }

        const player = HostClient.playersManager.getPlayerByPlayerId(data.playerId);
        assert(player, "expected player");
        actions.addNotification(`${player.getName()} joined the game 🎉`);
      });

      HostClient.on("party_player_left", (data: { playerId: number }) => {
        const player = HostClient.playersManager.getPlayerByPlayerId(data.playerId);
        assert(player, "expected player");
        actions.addNotification(`${player.getName()} left the game 👎`);
      });

      HostClient.on("player_reconnected", (data: { playerId: number }) => {
        const player = HostClient.playersManager.getPlayerByPlayerId(data.playerId);
        assert(player, "expected player");
        actions.addNotification(`${player.getName()} is back bitches! 😎`);
      });

      HostClient.hud.on("hud_setup", (hudConfig) => {
        setState({
          hudConfig,
        });
      });

      HostClient.hud.on("hud_players_update", (playersHudData) => {
        setState({
          playersHudData: { ...playersHudData },
        });
      });

      HostClient.hud.on("hud_screen_point_update", (hudScreenPointData) => {
        setState({
          hudScreenPointData: hudScreenPointData,
        });
      });

      HostClient.hud.on("hud_clear", () => {
        setState({
          hudConfig: null,
          playersHudData: null,
          hudScreenPointData: null,
        });
      });

      HostClient.peerManager.onPeerStatusChange(({ status }) => {
        setState({
          peerServerConnectionStatus: status,
        });
      });

      HostClient.peerManager.onConnectionsChange(() => {
        setState({
          connectionCount: HostClient.peerManager.getNumberOfConnections(),
          clientConnectionStatusByPeerId: HostClient.peerManager.getConnectionStatusesByPeerId(),
        });
      });

      HostClient.peerManager.onConnectionStatusChange(() => {
        setState({
          clientConnectionStatusByPeerId: HostClient.peerManager.getConnectionStatusesByPeerId(),
        });
      });

      HostClient.gameManager.on("setup_game", (data) => {
        setState({
          gameEntry: new GameEntry(data.gameEntry),
        });
      });

      HostClient.gameManager.on("unload_game", () => {
        setState({
          gameEntry: null,
        });
      });

      HostClient.gameManager.on("waiting_game_setup", (data) => {
        setState({
          waitingGameSetupPlayerIds: data.waitingClientIds,
        });
      });

      HostClient.on("host_state_change", (hostState) => {
        setState({
          hostState: hostState,
        });
      });

      return {
        actions,
      };
    }),
  );
};

let store: HostStore | null = null;
export default () => {
  if (!store) {
    store = createHostStore();
  }
  return store;
};
