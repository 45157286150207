import { Console } from "console-feed";
import { Message } from "console-feed/lib/definitions/Component";
import useConsoleFeedStore from "./consoleFeedStore";

/**
 * Make sure to place ConsoleFeedHook in the root of the app to start capturing logs as early as possible.
 */
const ConsoleFeedLog = () => {
  const feed = useConsoleFeedStore((state) => state.feed);
  const typeFilter = useConsoleFeedStore((state) => state.typeFilter);
  const searchKeywords = useConsoleFeedStore((state) => state.searchKeywords);

  return <Console logs={feed as Message[]} variant="dark" filter={typeFilter} searchKeywords={searchKeywords} />;
};

export default ConsoleFeedLog;
