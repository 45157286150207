import PlayerClient from "../../../common/client/player/PlayerClient";
import { NavigationButton } from "../../host/spatialNavigation/types";
import ControllerButton from "./ControllerButton";
import classes from "./PartyLeaderController.module.css";
import { usePlayerStore } from "../usePlayerStore";

const PartyLeaderController = () => {
  const player = usePlayerStore((state) => state.player);
  if (!player) {
    throw new Error("player expected");
  }

  const handleDown = (button: NavigationButton) => {
    PlayerClient.sendEventToHostClient("party_leader_input", {
      button: button,
    });
  };

  const gridButtons = [
    null,
    {
      label: "UP",
      onClick: () => {
        handleDown("up");
      },
    },
    null,
    {
      label: "LEFT",
      onClick: () => {
        handleDown("left");
      },
    },
    {
      label: "SELECT",
      onClick: () => {
        handleDown("enter");
      },
    },
    {
      label: "RIGHT",
      onClick: () => {
        handleDown("right");
      },
    },
    {
      label: "BACK",
      onClick: () => {
        handleDown("back");
      },
      isRound: true,
    },
    {
      label: "DOWN",
      onClick: () => {
        handleDown("down");
      },
    },
    null,
  ];

  return (
    <div className={classes.buttonGrid}>
      {gridButtons.map((item, i) =>
        !item ? (
          <div key={i}></div>
        ) : (
          <ControllerButton key={i} onClick={item.onClick} isRound={item.isRound}>
            {item.label}
          </ControllerButton>
        ),
      )}
    </div>
  );
};

export default PartyLeaderController;
