import React from "react";

type Props = React.ComponentProps<"button"> & {
  selected?: boolean;
  pressed?: boolean;
  variant?: "primary" | "secondary";
  isRound?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(function (
  { className, children, selected, pressed, variant = "primary", isRound, ...rest },
  ref,
) {
  let btnStyle = "";
  switch (variant) {
    case "primary":
      btnStyle = `box-border ${selected ? "bg-violet-700" : "bg-[rgb(40, 40, 40)]"} ${pressed ? "pressed" : ""} pressed:border-b pressed:border-l-[3px] pressed:border-r pressed:border-t-[3px] pressed:bg-violet-700 rounded border-b-[3px] border-l border-r-[3px] border-t border-solid border-b-violet-700 border-l-black border-r-violet-700 border-t-black p-2.5 text-center font-semibold text-white [text-shadow:_0_0_10px_gray] focus:outline-none active:border-b active:border-l-[3px] active:border-r active:border-t-[3px] active:bg-violet-700 disabled:border-[gray] disabled:border-r-[gray] disabled:bg-[#3a3a3a] disabled:text-[#a0a0a0]`;
      break;
    case "secondary":
      btnStyle = `box-border ${selected ? "bg-[#77292e]" : "bg-[rgb(40, 40, 40)]"} ${pressed ? "pressed" : ""} pressed:border-b pressed:border-l-[3px] pressed:border-r pressed:border-t-[3px] pressed:bg-[#77292e] rounded border-b-[3px] border-l border-r-[3px] border-t border-solid border-b-[gray] border-white border-r-[gray] p-2.5 text-center font-semibold text-white [text-shadow:_0_0_10px_gray] focus:outline-none active:border-b active:border-l-[3px] active:border-r active:border-t-[3px] active:bg-[#77292e] disabled:border-[gray] disabled:border-r-[gray] disabled:bg-[#3a3a3a] disabled:text-[#a0a0a0]"`;
      break;
    default:
      throw new Error(`Invalid button variant`);
  }

  if (isRound) {
    btnStyle += " rounded-full";
  }

  return (
    <button ref={ref} className={[btnStyle, className].filter(Boolean).join(" ")} {...rest}>
      {children}
    </button>
  );
});

export default Button;
