import { PlayerContext } from "./PlayerProvider";

import { useContext } from "react";
import { useStore } from "zustand";
import { PlayerState } from "./createPlayerStore";

export function usePlayerStore<T>(selector: (state: PlayerState) => T): T {
  const store = useContext(PlayerContext);
  if (!store) throw new Error("Missing PlayerContext.Provider in the tree");
  return useStore(store, selector);
}
