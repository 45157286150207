import { PLAYLIST_STATES } from "../../../common/client/plugins/playlist/PlaylistHostPlugin";
import { useHostActions } from "../useHostActions";
import { useHostStore } from "../useHostStore";
import Notification from "./Notification";

const NOTIFICATION_DISPLAY_DURATION_DEFAULT = 4;
const NOTIFICATION_DISPLAY_DURATION_PLAYING = 3; // Shorter duration when playing to avoid blocking the view
const NOTIFICATION_DISPLAY_DURATION_ON_QUEUE = 3; // Speed up if there is multiple notifications

const Notifications = () => {
  const actions = useHostActions();
  const notifications = useHostStore((state) => state.notifications);
  const hostState = useHostStore((state) => state.hostState);

  if (notifications.length <= 0) {
    return null;
  }

  const isPlaying = hostState?.playlist?.state === PLAYLIST_STATES.PLAY;
  let durationSeconds = isPlaying ? NOTIFICATION_DISPLAY_DURATION_PLAYING : NOTIFICATION_DISPLAY_DURATION_DEFAULT;
  durationSeconds = notifications.length > 1 ? NOTIFICATION_DISPLAY_DURATION_ON_QUEUE : durationSeconds;

  const message = notifications[0];

  return (
    <Notification
      key={message}
      message={message}
      durationSeconds={durationSeconds}
      onExit={() => {
        actions.clearFirstNotification();
      }}
    />
  );
};

export default Notifications;
