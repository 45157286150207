type Props = {
  children: string;
};

const PlayerStatusText = ({ children }: Props) => {
  return (
    <div
      style={{
        flex: 0,
        flexGrow: 0,
        height: "2em",
        textAlign: "center",
        fontSize: "1.25em",
        fontWeight: "bold",
        marginTop: "32px",
      }}
    >
      {children}
    </div>
  );
};

export default PlayerStatusText;
