import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import OneGameSelected from "../../../../assets/menu/one-game-selected.svg";
import OneGame from "../../../../assets/menu/one-game.svg";
import PlaylistSelected from "../../../../assets/menu/playlist-selected.svg";
import Playlist from "../../../../assets/menu/playlist.svg";
import useFocusableWithAction from "../../spatialNavigation/useFocusableWithAction";
import { PLAYLIST_BROWSER_FOCUS_KEY } from "./PlaylistBrowser";
import { playSound, SOUNDS } from "../../../sounds/ui/sounds";

type Props = {
  variant: "one-game" | "playlist";
  forceFocus?: boolean;
  onActionEnter: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const DisketteButton = ({ variant, forceFocus, onActionEnter, onFocus, onBlur }: Props) => {
  const { ref, focused, action } = useFocusableWithAction({
    focusKey: variant,
    forceFocus,
    onActionEnter: (...args) => {
      playSound(SOUNDS.BUTTON_PRESS, { throttleMs: 50 });
      playSound(SOUNDS.NEXT_ITEM, { throttleMs: 50 });
      onActionEnter(...args);
    },
    onBlur,
    onActionDirection: (direction) => {
      if (direction === "down") {
        setFocus(PLAYLIST_BROWSER_FOCUS_KEY);
        return false;
      }
      return true;
    },
    onFocus: () => {
      playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });

      onFocus && onFocus();
    },
  });

  let Image: string = "";
  let ImageSelected: string = "";

  switch (variant) {
    case "one-game":
      Image = OneGame;
      ImageSelected = OneGameSelected;
      break;
    case "playlist":
      Image = Playlist;
      ImageSelected = PlaylistSelected;
      break;
  }

  return (
    <button className="relative" ref={ref} onClick={action}>
      <img src={Image} alt="" />
      <img
        src={ImageSelected}
        className={`absolute -translate-y-full scale-[115%] opacity-0 transition-all duration-150 ${focused ? "scale-[120%] opacity-100" : ""}`}
        alt=""
      />
    </button>
  );
};

export default DisketteButton;
