import { useRef } from "react";
import ConsoleFeedLog from "../../../common/consoleFeed/ConsoleFeedLog";
import useConsoleFeedStore from "../../../common/consoleFeed/consoleFeedStore";
import { usePlayerStore } from "../usePlayerStore";

import {
  BugAntIcon,
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import useAutoScroll from "../../../common/hook/useAutoScroll";

const ActionButton = ({
  onClick,
  active,
  children,
}: {
  onClick: () => void;
  active?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      className={`border-1 flex border-stone-600 px-2 py-1 text-white ${active ? "bg-green-600" : "bg-slate-500"}`}
    >
      {children}
    </button>
  );
};

const ConsoleFeedLogOverlay = () => {
  const playerActions = usePlayerStore((state) => state.actions);
  const consoleActions = useConsoleFeedStore((state) => state.actions);
  const logCount = useConsoleFeedStore((state) => state.logCount);
  const errorCount = useConsoleFeedStore((state) => state.errorCount);
  const warningCount = useConsoleFeedStore((state) => state.warningCount);
  const debugCount = useConsoleFeedStore((state) => state.debugCount);
  const searchKeywords = useConsoleFeedStore((state) => state.searchKeywords);
  const typeFilter = useConsoleFeedStore((state) => state.typeFilter);

  const isSearchActive = searchKeywords !== undefined;

  const consoleContainerRef = useRef<HTMLDivElement>(null);
  useAutoScroll(consoleContainerRef, logCount);

  return (
    <div className="z-devConsole absolute inset-0 flex flex-col bg-slate-900 text-xs">
      <div>
        <div className="flex h-8 items-center justify-center bg-slate-800">
          <div className="text-white">Console Log</div>
        </div>
        <div className="flex items-stretch text-[0.6rem]">
          <ActionButton onClick={() => consoleActions.clear()}>
            <TrashIcon className="h-4 w-4" />
          </ActionButton>
          <span className="flex items-center pl-2 pr-1">Show:</span>
          <ActionButton
            onClick={() => {
              consoleActions.clearTypeFilters();
            }}
            active={typeFilter.length <= 0}
          >
            All({logCount})
          </ActionButton>
          <ActionButton
            onClick={() => {
              consoleActions.toggleTypeFilter("error");
            }}
            active={typeFilter.includes("error")}
          >
            <XCircleIcon className="h-4 w-4" /> ({errorCount})
          </ActionButton>
          <ActionButton
            onClick={() => {
              consoleActions.toggleTypeFilter("warn");
            }}
            active={typeFilter.includes("warn")}
          >
            <ExclamationTriangleIcon className="h-4 w-4" /> ({warningCount})
          </ActionButton>
          <ActionButton
            onClick={() => {
              consoleActions.toggleTypeFilter("debug");
            }}
            active={typeFilter.includes("debug")}
          >
            <BugAntIcon className="h-4 w-4" /> ({debugCount})
          </ActionButton>
          <span className="flex-1"></span>
          <ActionButton
            onClick={() => {
              searchKeywords !== undefined
                ? consoleActions.clearSearchKeywords()
                : consoleActions.setSearchKeywords("");
            }}
            active={isSearchActive}
          >
            <MagnifyingGlassIcon className="h-4 w-4" />
          </ActionButton>
        </div>
        <button className="absolute right-0 top-0 p-1" onClick={() => playerActions.setConsoleLogOpen(false)}>
          <XMarkIcon className="h-6 w-6 bg-rose-900" />
        </button>
      </div>
      {isSearchActive && (
        <div>
          <input
            type="text"
            autoFocus
            placeholder="Search"
            className="w-full bg-slate-200 p-1 text-black focus:outline-none"
            value={searchKeywords}
            onChange={(e) => consoleActions.setSearchKeywords(e.target.value)}
          />
        </div>
      )}
      <div ref={consoleContainerRef} className="flex-1 overflow-scroll">
        <ConsoleFeedLog />
      </div>
    </div>
  );
};

export default ConsoleFeedLogOverlay;
