import { HostClientType } from "../../host/HostClient";
import ClientPlugin from "../ClientPlugin";

export type HostPluginEvents = Record<string, never>;

class HostPlugin<T extends HostPluginEvents> extends ClientPlugin<T> {
  protected client: HostClientType | null = null;

  protected getClient() {
    if (!this.client) {
      throw new Error("Client not initialized");
    }
    return this.client;
  }
}

export default HostPlugin;
