import { FocusContext } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../../../../assets/arrow-down.svg";
import { PLAYLIST_BLUEPRINT_DATA } from "../../../../common/client/plugins/playlist/constants";
import useFocusableWithAction from "../../spatialNavigation/useFocusableWithAction";
import DisketteButton from "./DisketteButton";

export const START_MENU_FOCUS_KEY = "lobbyStartMenu";

const StartMenu = () => {
  const navigate = useNavigate();
  const { ref, focusKey, hasFocusedChild } = useFocusableWithAction({
    focusKey: START_MENU_FOCUS_KEY,
    forceFocus: true,
    trackChildren: true,
  });

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={`relative flex h-full w-full transition-all duration-200 ${hasFocusedChild ? "scale-100 opacity-100" : "-translate-y-10 scale-75 opacity-0"} `}
      >
        <div className="absolute left-[50%] top-[30%] origin-center -translate-x-[50%] -translate-y-[50%]">
          <img className="pointer-events-none h-[285px] select-none" src="/gc-logo.svg" alt="" />
        </div>
        <div className="absolute left-[27%] top-[60%] origin-center -translate-x-[50%] -translate-y-[50%] -rotate-[30deg]">
          <DisketteButton
            forceFocus
            variant="playlist"
            onActionEnter={() => {
              navigate(
                `/host/playlist-start/${PLAYLIST_BLUEPRINT_DATA.find((pb) => pb.id === "all_games_randomized_playlist")?.id}`,
              );
            }}
          />
        </div>
        <div className="absolute right-[27%] top-[60%] origin-center -translate-y-[50%] translate-x-[50%] rotate-[30deg]">
          <DisketteButton
            variant="one-game"
            onActionEnter={() => {
              navigate("/host/games");
            }}
          />
        </div>
        <div className="absolute left-[50%] top-[83%] flex origin-center -translate-x-[50%] -translate-y-[50%] flex-col items-center justify-center">
          <img src={ArrowDown} className="animate-[bounce_3s_ease-in-out_infinite]" alt="" />
          <span className="pt-5 text-center text-3xl text-white shadow-black text-shadow">
            Navigate down for more
            <br />
            playlist options
          </span>
        </div>
      </div>
    </FocusContext.Provider>
  );
};

export default StartMenu;
