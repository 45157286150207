import { useEffect, useState } from "react";
import PlayerMenu from "./PlayerMenu";
import PlayerMenuButton from "./PlayerMenuButton";
import classes from "./PlayerMenuProvider.module.css";
import { usePlayerStore } from "../usePlayerStore";

const PlayerMenuProvider = () => {
  const [open, setOpen] = useState(false);
  const hostState = usePlayerStore((state) => state.hostState);

  useEffect(() => {
    setOpen(false);
  }, [hostState?.status]);

  return (
    <>
      <PlayerMenuButton
        onClick={() => {
          setOpen(!open);
        }}
      />
      {open && (
        <div className={classes.container}>
          <PlayerMenu onClose={() => setOpen(false)} />
        </div>
      )}
    </>
  );
};

export default PlayerMenuProvider;
