import { CATEGORIES, GAME_ENTRY_DATA, GAMES_HOST, GAMES_PATH } from "./constants";

export type GameEntryData = {
  id: string;
  gameId: string;
  gameModeId: string;
  gameName: string;
  gameModeName: string;
  descriptionShort: string;
  descriptionLong: string;
  minPlayers: number;
  maxPlayers: number;
  categories: GameCategory[];
  coverImage: string;
  isWarmup?: boolean;
};

export type GameCategory = (typeof CATEGORIES)[keyof typeof CATEGORIES];

class GameEntry {
  private data: GameEntryData;

  public static getGameEntryDataById(gameEntryId: string) {
    return GAME_ENTRY_DATA.find((gameEntry) => gameEntry.id === gameEntryId);
  }

  public static getGameEntryById(gameEntryId: string) {
    const gameEntryData = this.getGameEntryDataById(gameEntryId);
    if (!gameEntryData) return null;
    return new GameEntry(gameEntryData);
  }

  constructor(gameEntry: GameEntryData) {
    this.data = gameEntry;
  }

  getGameEntryId() {
    return this.data.id;
  }

  getGameId() {
    return this.data.gameId;
  }

  getGameName() {
    return this.data.gameName;
  }

  getGameModeName() {
    return this.data.gameModeName;
  }

  getGameModeId() {
    return this.data.gameModeId;
  }

  getIsWarmup() {
    return !!this.data.isWarmup;
  }

  getMinPlayers() {
    return this.data.minPlayers;
  }

  getMaxPlayers() {
    return this.data.maxPlayers;
  }

  getCategories() {
    return this.data.categories;
  }

  getGameEntry() {
    return this.data;
  }

  getDescriptionShort() {
    return this.data.descriptionShort;
  }

  getDescriptionLong() {
    return this.data.descriptionLong;
  }

  getCoverImage() {
    return this.data.coverImage;
  }

  getData() {
    return this.data;
  }

  static getBaseUrl(gameEntry: GameEntry) {
    return `${GAMES_HOST}${GAMES_PATH}/${gameEntry.getGameId()}`;
  }

  static getGameHostUrl(gameEntry: GameEntry) {
    return `${this.getBaseUrl(gameEntry)}/host.html`;
  }

  static getGamePlayerUrl(gameEntry: GameEntry) {
    return `${this.getBaseUrl(gameEntry)}/player.html`;
  }
}

export default GameEntry;
