import GameEntry from "../../../../common/client/GameEntry";
import selectionArrowRightFuchsia500 from "../../../../assets/selection-arrow-right-fuchsia-500.svg";
import selectionArrowRight from "../../../../assets/selection-arrow-right.svg";

interface Props {
  gameEntry: GameEntry;
  isSelected?: boolean;
}

const GameEntryRow = ({ gameEntry, isSelected }: Props) => {
  return (
    <div
      className={`overflow-hidden rounded-bl-[25px] rounded-tr-[25px] border-[3px] ${isSelected ? "border-fuchsia-500 bg-fuchsia-500" : "border-violet-700 bg-violet-700"}`}
    >
      <div className={`flex bg-violet-950 py-2 text-3xl ${isSelected ? "" : "px-6"}`}>
        {isSelected && <img src={selectionArrowRightFuchsia500} className="ml-4 mr-3 w-4" alt="" />}
        {gameEntry.getGameName()}
      </div>
      <div className={`flex py-2 text-2xl ${isSelected ? "text-white" : "pl-10 text-violet-400"}`}>
        {isSelected && <img src={selectionArrowRight} className="ml-4 mr-3 w-3" alt="" />}
        {gameEntry.getGameModeName()}
      </div>
    </div>
  );
};

export default GameEntryRow;
