import { useLongPress } from "react-use";
import PlayerClient from "../../../common/client/player/PlayerClient";

const PauseOverlay = () => {
  const onLongPress = useLongPress(
    () => {
      PlayerClient.requestPause(false);
    },
    {
      delay: 1000,
      isPreventDefault: true,
    },
  );

  return (
    <div
      id="pause-overlay"
      className="z-playerPauseOverlay absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90"
    >
      <div className="animate-pulse text-2xl font-bold uppercase text-white">Game paused</div>
      <button className="mt-4 rounded-lg bg-blue-500 px-4 py-2 uppercase text-white" {...onLongPress}>
        Long press to resume
      </button>
    </div>
  );
};

export default PauseOverlay;
