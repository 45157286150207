import { PlayerClientEventMessage } from "../../player/PlayerClient";
import HostPlugin, { HostPluginEvents } from "../hud/HostPlugin";

export type LatencyHostPluginEvents = HostPluginEvents;

class LatencyHostPlugin extends HostPlugin<LatencyHostPluginEvents> {
  constructor() {
    super("LatencyHostPlugin");
  }

  handlePlayerClientEvent = (event: PlayerClientEventMessage) => {
    if (event.eventName === "ping") {
      const pingId = event.data as number;
      this.getClient().sendEventToPlayerClient(event.playerId, "pong", pingId);
    }
  };
}

export default LatencyHostPlugin;
