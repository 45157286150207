import { useEffect, useState } from "react";
import { H1 } from "../../../common/ui/heading/Heading";

const WarmupDisclaimer = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className={`absolute top-0 flex w-full translate-y-[-100%] animate-[yTo0_.35s_ease-out_forwards] flex-col items-center justify-center shadow-black transition-all duration-500 text-shadow ${show ? "opacity-100" : "opacity-0"}`}
    >
      <H1>WARMUP ROUND</H1>
      <h5>No score will be recorded from warmup rounds</h5>
    </div>
  );
};

export default WarmupDisclaimer;
