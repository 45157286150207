import { useEffect, useRef } from "react";

const useFixedAspectRatioScale = (width = 1920, height = 1080, ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    function handleResize() {
      const content = ref.current;
      if (!content) {
        return;
      }

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const scaleFactor = Math.min(viewportWidth / width, viewportHeight / height);
      content.style.transform = "scale(" + scaleFactor + ")";
      content.style.top = (viewportHeight - height * scaleFactor) / 2 + "px";
      content.style.left = (viewportWidth - width * scaleFactor) / 2 + "px";
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height, ref]);
};

const FixedAspectRatioScale = ({
  children,
  width,
  height,
}: React.PropsWithChildren<{
  width: number;
  height: number;
}>) => {
  const ref = useRef<HTMLDivElement>(null);
  useFixedAspectRatioScale(width, height, ref);

  return (
    <div className="relative flex h-dvh w-dvw justify-center overflow-hidden">
      <div
        ref={ref}
        className={`absolute left-0 top-0 z-10 origin-top-left`}
        style={{
          width: width,
          maxWidth: width,
          height: height,
          maxHeight: height,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FixedAspectRatioScale;
