import noise from "../../../assets/noise.png";

const TvNoiseEffectOverlay = () => {
  return (
    <div className="absolute inset-0 animate-[tvNoiseOpacity_23s_linear_infinite_both]">
      <div
        className="absolute inset-[-200%] animate-[tvNoiseShift_0.15s_linear_infinite_both] transition-all"
        style={{
          backgroundImage: `url(${noise})`,
          backgroundSize: "15% 15%",
        }}
      />
    </div>
  );
};

export default TvNoiseEffectOverlay;
