import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import Player, { PlayerData } from "./Player";

export type SharedPlayersState = {
  playersData: PlayerData[];
};

export interface PlayersManagerEvents extends DefaultListener {
  change: (sharedState: SharedPlayersState) => void;
}

class PlayersManager<T extends PlayersManagerEvents = PlayersManagerEvents> extends EventEmitter<T> {
  protected players: Player[] = []; // all players ever joined. Players are set to inactive when they leave to preserve their state

  protected dispatchChange = () => {
    this.dispatch("change", this.getSharedClientState());
  };

  getSharedClientState(): SharedPlayersState {
    return {
      playersData: this.players.map((p) => p.toData()),
    };
  }

  getPlayers(): Player[] {
    return this.players;
  }

  getPlayerByPlayerId(playerId: number): Player | null {
    return this.players.find((p) => p.getPlayerId() === playerId) || null;
  }

  getPlayerByName(name: string): Player | null {
    return this.players.find((p) => p.getName() === name) || null;
  }

  getPlayerByPeerId(peerId: string): Player | null {
    return this.players.find((p) => p.getPeerId() === peerId) || null;
  }

  getPeerIdByPlayerId = (playerId: number): string | null => {
    const player = this.getPlayerByPlayerId(playerId);
    if (!player) {
      return null;
    }
    return player.getPeerId();
  };
}

export default PlayersManager;
