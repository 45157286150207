export const LATENCY_SEVERITY_STEPS = [0, 100, 150, 200, 250] as const;

export const LATENCY_SEVERITY_COLORS = ["green", "yellow", "orange", "red", "darkred"] as const;

export const LATENCY_SEVERITY_BG_TAILWIND_COLORS = [
  "bg-green-500",
  "bg-yellow-500",
  "bg-orange-500",
  "bg-red-500",
  "bg-red-700",
] as const;
