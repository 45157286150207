import { Howl } from "howler";
import { useEffect } from "react";

export const SOUNDS = {
  BUTTON_FOCUS: "buttonFocus",
  BUTTON_PRESS: "buttonPress",
  BUTTON_PRESS_INVALID: "buttonPressInvalid",
  NEXT_ITEM: "nextItem",
  PREV_ITEM: "prevItem",
  PLAYER_JOIN: "playerJoin",
  NOTIFICATION: "notification",
  COUNTDOWN_TICK: "countdownTick",
} as const;

export type SoundId = (typeof SOUNDS)[keyof typeof SOUNDS];

const soundLastPlayedAt = {} as Record<SoundId, number>;

const sounds: Record<SoundId, Howl> = {
  [SOUNDS.BUTTON_FOCUS]: new Howl({
    src: ["/ui/sounds/button-press.mp3"],
    volume: 0.5,
  }),
  [SOUNDS.BUTTON_PRESS]: new Howl({
    src: ["/ui/sounds/button-press.mp3"],
    volume: 0.5,
  }),
  [SOUNDS.BUTTON_PRESS_INVALID]: new Howl({
    src: ["/ui/sounds/button-press-invalid.wav"],
    volume: 0.75,
  }),
  [SOUNDS.NEXT_ITEM]: new Howl({
    src: ["/ui/sounds/next-item.mp3"],
    volume: 0.25,
  }),
  [SOUNDS.PREV_ITEM]: new Howl({
    src: ["/ui/sounds/prev-item.mp3"],
    volume: 0.15,
  }),
  [SOUNDS.PLAYER_JOIN]: new Howl({
    src: ["/ui/sounds/player-join.wav"],
  }),
  [SOUNDS.NOTIFICATION]: new Howl({
    src: ["/ui/sounds/notification-alt.wav"],
    volume: 0.25,
  }),
  [SOUNDS.COUNTDOWN_TICK]: new Howl({
    src: ["/ui/sounds/countdown-tick.wav"],
    volume: 0.2,
  }),
} as const;

export type PlayOptions = {
  throttleMs: number;
  allowConcurrent: boolean;
};

const DEFAULT_OPTIONS: PlayOptions = {
  throttleMs: 0,
  allowConcurrent: true,
};

export function playSound(id: SoundId, playOptions?: Partial<PlayOptions>) {
  try {
    const options: PlayOptions = { ...DEFAULT_OPTIONS, ...playOptions };

    const sound = sounds[id];

    if (!options.allowConcurrent && sound.playing()) {
      return;
    }

    if (soundLastPlayedAt[id] && options.throttleMs > 0 && Date.now() - soundLastPlayedAt[id] < options.throttleMs) {
      return;
    }

    soundLastPlayedAt[id] = Date.now();
    sound.play();
  } catch (error) {
    console.error("Error playing sound", error);
  }
}

export const useSoundOnMount = (id: SoundId, playOptions?: Partial<PlayOptions>) => {
  useEffect(() => {
    playSound(id, playOptions);
  }, [id, playOptions]);
};
