type StorageType = "localStorage" | "sessionStorage";

interface StorageInterface {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

class Storage {
  storage: StorageInterface;
  prefix: string = "";

  constructor(
    storageType: StorageType = "localStorage",
    options?: {
      prefix?: string;
    },
  ) {
    this.storage = window[storageType];
    this.prefix = options?.prefix || "";
  }

  setItem(key: string, value: string) {
    this.storage.setItem(this.prefix + key, value);
  }

  getItem(key: string) {
    return this.storage.getItem(this.prefix + key);
  }

  removeItem(key: string) {
    this.storage.removeItem(this.prefix + key);
  }
}

export const storages = {
  local: new Storage("localStorage"),
  session: new Storage("sessionStorage"),
};

export default Storage;
