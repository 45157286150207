import { DataConnection } from "peerjs";
import { assert } from "../../logger/assert";
import ClientConnection from "../ClientConnection";
import { CLIENT_CONNECTION_STATUS } from "../constants";

class HostClientConnection extends ClientConnection {
  private peerId: string;

  constructor(peerId: string) {
    super();
    this.peerId = peerId;
  }

  setConnection(connection: DataConnection, allowReplace = false) {
    if (connection.reliable) {
      if (!allowReplace) {
        assert(!this.reliableConnection, "Reliable connection already initialized");
      }
      this.reliableConnection?.removeAllListeners();
      this.reliableConnection?.close();
      this.reliableConnection = connection;
    } else {
      if (!allowReplace) {
        assert(!this.unreliableConnection, "Unreliable connection already initialized");
      }
      this.unreliableConnection?.removeAllListeners();
      this.unreliableConnection?.close();
      this.unreliableConnection = connection;
    }

    this.setupCommonConnectionCallbacks(connection);

    if (this.reliableConnection && this.unreliableConnection) {
      if (this.connectionStatus !== CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE) {
        this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE);
      }
      this.dispatch("connected", {
        metadata: this.reliableConnection.metadata,
      });
    }
  }

  unsetConnection(connection: DataConnection) {
    if (connection.reliable) {
      assert(this.reliableConnection === connection, "Connection does not match reliable connection");
      this.reliableConnection = null;
    } else {
      assert(this.unreliableConnection === connection, "Connection does not match unreliable connection");
      this.unreliableConnection = null;
    }

    if (!this.reliableConnection && !this.unreliableConnection) {
      this.disconnect();
    }
  }

  getPeerId() {
    return this.peerId;
  }
}

export default HostClientConnection;
