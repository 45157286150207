import HostClient from "../../../../../common/client/host/HostClient";
import { useHostStore } from "../../../useHostStore";
import HeaderPlayerCard from "./HeaderPlayerCard";
import HeaderPlayerPlaceholder from "./HeaderPlayerPlaceholder";
import { PARTY_MAX_PLAYER_SLOTS } from "../../../../../common/client/host/constants";

const HeaderPlayers = () => {
  useHostStore((state) => state.players);
  useHostStore((state) => state.clientConnectionStatusByPeerId); // TODO: This is for connection inactivity updates. We should get player connection status via player object directly?
  const playersSortedByPerformance = HostClient.party.getPlayersSortedByPerformance();

  return (
    <div className="-mx-2.5 flex h-full items-center justify-center overflow-hidden">
      {playersSortedByPerformance.map((player) => {
        return <HeaderPlayerCard key={player.getPlayerId()} player={player} />;
      })}

      {playersSortedByPerformance.length < PARTY_MAX_PLAYER_SLOTS &&
        [...(new Array(PARTY_MAX_PLAYER_SLOTS - playersSortedByPerformance.length) as undefined[])].map((_, i) => (
          <HeaderPlayerPlaceholder key={i} />
        ))}
    </div>
  );
};

export default HeaderPlayers;
