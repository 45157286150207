import dsbLogo from "./assets/dsb-logo.jpeg";
import Button from "./common/ui/button/Button";

import "./App.css";
import useFullScreen from "./common/fullScreen/useFullScreen";
import { H6 } from "./common/ui/heading/Heading";
import { IS_DEV } from "./domain/constants";

function App() {
  const fullScreen = useFullScreen();

  return (
    <div className="m-auto mt-8 flex flex-col items-center justify-center">
      <img src={dsbLogo} alt="logo" className="border-4 border-solid border-gray-700" />
      <div className="m-4 mt-10 flex max-w-sm flex-col items-center justify-center gap-5 rounded-lg bg-neutral-700 p-5">
        <H6>USE THIS DEVICE AS A</H6>
        <div className="flex gap-5 text-sm">
          <Button
            onClick={() => {
              window.location.replace("/#/host");
              fullScreen.isAvailable && !IS_DEV && void fullScreen.request();
            }}
          >
            Game screen
          </Button>
          <Button
            onClick={() => {
              window.location.replace("/#/player");
              fullScreen.isAvailable && !IS_DEV && void fullScreen.request();
            }}
          >
            Player controller
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;
