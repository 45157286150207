import { PLAYERS_HUD_VALUE_TYPE, PLAYERS_HUD_VALUE_TYPE_STATUS } from "@shared/src/gc/hud/constants";
import { HudConfig, PlayerHudData, PlayersHudStatus } from "@shared/src/gc/hud/types";
import { PlayerColor } from "@shared/src/gc/types";
import { CLIENT_CONNECTION_STATUS } from "../../../common/client/constants";
import HostClient from "../../../common/client/host/HostClient";
import { assert } from "../../../common/logger/assert";
import { useHostStore } from "../useHostStore";

/**
 * Works for games that require up to 20 points.
 */
const ValuePointsSmall = ({
  points,
  maxPoints,
  playerColor,
}: {
  points: number;
  maxPoints: number;
  playerColor: string;
}) => {
  return (
    <div className="flex min-h-6 w-full justify-center rounded-md rounded-t-none border-4 border-t-0 border-solid border-slate-300 bg-slate-800 font-bold uppercase">
      {Array.from({ length: maxPoints }).map((_, index) => {
        return (
          <div
            key={index}
            className={`h-full flex-1 border-r-[1px] ${index < points ? `bg-player-${playerColor}` : "bg-slate-400"}`}
          ></div>
        );
      })}
    </div>
  );
};

const ValueStatus = ({ text: value }: { text: string }) => {
  const text = value.split("/")[0];
  const status = value.split("/")[1];

  let statusBgColor = "";

  switch (status as PlayersHudStatus | "") {
    case PLAYERS_HUD_VALUE_TYPE_STATUS.NEUTRAL:
      break;
    case PLAYERS_HUD_VALUE_TYPE_STATUS.SUCCESS:
      statusBgColor = "bg-green-900";
      break;
    case PLAYERS_HUD_VALUE_TYPE_STATUS.PENDING:
      statusBgColor = "bg-yellow-600";
      break;
    case PLAYERS_HUD_VALUE_TYPE_STATUS.FAILURE:
      statusBgColor = "bg-red-900";
      break;
    default:
      console.warn(`ValueText - unknown status: ${status}`);
  }

  return (
    <div
      className={`flex w-full justify-center rounded-md rounded-t-none border-4 border-t-0 border-solid border-slate-300 bg-slate-800 font-bold uppercase ${statusBgColor}`}
    >
      {text}
    </div>
  );
};

const ValueText = ({ text }: { text: string }) => {
  return (
    <div className="`flex uppercase` w-full justify-center rounded-md rounded-t-none border-4 border-t-0 border-solid border-slate-300 bg-slate-800 font-bold">
      {text}
    </div>
  );
};

const PlayerValue = ({
  config,
  playerColor,
  playerValue,
}: {
  config: HudConfig["players"];
  playerColor: PlayerColor;
  playerValue: PlayerHudData["value"];
}) => {
  switch (config.valueType) {
    case PLAYERS_HUD_VALUE_TYPE.POINTS_SMALL: {
      const parts = (playerValue as string).split("/");
      assert(parts.length == 2, `invalid hud player value for "${config.valueType}" type`);
      const points = Number(parts[0]);
      const maxPoints = Number(parts[1]);
      assert(!isNaN(points), `invalid hud player value points`);
      assert(!isNaN(maxPoints), `invalid hud player value maxPoints`);
      return <ValuePointsSmall points={points} maxPoints={maxPoints} playerColor={playerColor} />;
    }
    case PLAYERS_HUD_VALUE_TYPE.STATUS:
      return <ValueStatus text={playerValue as string} />;
    case PLAYERS_HUD_VALUE_TYPE.TEXT:
      return <ValueText text={playerValue as string} />;
    case PLAYERS_HUD_VALUE_TYPE.LIVES:
      return <div>Not implemented...</div>;
    default:
      return null;
  }
};

const PlayersHud = () => {
  useHostStore((state) => state.players); // for updates
  const hudConfig = useHostStore((state) => state.hudConfig);
  const playersHudData = useHostStore((state) => state.playersHudData);

  if (!hudConfig || !playersHudData) {
    return null;
  }

  return (
    <div className="bg-red ml-4 flex h-full w-32 translate-x-[-150%] animate-[xTo0_.35s_ease-out_forwards_2s] flex-col justify-center transition-transform">
      {playersHudData?.players.map((playerHudData) => {
        const player = HostClient.playersManager.getPlayerByPlayerId(playerHudData.playerId);
        assert(player, "expected player");
        const isInactive =
          HostClient.getPlayerConnectionStatus(player.getPlayerId()) === CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE;

        const isEliminated = playerHudData.eliminated;
        return (
          <div
            key={player.getPlayerId()}
            className="flex h-32 w-32 flex-col items-center justify-center"
            style={{
              opacity: isInactive ? 0.4 : 1,
            }}
          >
            <div
              className={`flex h-20 w-20 items-center justify-center rounded-full border-4 border-solid bg-gradient-to-b ${playerHudData.value ? "pb-5" : "pb-3"} text-3xl font-bold ${isEliminated ? "border-red-600" : "border-white"} bg-player-${player.getColor()}-muted`}
            >
              {player.getName().substring(0, 1)}
            </div>
            <div className="relative bottom-6 flex h-10 w-32 flex-col justify-center">
              <div
                className={`flex w-full justify-center rounded-md rounded-b-none border-4 border-solid ${isEliminated ? "bg-red-950" : "bg-slate-700"} bg-slate-700 text-xl font-bold uppercase bg-player-${player.getColor()}-muted ${isEliminated ? "border-red-600 bg-red-800" : "border-slate-300"}`}
              >
                {player.getName()}
              </div>
              {playerHudData.value && (
                <PlayerValue
                  config={hudConfig.players}
                  playerColor={player.getColor()}
                  playerValue={playerHudData.value}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlayersHud;
