import { PlayerPlaylistStats, PlayerStats } from "../../../common/client/host/PartyStats";
import { toDecimalPlaces } from "../../../common/utils/number";

const PlayerCardStats = ({ stats }: { stats: PlayerStats | PlayerPlaylistStats }) => {
  const isPlayerStats = "numberOfPlaylists" in stats;

  return (
    <>
      <div className="flex flex-col items-center text-xl text-neutral-50">
        <img className="mt-[4px] block h-[18px]" src="/efficiency.svg" alt="Efficiency" />
        {stats.numberOfGames === 0 ? "0%" : `${toDecimalPlaces(stats.performance * 100, 0)}%`}
      </div>
      <div className="flex flex-col items-center text-xl text-neutral-50">
        <img className="block h-[22px]" src="/wins.svg" alt="Game wins/played" />
        <span>
          {stats.numberOfGames === 0 ? (
            "0"
          ) : (
            <>
              {stats.firstPlaces}/{stats.numberOfGames}
            </>
          )}
        </span>
      </div>
      {isPlayerStats && (
        <div className="flex flex-col items-center text-xl text-neutral-50">
          <img className="block h-[22px]" src="/trophies.svg" alt="Playlist wins/played" />
          {stats.numberOfPlaylists === 0 ? (
            "0/0"
          ) : (
            <>
              {stats.playlistWins}/{stats.numberOfPlaylists}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PlayerCardStats;
