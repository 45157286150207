import { FrameEventData, FrameEventName } from "./FrameEventEmitter";
import { EVENT_TYPE } from "./constants";
import {
  GameEventData,
  GameEventMessage,
  GameEventName,
} from "./GameEventEmitter";
import { FrameEventMessage } from "./FrameEventEmitter";

export function createGameEventMessage<T extends GameEventName>(
  playerId: number,
  eventName: T,
  data?: GameEventData<T>,
  reliable = true
): GameEventMessage {
  return {
    type: EVENT_TYPE.GAME_EVENT,
    eventName,
    data,
    playerId,
    reliable,
  };
}

export function createFrameEventMessage<T extends FrameEventName>(
  eventName: T,
  data?: FrameEventData<T>
): FrameEventMessage {
  return { type: EVENT_TYPE.FRAME_EVENT, eventName, data };
}
