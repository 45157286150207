import { useContext } from "react";
import { assert } from "../logger/assert";
import { FullScreenContext } from "./FullScreenProvider";

const useFullScreen = () => {
  const context = useContext(FullScreenContext);
  assert(context, "useFullScreen must be used within a FullScreenProvider");
  return context;
};

export default useFullScreen;
