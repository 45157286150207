import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import { PlaylistBlueprintData } from "../../../common/client/plugins/playlist/types";
import PlaylistBlueprint from "../../../common/client/plugins/playlist/PlaylistBlueprint";

import { useSwiper } from "swiper/react";
import cdIcon from "../../../assets/cd-icon.svg";
import gameTypePlaceholderIcon from "../../../assets/game-type-placeholder-icon.svg";
import CoverImageSwap from "../animation/CoverImageSwap";
import TvNoiseEffectOverlay from "../animation/TvNoiseEffectOverlay";
import { START_MENU_FOCUS_KEY } from "../lobby/startMenu/StartMenu";
import useFocusableWithAction from "../spatialNavigation/useFocusableWithAction";
import DetailsBoxes from "./DetailsBoxes";
import useGameEntryCarousel from "./useGameEntryCarousel";
import { playSound, SOUNDS } from "../../sounds/ui/sounds";

interface Props {
  forceFocus: boolean;
  className?: string;
  playlistBlueprintData: PlaylistBlueprintData;
  onSelect: () => void;
  swiperIndex: number;
}

const PlaylistItem = ({ className, forceFocus, playlistBlueprintData, onSelect, swiperIndex }: Props) => {
  const swiper = useSwiper();

  const playlistBlueprint = new PlaylistBlueprint(playlistBlueprintData);
  const { ref, focused } = useFocusableWithAction({
    forceFocus: !!forceFocus,
    onActionEnter: () => {
      playSound(SOUNDS.BUTTON_PRESS, { throttleMs: 50 });
      playSound(SOUNDS.NEXT_ITEM, { throttleMs: 50 });
      onSelect && onSelect();
    },
    onActionDirection: (direction) => {
      if (swiperIndex === 0 && direction === "up") {
        setFocus(START_MENU_FOCUS_KEY);
      }
      return true;
    },
    onFocus: () => {
      playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });
    },
  });
  const gameEntry = useGameEntryCarousel({ playlistBlueprint, intervalMs: 2500, enabled: focused });

  useEffect(() => {
    setFocus("");
  }, []);

  useEffect(() => {
    if (focused) {
      swiper.slideTo(swiperIndex);
    }
  }, [focused, swiper, swiperIndex]);

  return (
    <div
      ref={ref}
      className={[className, "relative flex h-[550px] w-full shadow-[10px_10px_2px] shadow-indigo-950/75 outline-0"]
        .filter(Boolean)
        .join(" ")}
      tabIndex={0}
    >
      <div
        className={`flex w-full border-[3px] border-solid transition-all ease-in-out ${focused ? "border-fuchsia-500 shadow-[0_0_10px_2px] shadow-fuchsia-500" : "border-violet-700"}`}
      >
        <div className="flex h-full w-full flex-col border-[3px] border-violet-700 text-neutral-50">
          <div className="flex h-[100px] items-center bg-violet-700 px-10 text-4xl font-bold">
            {playlistBlueprint.getName()}
          </div>
          <div className="flex flex-1">
            <div className="flex w-6/12 flex-col border-r-[3px] border-violet-700">
              <div className="w-full pr-[46px] pt-[40px]">
                <div className="relative flex h-[86px] items-center rounded-r-full bg-purple-700 pl-[40px] text-[27px] font-bold">
                  {`${gameEntry.getGameName()} ${gameEntry.getGameModeName()}`}
                  <img
                    className="absolute -right-[1px] top-0 h-[86px] animate-[spin_10s_linear_infinite]"
                    src={cdIcon}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col p-[40px]">
                <div className="flex-1">
                  <div className="line-clamp-2 text-3xl">{playlistBlueprint.getDescriptionShort()}</div>
                </div>
                <div className="flex">
                  <DetailsBoxes playlistBlueprint={playlistBlueprint} />
                  <div className="ml-[22px] flex">
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    {[...Array(3)].map((__, i) => (
                      <img key={i} src={gameTypePlaceholderIcon} className="mx-[8px] block w-[80px]" alt="" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-6/12 overflow-hidden border-l-[3px] border-violet-700 bg-[#17163c]">
              <CoverImageSwap src={gameEntry.getCoverImage()} />
              <TvNoiseEffectOverlay />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistItem;
