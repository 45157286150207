import { useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseClientType } from "../common/client/BaseClient";

type Props = {
  client: BaseClientType;
};

const ErrorNotifications = ({ client }: Props) => {
  useEffect(() => {
    const handleError = (error: unknown) => {
      const errorMessage =
        typeof error === "string" ? error : (error as { message: string })?.message || "Oops! Something went wrong. :(";
      toast.error(errorMessage);
    };

    const errorEventId = client.on("error", handleError);
    const peerErrorEventId = client.on("peer_error", handleError);
    const connectionErrorEventId = client.on("connection_error", handleError);

    return () => {
      client.off(errorEventId);
      client.off(peerErrorEventId);
      client.off(connectionErrorEventId);
    };
  }, [client]);

  return <ToastContainer position="bottom-center" />;
};

export default ErrorNotifications;
