import { useEffect, useState } from "react";

const CoverImageSwap = ({ src }: { src: string }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [show, setShow] = useState(false);

  useEffect(
    function transitionToNewImage() {
      if (src !== currentSrc) {
        setShow(false);
        const timeout = setTimeout(() => {
          setCurrentSrc(src);
        }, 300);

        return () => {
          clearTimeout(timeout);
        };
      }
    },
    [src, currentSrc],
  );

  return (
    <>
      <img
        src={currentSrc}
        className={`opacity-0 transition-all duration-300 ${show ? "opacity-100" : ""}`}
        onLoad={() => setShow(true)}
      />
    </>
  );
};

export default CoverImageSwap;
