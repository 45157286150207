import { useEffect, useRef } from "react";
import GameEntry from "../../../common/client/GameEntry";
import HostClient from "../../../common/client/host/HostClient";
import { useHostStore } from "../useHostStore";
import { createPortal } from "react-dom";
import { assert } from "../../../common/logger/assert";

const HostFrame = () => {
  const gameEntry = useHostStore((state) => state.gameEntry);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeContainerRef = useRef<HTMLElement>(document.getElementById("host-frame-container"));

  assert(iframeContainerRef.current, "iframeContainerRef.current expected");

  useEffect(() => {
    if (!iframeRef.current) return;
    HostClient.setupIframe(iframeRef.current);
  }, [iframeRef, gameEntry]);

  return gameEntry
    ? createPortal(
        <iframe
          id="host-frame"
          ref={iframeRef}
          src={GameEntry.getGameHostUrl(gameEntry)}
          allow="autoplay; fullscreen"
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,
            border: 0,
          }}
        ></iframe>,
        iframeContainerRef.current,
      )
    : null;
};

export default HostFrame;
