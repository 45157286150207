import { Message } from "console-feed/lib/definitions/Console";
import { Methods } from "console-feed/lib/definitions/Methods";
import { create } from "zustand";

type ConsoleFeedStore = {
  feed: Message[];
  typeFilter: Methods[];
  searchKeywords: string | undefined;
  logCount: number;
  errorCount: number;
  warningCount: number;
  debugCount: number;
  actions: {
    add: (message: Message) => void;
    clear: () => void;
    setTypeFilters: (filter: Methods[]) => void;
    toggleTypeFilter: (filter: Methods) => void;
    clearTypeFilters: () => void;
    setSearchKeywords: (keywords: string) => void;
    clearSearchKeywords: () => void;
  };
};

const useConsoleFeedStore = create<ConsoleFeedStore>((set) => ({
  feed: [],
  typeFilter: [],
  searchKeywords: undefined,
  logCount: 0,
  errorCount: 0,
  warningCount: 0,
  debugCount: 0,
  actions: {
    add: (message: Message) => {
      switch (message.method) {
        case "error":
          set((state) => ({ errorCount: state.errorCount + 1 }));
          break;
        case "warn":
          set((state) => ({ warningCount: state.warningCount + 1 }));
          break;
        case "debug":
          set((state) => ({ debugCount: state.debugCount + 1 }));
          break;
      }

      set((state) => ({ feed: [...state.feed, message], logCount: state.logCount + 1 }));
    },
    clear: () => set({ feed: [], logCount: 0, errorCount: 0, warningCount: 0 }),
    setTypeFilters: (filter: Methods[]) => set({ typeFilter: filter }),
    toggleTypeFilter: (filter: Methods) => {
      set((state) => {
        const typeFilter = state.typeFilter.includes(filter)
          ? state.typeFilter.filter((f) => f !== filter)
          : [...state.typeFilter, filter];

        return { typeFilter };
      });
    },
    clearTypeFilters: () => set({ typeFilter: [] }),
    setSearchKeywords: (keywords: string) => set({ searchKeywords: keywords }),
    clearSearchKeywords: () => set({ searchKeywords: undefined }),
  },
}));

export default useConsoleFeedStore;
