export const HOST_PLAYER_ID = 0;

export const UNSET_PLAYER_COLOR = "white";

export const PLAYER_COLORS = [
  UNSET_PLAYER_COLOR,
  "blue",
  "red",
  "green",
  "cyan",
  "yellow",
  "purple",
  "pink",
  "brown",
] as const;

export const PLAYER_COLORS_MUTED = {
  [UNSET_PLAYER_COLOR]: "white",
  blue: "#5873ff",
  red: "#ff5f5f",
  green: "#66b366",
  cyan: "#4ea8a8",
  yellow: "#e6e65a",
  purple: "#9966cc",
  pink: "#ff99cc",
  brown: "#a96b46",
} as const;

export const PLAYER_COLORS_MUTED_DARKER = {
  [UNSET_PLAYER_COLOR]: "white",
  blue: "#3152b8",
  red: "#b23636",
  yellow: "#b4b441",
  green: "#4c8a4c",
  cyan: "#2a7a7a",
  purple: "#704a8e",
  pink: "#b26695",
  brown: "#7e5134",
} as const;
