import Player from "../../../common/client/Player";
import { AVATAR_BY_PLAYER_COLOR } from "./constants";
import controlIcon from "../../../assets/control-icon.svg";

interface Props {
  player: Player;
  isInactive?: boolean;
  isPartyLeader?: boolean;
}

const PlayerCard = ({ children, player, isInactive, isPartyLeader }: React.PropsWithChildren<Props>) => {
  const color = player.getColor();

  return (
    <div
      className={`relative mx-2.5 h-full w-[162px] flex-col items-center border-[3px] bg-indigo-950 border-player-${color}-muted ${isInactive ? "opacity-50" : ""}`}
    >
      <div className="relative flex items-center justify-center pt-[8px]">
        <div className={`absolute left-0 top-0 h-[72px] w-full bg-player-${color}-muted`} />
        <img className="pointer-events-none relative h-[94px]" src={AVATAR_BY_PLAYER_COLOR[player.getColor()]} alt="" />
      </div>
      {isPartyLeader && (
        <div className="absolute left-1.5 top-1.5 text-xl">
          <img src={controlIcon} alt="chevron-right" className="h-5 w-5" />
        </div>
      )}
      <div className="text-center text-2xl font-semibold text-neutral-50">{player.getName()}</div>
      <div className="mt-1 flex w-full justify-around pb-2">{children}</div>
    </div>
  );
};

export default PlayerCard;
