import { PLAYER_COLORS } from "@shared/src/gc/constants";
import { assert } from "../../logger/assert";
import Party, { PartyEvents } from "../Party";
import Player from "../Player";
import { HostClient } from "./HostClient";
import PartyStats from "./PartyStats";
import { PARTY_MAX_PLAYER_SLOTS } from "./constants";

export interface HostPartyEvents extends PartyEvents {
  player_joined: (data: { playerId: number }) => void;
  player_left: (data: { playerId: number }) => void;
}

class HostParty extends Party<HostPartyEvents> {
  readonly partyStats: PartyStats = new PartyStats();
  private playerSlots: number[] = Array(PARTY_MAX_PLAYER_SLOTS).fill(-1) as number[];
  protected client: HostClient;

  constructor(client: HostClient) {
    super(client);
    this.client = client;
  }

  setup() {}

  private findFreeSlotNumber = (): number => {
    const index = this.playerSlots.findIndex((slot) => slot === -1);
    return index === -1 ? -1 : index + 1;
  };

  private tryGetNewPartyLeaderPlayerId = (): number | null => {
    return this.playerIds[0] || null;
  };

  addPlayer = (player: Player): Player => {
    if (!this.tryGetFreeSlotNumber()) {
      throw new Error(
        "Can't join party. Check with 'tryGetFreeSlotNumber' before calling 'addPlayer' to avoid throwing",
      );
    }

    const slotNumber = this.findFreeSlotNumber();

    player.setColor(PLAYER_COLORS[slotNumber]);

    this.playerIds.push(player.getPlayerId());
    this.playerSlots[slotNumber - 1] = player.getPlayerId();

    if (!this.partyLeaderPlayerId) {
      this.partyLeaderPlayerId = this.tryGetNewPartyLeaderPlayerId();
    }

    this.dispatchChange();
    this.dispatch("player_joined", { playerId: player.getPlayerId() });

    this.partyStats.calculateAndStoreStatsForPlayers([player.getPlayerId()]);

    return player;
  };

  removePlayer = (playerId: number): void => {
    const player = this.getPlayerByPlayerId(playerId);
    assert(player, "expected player");

    this.playerIds = this.playerIds.filter((id) => id !== playerId);

    if (this.partyLeaderPlayerId === playerId) {
      this.partyLeaderPlayerId = this.tryGetNewPartyLeaderPlayerId();
    }

    const slotIndex = this.playerSlots.indexOf(playerId);
    this.playerSlots[slotIndex] = -1;

    this.dispatch("player_left", { playerId });
    this.dispatchChange();
  };

  tryGetFreeSlotNumber() {
    const slotNumber = this.findFreeSlotNumber();
    if (slotNumber === -1) {
      return "party_full";
    }

    return slotNumber;
  }

  setPartyLeader(playerId: number): void {
    this.partyLeaderPlayerId = playerId;
    this.dispatchChange();
  }

  getPlayersSortedByPerformance() {
    const playerStatsByPlayerId = this.partyStats.getStats();

    const players = this.playerIds.map((playerId) => {
      const player = this.getPlayerByPlayerId(playerId);
      assert(player, "expected player");
      return player;
    });

    return players.sort((a, b) => {
      const aStats = playerStatsByPlayerId.get(a.getPlayerId());
      const bStats = playerStatsByPlayerId.get(b.getPlayerId());
      assert(aStats, "expected aStats");
      assert(bStats, "expected bStats");
      return bStats.performance - aStats.performance;
    });
  }
}

export default HostParty;
