import { GameEntryData } from "./GameEntry";

export const HOST_PEER_ID_PREFIX = "host-pid-";
export const PLAYER_PEER_ID_PREFIX = "player-pid-";

export const GAMES_HOST = import.meta.env.VITE_GAMES_HOST;
export const GAMES_PATH = import.meta.env.VITE_GAMES_PATH;

export const CATEGORIES = {
  EXPERIMENTAL: "experimental",
  COMBAT: "combat",
  RACING: "racing",
  DRIVING: "driving",
} as const;

export const GAME_ENTRY_DATA: Array<GameEntryData> = [
  {
    id: "testgame",
    gameId: "testgame",
    gameModeId: "testing",
    gameName: "Test Game",
    gameModeName: "Testing",
    descriptionShort: "Short description...",
    descriptionLong: "Long description...",
    minPlayers: 1,
    maxPlayers: 8,
    categories: [CATEGORIES.EXPERIMENTAL],
    coverImage: "/game-assets/cover-placeholder.png",
  },
  {
    id: "runman:run",
    gameId: "runman",
    gameModeId: "run",
    gameName: "Runman",
    gameModeName: "Run",
    descriptionShort: "Run from the ghosts and sabotage others.",
    descriptionLong: "Run from the ghosts and sabotage others.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/runman/run/cover.png",
  },
  {
    id: "runman:dungeon",
    gameId: "runman",
    gameModeId: "dungeon",
    gameName: "Runman",
    gameModeName: "Dungeon",
    descriptionShort: "Get to the portals and survive while running from ghosts.",
    descriptionLong: "Get to the portals and survive while running from ghosts.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/runman/dungeon/cover.png",
  },
  {
    id: "sumo:ring",
    gameId: "sumo",
    gameModeId: "ring",
    gameName: "Sumo",
    gameModeName: "The Ring",
    descriptionShort: "Stay inside the ring. Push others out!",
    descriptionLong: "Stay inside the ring. Push others out!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/sumo/ring/cover.png",
  },
  {
    id: "sumo:lastCall",
    gameId: "sumo",
    gameModeId: "lastCall",
    gameName: "Sumo",
    gameModeName: "Last Call",
    descriptionShort: "Take and hold the last beer and drink it!",
    descriptionLong: "Take and hold the last beer and drink it!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/sumo/lastCall/cover.png",
  },
  {
    id: "sumo:king",
    gameId: "sumo",
    gameModeId: "king",
    gameName: "Sumo",
    gameModeName: "King of the Hill",
    descriptionShort: "All players inside the ring will gain score. Push others out!",
    descriptionLong: "All players inside the ring will gain score. Push others out!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/sumo/king/cover.png",
  },
  {
    id: "partycars:parking_collect",
    gameId: "partycars",
    gameModeId: "parking_collect",
    gameName: "Party Cars",
    gameModeName: "Capture Spots",
    descriptionShort: "Capture spots to score. Spots can be captured only once.",
    descriptionLong: "Capture spots to score. Spots can be captured only once.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.DRIVING],
    coverImage: "/game-assets/partycars/parking_collect/cover.png",
  },
  {
    id: "partycars:parking_fill",
    gameId: "partycars",
    gameModeId: "parking_fill",
    gameName: "Party Cars",
    gameModeName: "Limited Space",
    descriptionShort: "Don't be the last one without a parking spot.",
    descriptionLong: "Don't be the last one without a parking spot.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.DRIVING],
    coverImage: "/game-assets/partycars/parking_fill/cover.png",
  },
  {
    id: "partycars:raft",
    gameId: "partycars",
    gameModeId: "raft",
    gameName: "Party Cars",
    gameModeName: "Ferry Race",
    descriptionShort: "Race to the ferry and once ashore, race to the finish line!",
    descriptionLong: "Race to the ferry and once ashore, race to the finish line!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.DRIVING],
    coverImage: "/game-assets/partycars/raft/cover.png",
  },
  {
    id: "partycars:heist_bank",
    gameId: "partycars",
    gameModeId: "heist_bank",
    gameName: "Party Cars",
    gameModeName: "Heist",
    descriptionShort: "Run from the cops. Bump to others to steal their share.",
    descriptionLong: "Run from the cops. Bump to others to steal their share.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.DRIVING],
    coverImage: "/game-assets/partycars/heist_bank/cover.png",
  },
  {
    id: "partycars:blocks",
    gameId: "partycars",
    gameModeId: "blocks",
    gameName: "Party Cars",
    gameModeName: "Blocks",
    descriptionShort: "Blocks will eventually break. :(",
    descriptionLong: "Blocks will eventually break. :(",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.DRIVING],
    coverImage: "/game-assets/partycars/blocks/cover.png",
  },
  {
    id: "abstract:balance",
    gameId: "abstract",
    gameModeId: "balance",
    gameName: "Abstract",
    gameModeName: "Balance",
    descriptionShort: "Stay on top of the balance board!",
    descriptionLong: "Stay on top of the balance board!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/abstract/balance/cover.png",
  },
  {
    id: "abstract:bubble",
    gameId: "abstract",
    gameModeId: "bubble",
    gameName: "Abstract",
    gameModeName: "Bubble Up",
    descriptionShort: "Stay in the frame by hopping on the bubbles!",
    descriptionLong: "Stay in the frame by hopping on the bubbles!",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/abstract/bubble/cover.png",
  },
  {
    id: "kinderzeichnung:fiesta",
    gameId: "kinderzeichnung",
    gameModeId: "fiesta",
    gameName: "Kinderzeichnung",
    gameModeName: "Fiesta",
    descriptionShort: "Do not hit lines, box others.",
    descriptionLong: "Do not hit lines, box others.",
    minPlayers: 2,
    maxPlayers: 8,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/kinderzeichnung/fiesta/cover.png",
  },
  {
    id: "four_pong",
    gameId: "four_pong",
    gameModeId: "classic",
    gameName: "Four Pong",
    gameModeName: "Classic",
    descriptionShort: "Ping pong!",
    descriptionLong: "Ping pong!",
    minPlayers: 2,
    maxPlayers: 4,
    categories: [CATEGORIES.COMBAT],
    coverImage: "/game-assets/four_pong/cover.png",
  },
] as const;

export const CLIENT_CONNECTION_STATUS = {
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  CONNECTED_ACTIVE: "connected_active",
  CONNECTED_INACTIVE: "connected_inactive",
} as const;
