export const toDecimalPlaces = (value: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
};

export const getRandomNumber = (length: number) => {
  return Math.random()
    .toString()
    .slice(2, length + 2);
};
