import faceHappy from "../../../../../assets/face-happy.svg";

const HeaderPlayerPlaceholder = () => {
  return (
    <div className="flex justify-center items-center mx-2.5 h-full w-[162px] border-[3px] border-solid border-violet-700 bg-indigo-950">
      <img className="w-[100px]" src={faceHappy} alt=""/>
    </div>
  );
};

export default HeaderPlayerPlaceholder;
