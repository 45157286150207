import { useCallback, useRef, useState } from "react";

const warn = (content: string) => console.warn("[wake-lock]: " + content);

export interface WakeLockOptions {
  onError?: (error: Error) => void;
  onRequest?: () => void;
  onRelease?: EventListener;
}

export const useWakeLockControls = ({ onError, onRequest, onRelease }: WakeLockOptions | undefined = {}) => {
  const [released, setReleased] = useState<boolean | undefined>();
  const wakeLock = useRef<WakeLockSentinel | null>(null);

  // https://caniuse.com/mdn-api_wakelock
  const isSupported = typeof window !== "undefined" && "wakeLock" in navigator;

  const request = useCallback(async () => {
    const isWakeLockAlreadyDefined = wakeLock.current != null;
    if (!isSupported) {
      return warn("Calling the `request` function has no effect, Wake Lock Screen API isn't supported");
    }
    if (isWakeLockAlreadyDefined) {
      return warn("Calling `request` multiple times without `release` has no effect");
    }

    try {
      wakeLock.current = await navigator.wakeLock.request("screen");

      wakeLock.current.onrelease = (e: Event) => {
        // Default to `true` - `released` API is experimental: https://caniuse.com/mdn-api_wakelocksentinel_released
        setReleased(wakeLock.current?.released || true);
        onRelease && onRelease(e);
        wakeLock.current = null;
      };

      onRequest && onRequest();
      setReleased(wakeLock.current?.released || false);
    } catch (error: unknown) {
      onError && onError(error as Error);
    }
  }, [isSupported, onRequest, onError, onRelease]);

  const release = useCallback(async () => {
    if (!isSupported) {
      return warn("Calling the `release` function has no effect, Wake Lock Screen API isn't supported");
    }

    if (wakeLock.current === null) {
      return;
    }

    await wakeLock.current.release();
  }, [isSupported]);

  return {
    isSupported,
    request,
    released,
    release,
    type: wakeLock.current?.type || undefined,
  };
};
