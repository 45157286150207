import { useHostStore } from "../useHostStore";
import PauseHud from "./PauseHud";
import PlayersHud from "./PlayersHud";
import ScreenPointHud from "./ScreenPointHud";
import WarmupDisclaimer from "./WarmupDisclaimer";

const GameHud = () => {
  const hostState = useHostStore((state) => state.hostState);
  const gameEntry = useHostStore((state) => state.gameEntry);

  return (
    <div id="game-hud" className="pointer-events-none absolute inset-0 overflow-hidden">
      {gameEntry?.getIsWarmup() && <WarmupDisclaimer />}
      <PlayersHud />
      <ScreenPointHud />
      <PauseHud isPaused={Boolean(hostState?.playlist?.isPaused)} />
    </div>
  );
};

export default GameHud;
