import { FocusContext } from "@noriginmedia/norigin-spatial-navigation";
import { PLAYLIST_BLUEPRINT_DATA } from "../../../../common/client/plugins/playlist/constants";
import { PlaylistBlueprintData } from "../../../../common/client/plugins/playlist/types";
import { IS_DEV } from "../../../constants";
import PlaylistItems from "../../playlist/PlaylistItems";
import useFocusableWithAction from "../../spatialNavigation/useFocusableWithAction";
import { playSound, SOUNDS } from "../../../sounds/ui/sounds";

const playlistBlueprints = (
  !IS_DEV ? PLAYLIST_BLUEPRINT_DATA.filter((bp) => !["dev_playlist"].includes(bp.id)) : PLAYLIST_BLUEPRINT_DATA
).filter((bp) => !["all_games_randomized_playlist"].includes(bp.id));

type Props = {
  onPlaylistBlueprintSelect: (playlistBlueprint: PlaylistBlueprintData) => void;
};

export const PLAYLIST_BROWSER_FOCUS_KEY = "playlistBrowser";

const PlaylistBrowser = ({ onPlaylistBlueprintSelect }: Props) => {
  const { ref, focusKey, hasFocusedChild } = useFocusableWithAction({
    focusKey: PLAYLIST_BROWSER_FOCUS_KEY,
    trackChildren: true,
    onFocus: () => {
      playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });
    },
  });

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={`absolute inset-0 flex h-full w-full flex-col items-center justify-center transition-all duration-300 ${hasFocusedChild ? "translate-y-[0%]" : "translate-y-full"}`}
      >
        <PlaylistItems playlistBlueprints={playlistBlueprints} onSelect={onPlaylistBlueprintSelect} />
      </div>
    </FocusContext.Provider>
  );
};

export default PlaylistBrowser;
