import { FrameEventMessage } from "@shared/src/gc/events/FrameEventEmitter";
import { HudConfig, HudScreenPointData, PlayersHudData } from "@shared/src/gc/hud/types";
import HostPlugin, { HostPluginEvents } from "./HostPlugin";

export type HudHostPluginEvents = HostPluginEvents & {
  hud_setup: (hudConfig: HudConfig) => void;
  hud_players_update: (playersHudData: PlayersHudData) => void;
  hud_screen_point_update: (hudScreenPointData: HudScreenPointData) => void;
  hud_clear: () => void;
};

class HudHostPlugin extends HostPlugin<HudHostPluginEvents> {
  private hudConfig: HudConfig | null = null;
  private playersHudData: PlayersHudData | null = null;
  private hudScreenPointData: HudScreenPointData | null = null;

  constructor() {
    super("HudHostPlugin");
  }

  handleFrameEvent = (frameEvent: FrameEventMessage) => {
    switch (frameEvent.eventName) {
      case "hud_setup":
        this.setHudConfig(frameEvent.data as HudConfig);
        break;
      case "hud_players_update":
        this.updatePlayersHud(frameEvent.data as PlayersHudData);
        break;
      case "hud_screen_point_update":
        this.updateHudScreenPointData(frameEvent.data as HudScreenPointData);
        break;
    }
  };

  setHudConfig(hudConfig: HudConfig) {
    this.hudConfig = hudConfig;
    this.dispatch("hud_setup", hudConfig);
  }

  updatePlayersHud(playersHudData: PlayersHudData) {
    this.playersHudData = playersHudData;
    this.dispatch("hud_players_update", playersHudData);
  }

  updateHudScreenPointData(hudScreenPointData: HudScreenPointData) {
    this.hudScreenPointData = hudScreenPointData;
    this.dispatch("hud_screen_point_update", hudScreenPointData);
  }

  clear() {
    this.hudConfig = null;
    this.playersHudData = null;
    this.hudScreenPointData = null;
    this.dispatch("hud_clear");
  }

  getHudConfig() {
    return this.hudConfig;
  }

  getPlayersHudData() {
    return this.playersHudData;
  }

  getHudScreenPointData() {
    return this.hudScreenPointData;
  }
}

export default HudHostPlugin;
