import { useEffect } from "react";
import { HOST_STATUS } from "../../../common/client/host/HostClient";
import PlayerClient from "../../../common/client/player/PlayerClient";
import { PLAYLIST_STATES } from "../../../common/client/plugins/playlist/PlaylistHostPlugin";
import PlayerConnectView from "../PlayerConnectView";
import ConsoleFeedContainer from "../consoleFeed/ConsoleFeedContainer";
import PlayerFrame from "../frame/PlayerFrame";
import GameResultsView from "../gameResultsView/GameResultsView";
import PlayerMenuProvider from "../menu/PlayerMenuProvider";
import PlayerSetupGameView from "../setupGameView/PlayerSetupGameView";
import { usePlayerStore } from "../usePlayerStore";
import PauseOverlay from "./PauseOverlay";
import PlayerAutoReconnectOverlay from "./PlayerAutoReconnectOverlay";
import PlayerColorBorder from "./PlayerColorBorder";
import PartyLeaderView from "./partyLeaderView/PartyLeaderView";
import PartyMemberView from "./partyMemberView/PartyMemberView";
import PlayerLatencyIndicator from "./playerLatency/PlayerLatencyIndicator";

const PlayerView = () => {
  const inParty = usePlayerStore((state) => state.inParty);
  const isPartyLeader = usePlayerStore((state) => state.isPartyLeader);
  const hostState = usePlayerStore((state) => state.hostState);

  useEffect(() => {
    if (!inParty) {
      PlayerClient.forceScreenOrientation.setMode("portrait");
    }
  }, [inParty]);

  useEffect(() => {
    switch (hostState?.status) {
      case HOST_STATUS.LOBBY:
        PlayerClient.forceScreenOrientation.setMode("portrait");
        break;
      case HOST_STATUS.PLAYING:
        PlayerClient.forceScreenOrientation.setMode("landscape");
        break;
    }
  }, [hostState?.status]);

  const consoleComponent = <ConsoleFeedContainer />;

  if (!inParty) {
    return (
      <>
        <PlayerConnectView />
        {consoleComponent}
      </>
    );
  }

  const renderView = () => {
    if (hostState?.playlist?.state) {
      switch (hostState.playlist.state) {
        case PLAYLIST_STATES.SETUP_GAME:
        case PLAYLIST_STATES.PRE_PLAY:
        case PLAYLIST_STATES.PLAY:
        case PLAYLIST_STATES.POST_PLAY:
          return (
            <>
              {hostState.playlist.isPaused && <PauseOverlay />}
              {hostState.playlist.state === PLAYLIST_STATES.SETUP_GAME && <PlayerSetupGameView />}
              <PlayerFrame />
            </>
          );
          break;
        case PLAYLIST_STATES.GAME_END:
        case PLAYLIST_STATES.NEXT_GAME_PREVIEW:
        case PLAYLIST_STATES.PLAYLIST_END:
          return <GameResultsView />;
        default:
          throw new Error(`Unhandled playlistState: ${hostState.playlist.state}`);
      }
    }

    return isPartyLeader ? <PartyLeaderView /> : <PartyMemberView />;
  };

  return (
    <>
      <PlayerAutoReconnectOverlay />
      <PlayerColorBorder />
      <PlayerLatencyIndicator />
      <PlayerMenuProvider />
      {consoleComponent}
      {renderView()}
    </>
  );
};

export default PlayerView;
