import { createContext, useRef } from "react";
import createPlayerStore, { PlayerStore } from "./createPlayerStore";

export const PlayerContext = createContext<PlayerStore | null>(null);

type PlayerProviderProps = React.PropsWithChildren;

function PlayerProvider({ children }: PlayerProviderProps) {
  const storeRef = useRef<PlayerStore>();
  if (!storeRef.current) {
    storeRef.current = createPlayerStore();
  }
  return <PlayerContext.Provider value={storeRef.current}>{children}</PlayerContext.Provider>;
}

export default PlayerProvider;
