import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PLAYLIST_BLUEPRINT_DATA } from "../../../common/client/plugins/playlist/constants";
import HostClient, { HOST_STATUS } from "../../../common/client/host/HostClient";
import { IS_DEV } from "../../constants";
import PlaylistBlueprint from "../../../common/client/plugins/playlist/PlaylistBlueprint";
import { useHostActions } from "../useHostActions";
import { useHostStore } from "../useHostStore";

const useDevAutoStartPlaylist = () => {
  const hostState = useHostStore((state) => state.hostState);
  const playerCount = useHostStore((state) => state.playerCount);
  const actions = useHostActions();
  const [params] = useSearchParams();
  const waitPlayers = Number(params.get("waitPlayers")) || 2;

  useEffect(() => {
    if (!IS_DEV) {
      return;
    }

    if (hostState.status !== HOST_STATUS.LOBBY) {
      return;
    }

    const playlistId = params.get("playlist");
    if (!playlistId) {
      return;
    }

    const playlistBlueprintData = PLAYLIST_BLUEPRINT_DATA.find(
      (playlistBlueprint) => playlistBlueprint.id === playlistId,
    );
    if (!playlistBlueprintData) {
      return;
    }

    if (HostClient.party.getPlayers().length < waitPlayers) {
      return;
    }

    console.debug("DEV AUTO START PLAYLIST FROM BLUEPRINT:", playlistBlueprintData);
    actions.startPlaylistFromBlueprint(new PlaylistBlueprint(playlistBlueprintData));
  }, [params, actions, waitPlayers, playerCount, hostState.status]);
};

export default useDevAutoStartPlaylist;
