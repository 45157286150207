import { EVENT_TYPE } from "@shared/src/gc/events/constants";
import { GameEventMessage } from "@shared/src/gc/events/GameEventEmitter";
import { DataConnection } from "peerjs";
import { IS_DEV } from "../../../domain/constants";
import { assert } from "../../logger/assert";
import { getRandomNumber } from "../../utils/number";
import { ClientConnectionStatus, ConnectionErrorEventData } from "../ClientConnection";
import { CLIENT_CONNECTION_STATUS, HOST_PEER_ID_PREFIX } from "../constants";
import PeerManager, { PeerManagerEvents } from "../PeerManager";
import { PlayerClientEventMessage } from "../player/PlayerClient";
import HostClientConnection from "./HostClientConnection";

export interface HostPeerManagerEvents extends PeerManagerEvents {
  connection_data: (data: { clientConnection: HostClientConnection; data: unknown }) => void;
  connected: (data: { clientConnection: HostClientConnection; metadata: unknown }) => void;
  status_change: (data: { clientConnection: HostClientConnection; status: ClientConnectionStatus }) => void;
  disconnected: (data: { peerId: string }) => void;
  connection_error: (data: { connection: DataConnection; error: ConnectionErrorEventData }) => void;
  connections_change: () => void;
}

export const PLAYER_CONNECT_TYPE = {
  JOIN: "join",
  SILENT_AUTO_RECONNECT: "silentAutoReconnect",
} as const;

type PlayerConnectType = (typeof PLAYER_CONNECT_TYPE)[keyof typeof PLAYER_CONNECT_TYPE];

export type PlayerConnectMetadata = {
  playerName: string;
  type: PlayerConnectType;
};

class HostPeerManager extends PeerManager<HostPeerManagerEvents> {
  private clientConnections: HostClientConnection[] = [];
  private clientConnectionByPeerId: Record<string, HostClientConnection> = {};

  constructor() {
    super();
  }

  private addConnection(clientConnection: HostClientConnection): void {
    this.debug("addConnection() - peerId:", clientConnection.getPeerId());

    this.clientConnections.push(clientConnection);
    this.clientConnectionByPeerId[clientConnection.getPeerId()] = clientConnection;
  }

  private removeConnection(clientConnection: HostClientConnection): void {
    this.debug("removeConnection() - peerId:", clientConnection.getPeerId());

    this.clientConnections = this.clientConnections.filter(
      (connection) => connection.getPeerId() !== clientConnection.getPeerId(),
    );
    delete this.clientConnectionByPeerId[clientConnection.getPeerId()];
  }

  private createNewClientConnection(peerId: string): HostClientConnection {
    this.debug("createNewClientConnection() - peerId:", peerId);

    const clientConnection = new HostClientConnection(peerId);
    this.addConnection(clientConnection);

    clientConnection.onData((data: unknown) => {
      this.dispatch("connection_data", { clientConnection, data });
    });

    clientConnection.onConnected((data) => {
      this.debug("onConnected() - peerId:", peerId);

      this.dispatch("connected", {
        clientConnection,
        metadata: data.metadata,
      });
    });

    clientConnection.onStatusChange((data) => {
      this.debug("onStatusChange() - peerId:", peerId, "status:", data.status);

      this.dispatch("status_change", { clientConnection, status: data.status });
    });

    clientConnection.onDisconnected(() => {
      this.debug("onDisconnected() - peerId:", peerId);

      this.removeConnection(clientConnection);
      this.dispatch("disconnected", { peerId });
      this.dispatchConnectionsChange();
    });

    this.dispatchConnectionsChange();

    return clientConnection;
  }

  public setup() {
    super.setup();

    this.onPeerCreated((peer) => {
      this.debug("onPeerCreated()");

      peer.on("connection", (connection: DataConnection) => {
        this.debug("peer on connection - peerId:", connection.peer);

        connection.on("open", () => {
          this.debug("connection open - peerId:", connection.peer);

          const connectionMetadata = connection.metadata as PlayerConnectMetadata;
          const isSilentAutoReconnect = connectionMetadata.type === PLAYER_CONNECT_TYPE.SILENT_AUTO_RECONNECT;

          const clientConnection =
            this.clientConnectionByPeerId[connection.peer] || this.createNewClientConnection(connection.peer);

          clientConnection.setConnection(connection, isSilentAutoReconnect);
        });
        connection.on("close", () => {
          this.debug("connection close - peerId:", connection.peer);

          const clientConnection = this.clientConnectionByPeerId[connection.peer];
          assert(clientConnection, "clientConnection expected");
          clientConnection.unsetConnection(connection);
        });
        connection.on("error", (error) => {
          console.error("Connection error", error);
          this.dispatch("connection_error", { connection, error });
        });
      });
    });

    this.createNewPeer(`${HOST_PEER_ID_PREFIX}${getRandomNumber(IS_DEV ? 4 : 6)}`);
  }

  private dispatchConnectionsChange = (): void => {
    this.dispatch("connections_change");
  };

  getNumberOfConnections(): number {
    return this.clientConnections.length;
  }

  getConnectionStatus(peerId: string): ClientConnectionStatus {
    const clientConnection = this.clientConnectionByPeerId[peerId];
    return clientConnection?.getStatus() || CLIENT_CONNECTION_STATUS.DISCONNECTED;
  }

  getConnectionStatusesByPeerId() {
    const statusByPeerId: Record<string, ClientConnectionStatus> = {};
    this.clientConnections.forEach((connection) => {
      statusByPeerId[connection.getPeerId()] = connection.getStatus();
    });

    return statusByPeerId;
  }

  disconnectPeer(peerId: string): void {
    this.debug("disconnectPeer() - peerId:", peerId);

    const clientConnection = this.clientConnectionByPeerId[peerId];
    clientConnection?.disconnect();
    delete this.clientConnectionByPeerId[peerId];
    this.dispatchConnectionsChange();
  }

  disconnect(): void {
    this.debug("disconnect()");

    this.clientConnections.forEach((c) => c.disconnect());
  }

  sendMessageToAllPeers(message: unknown, reliable = true): void {
    this.clientConnections.forEach((connection) => {
      const status = connection.getStatus();
      if (
        status === CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE ||
        status === CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE
      ) {
        void connection.sendMessage(message, reliable);
      }
    });
  }

  sendMessage(peerId: string, message: unknown, reliable = true): void {
    const clientConnection = this.clientConnectionByPeerId[peerId];
    if (!clientConnection) {
      throw new Error(`No connection to peerId ${peerId}`);
    }
    void clientConnection.sendMessage(message, reliable);
  }

  close(peerId: string): void {
    this.debug("close() - peerId:", peerId);

    const clientConnection = this.clientConnectionByPeerId[peerId];
    if (!clientConnection) {
      throw new Error(`No connection to peerId ${peerId}`);
    }
    clientConnection.disconnect();
  }

  onConnected(callback: (peerId: string, data: Partial<PlayerConnectMetadata> | undefined) => void): void {
    this.on("connected", ({ clientConnection, metadata }) => {
      callback(clientConnection.getPeerId(), metadata as Partial<PlayerConnectMetadata>);
    });
  }

  onData(callback: (peerId: string, data: unknown) => void): void {
    this.on("connection_data", ({ clientConnection, data }) => {
      callback(clientConnection.getPeerId(), data);
    });
  }

  onConnectionStatusChange(callback: (peerId: string, status: ClientConnectionStatus) => void): void {
    this.on("status_change", ({ clientConnection, status }) => {
      callback(clientConnection.getPeerId(), status);
    });
  }

  onGameEvent(callback: (event: GameEventMessage) => void): void {
    this.on("connection_data", ({ data }) => {
      if ((data as { type?: string }).type === EVENT_TYPE.GAME_EVENT) {
        callback(data as GameEventMessage);
      }
    });
  }

  onPlayerClientEvent(callback: (event: PlayerClientEventMessage) => void): void {
    this.on("connection_data", ({ data }) => {
      if ((data as { type?: string }).type === EVENT_TYPE.PLAYER_CLIENT_EVENT) {
        callback(data as PlayerClientEventMessage);
      }
    });
  }

  onDisconnected(callback: (peerId: string) => void): void {
    this.on("disconnected", ({ peerId }) => {
      callback(peerId);
    });
  }

  onConnectionError(callback: (peerId: string, error: ConnectionErrorEventData) => void): void {
    this.on("connection_error", ({ connection, error }) => {
      callback(connection.peer, error);
    });
  }

  onConnectionsChange(callback: () => void): void {
    this.on("connections_change", () => {
      callback();
    });
  }
}

export default HostPeerManager;
