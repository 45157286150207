import { assert } from "../../../common/logger/assert";
import { usePlayerStore } from "../usePlayerStore";

const PlayerColorBorder = () => {
  const player = usePlayerStore((state) => state.player);
  assert(player, "expected player");

  return (
    <div
      className={`user-select-none pointer-events-none absolute inset-0 z-20 border-[20px] border-solid border-player-${player.getColor()}-muted`}
    />
  );
};

export default PlayerColorBorder;
