import { assert } from "../../logger/assert";
import GameEntry, { GameEntryData } from "../GameEntry";
import GameManager, { GameManagerEvents } from "../GameManager";
import { PlayerClient } from "./PlayerClient";

export type PlayerGameManagerEvents = GameManagerEvents & {
  setup_game: (data: { gameEntry: GameEntry }) => void;
  unload_game: () => void;
};

class PlayerGameManager extends GameManager<PlayerGameManagerEvents> {
  protected client: PlayerClient | null = null;

  setup(client: PlayerClient): void {
    this.client = client;

    client.hostClientEvents.on("setup_game", (data) => {
      this.setupGame(new GameEntry(data.gameEntry));
    });
    client.hostClientEvents.on("unload_game", () => {
      this.unloadGame();
    });
  }

  protected getClient() {
    assert(this.client, "Client not set");
    return this.client;
  }

  private setupGame(gameEntry: GameEntry) {
    this.gameEntry = gameEntry;

    this.dispatch("setup_game", { gameEntry });
  }

  public setupGameEntryFromData(gameEntryData: GameEntryData) {
    this.setupGame(new GameEntry(gameEntryData));
  }

  private unloadGame() {
    this.gameEntry = null;

    this.dispatch("unload_game");
  }
}

export default PlayerGameManager;
