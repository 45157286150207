import { useCallback, useEffect } from "react";
import HostClient from "../../../common/client/host/HostClient";
import { NavigationButton } from "./types";

import { getCurrentFocusKey, init, navigateByDirection } from "@noriginmedia/norigin-spatial-navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { HOST_ROOT_PATH } from "../constants";
import spatialNavigationActions from "./spatialNavigationActions";
import { playSound, SOUNDS } from "../../sounds/ui/sounds";

init({
  // debug: IS_DEV,
  // visualDebug: IS_DEV,
  useGetBoundingClientRect: true, // as we scale our UI to aspect ratio, for the debug elements to be in the correct position we need to use getBoundingClientRect
});

type Props = React.PropsWithChildren;

const SpatialNavigation = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleButton = useCallback(
    (button: NavigationButton) => {
      const actionHandlers = spatialNavigationActions.getActionHandlers(getCurrentFocusKey());

      if (button === "enter") {
        actionHandlers?.onActionEnter?.();
        return;
      }

      if (button === "back") {
        let preventDefaultBack = false;
        if (actionHandlers?.onActionBack) {
          preventDefaultBack = !actionHandlers.onActionBack();
          return;
        }

        if (preventDefaultBack) {
          return;
        }

        // avoid exiting the host session
        if (location.pathname === HOST_ROOT_PATH) {
          return;
        }

        navigate(-1);
        return;
      }

      let preventNavigation = false;

      if (actionHandlers?.onActionDirection) {
        preventNavigation = !actionHandlers.onActionDirection(button);
      }

      if (preventNavigation) {
        return;
      }

      navigateByDirection(button, {});
    },
    [navigate, location],
  );

  useEffect(
    function handlePartyLeaderInput() {
      const partyLeaderInputEventId = HostClient.on("party_leader_input", (data) => {
        const button: NavigationButton = data.button as NavigationButton;
        handleButton(button);
      });

      return () => {
        HostClient.off(partyLeaderInputEventId);
      };
    },
    [handleButton],
  );

  useEffect(
    function handleEscapeKey() {
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          playSound(SOUNDS.PREV_ITEM, { throttleMs: 50 });
          handleButton("back");
        }
      };

      document.addEventListener("keydown", handleEscape);

      return () => {
        document.removeEventListener("keydown", handleEscape);
      };
    },
    [handleButton],
  );

  return children;
};

export default SpatialNavigation;
