import { useState } from "react";
import { usePlayerStore } from "../../usePlayerStore";
import PlayerLatencyHistory from "./PlayerLatencyHistory";
import { LATENCY_SEVERITY_BG_TAILWIND_COLORS } from "./constants";
import { getSeverityIndex } from "./playerLatency";
import { PlayerConnectionStats } from "../../../../common/client/player/PlayerClientConnection";

const PlayerLatencyIndicator = () => {
  const latency = usePlayerStore((state) => state.networkLatency.latency);
  const playerClientConnectionStats = usePlayerStore((state) => state.playerClientConnectionStats);
  const [isGraphOpen, setIsGraphOpen] = useState(false);

  if (!latency && latency !== 0) {
    return null;
  }

  const severityIndex = getSeverityIndex(latency);

  const connectionStatsRow = (label: string, stats: PlayerConnectionStats) => {
    return (
      <div>
        <b className="pr-1">{label}</b>
        <u>
          L:
          {`${stats.localCandidate.type}[${stats.localCandidate.protocol.toUpperCase()}:${stats.localCandidate.networkType}]`}
        </u>
        <span className="pl-1">/ R:</span>
        {stats.remoteCandidate.type}
        <br />
      </div>
    );
  };

  return (
    <>
      {isGraphOpen && (
        <div className="z-latencyIndicator pointer-events-none absolute inset-0 h-[200px] w-full bg-black bg-opacity-50">
          <PlayerLatencyHistory />
        </div>
      )}

      <div
        className="z-latencyIndicator absolute right-5 top-5 min-w-12 text-center"
        onClick={() => {
          setIsGraphOpen(!isGraphOpen);
        }}
      >
        <div
          className={`min-w-16 bg-opacity-80 px-1 py-0.5 text-xs text-white ${LATENCY_SEVERITY_BG_TAILWIND_COLORS[severityIndex]}`}
        >
          {latency}ms
        </div>
      </div>
      {isGraphOpen && (
        <div className="z-latencyIndicator pointer-events-none absolute left-0 top-0 min-w-12">
          <div className={`flex min-w-16 flex-col bg-opacity-80 px-1 py-0.5 text-xs text-white`}>
            {playerClientConnectionStats ? (
              <>
                {connectionStatsRow("Reliable", playerClientConnectionStats.reliable)}
                {connectionStatsRow("Unreliable", playerClientConnectionStats.unreliable)}
              </>
            ) : (
              " (No connection stats available)"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerLatencyIndicator;
