import { useEffect, useRef } from "react";
import GameEntry from "../../../common/client/GameEntry";
import PlayerClient from "../../../common/client/player/PlayerClient";

const PlayerFrame = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (!iframeRef.current) return;
    PlayerClient.setupIframe(iframeRef.current);
  }, [iframeRef]);

  const gameEntry = PlayerClient.gameManager.getGameEntry();
  if (!gameEntry) throw new Error("Expected game to be set");

  return (
    <iframe
      id="player-frame"
      ref={iframeRef}
      src={GameEntry.getGamePlayerUrl(gameEntry)}
      allow="autoplay; fullscreen"
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
        border: 0,
      }}
    ></iframe>
  );
};

export default PlayerFrame;
