import HostClient from "../../../common/client/host/HostClient";
import { assert } from "../../../common/logger/assert";
import { H2 } from "../../../common/ui/heading/Heading";
import PlayerPlacementCard from "../player/PlayerPlacementCard";

const RoundPlacements = () => {
  const results = HostClient.party.partyStats.getLatestGameResults();

  if (!results) {
    return null;
  }

  const widthByPlacement = [
    "w-[280px]",
    "w-[240px]",
    "w-[210px]",
    "w-[140px]",
    "w-[140px]",
    "w-[140px]",
    "w-[140px]",
    "w-[140px]",
  ];

  if (results.playerIdsByPlacement.length <= 0) {
    return null;
  }

  return (
    <div className="relative">
      <H2 className="pb-6">Round placements</H2>
      <div className="flex space-x-6">
        {results.playerIdsByPlacement.map((playerId, placement) => {
          const player = HostClient.playersManager.getPlayerByPlayerId(playerId);
          assert(player, "expected player");
          return (
            <div className={widthByPlacement[placement]} key={playerId}>
              <PlayerPlacementCard player={player} placementNumber={placement} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoundPlacements;
