import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
  const releaseId = import.meta.env.VITE_RELEASE_ID || new Date().toISOString();

  Sentry.init({
    dsn: "https://fc378d19d863504966e7483afa22aff4@o4506631639597056.ingest.sentry.io/4506653889003520",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/couch\.dsb\.fi/, /^https:\/\/gamingcouch\.com/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 10% while in production and then sample at a higher rate in development.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: releaseId,
    environment: import.meta.env.VITE_RELEASE_DEPLOY_ENVIRONMENT,
  });
}
