import { UNSET_PLAYER_COLOR } from "@shared/src/gc/constants";
import { PlayerGameData } from "@shared/src/gc/types";
import { PlayerColor } from "@shared/src/gc/types";

export type PlayerData = {
  playerId: number;
  name: string;
  peerId: string;
  color: PlayerColor;
  isActive: boolean;
};

class Player {
  private playerId: number;
  private name: string;
  private peerId: string;
  private color: PlayerColor;
  private isActive: boolean = false;

  constructor(id: number, name: string, peerId: string) {
    this.playerId = id;
    this.name = name;
    this.peerId = peerId;
    this.color = UNSET_PLAYER_COLOR;
    this.isActive = true;
  }

  setColor(color: PlayerColor): void {
    this.color = color;
  }

  setPeerId(peerId: string): void {
    this.peerId = peerId;
  }

  getPlayerId(): number {
    return this.playerId;
  }

  getName(): string {
    return this.name;
  }

  getPeerId(): string {
    return this.peerId;
  }

  getColor(): PlayerColor {
    return this.color;
  }

  toData(): PlayerData {
    return {
      playerId: this.playerId,
      name: this.name,
      peerId: this.peerId,
      color: this.color,
      isActive: this.isActive,
    };
  }

  toGameData(): PlayerGameData {
    return {
      playerId: this.playerId,
      name: this.name,
      color: this.color,
    };
  }

  setActive(value: boolean): void {
    this.isActive = value;
  }

  getIsActive(): boolean {
    return this.isActive;
  }

  static fromData(data: PlayerData): Player {
    const player = new Player(data.playerId, data.name, data.peerId);
    player.setColor(data.color);
    player.setActive(data.isActive);
    return player;
  }

  clone(): Player {
    return Player.fromData(this.toData());
  }

  valueOf() {
    return this.playerId;
  }

  toString() {
    return `Player: ${this.name} (${this.playerId})`;
  }

  isEqualTo(to: { valueOf: () => unknown }) {
    return this.valueOf() === to.valueOf();
  }
}

export default Player;
