import Peer, { DataConnection } from "peerjs";
import { assert } from "../../logger/assert";
import ClientConnection from "../ClientConnection";
import { CLIENT_CONNECTION_STATUS } from "../constants";
import { PLAYER_CONNECT_TYPE, PlayerConnectMetadata } from "../host/HostPeerManager";

export type PlayerConnectionStats = {
  remoteCandidate: {
    protocol: string;
    type: string;
  };
  localCandidate: {
    protocol: string;
    networkType: string;
    type: string;
  };
};

export type PlayerClientConnectionStats = {
  reliable: PlayerConnectionStats;
  unreliable: PlayerConnectionStats;
};

type ConnectionParams = {
  peerId: string;
  options: ConnectionOptions;
};

type ConnectionOptions = {
  playerName: string;
};

class PlayerClientConnection extends ClientConnection {
  private peer: Peer;

  private prevConnectionParams: ConnectionParams | null = null;

  constructor(peer: Peer) {
    super();
    this.peer = peer;
  }

  private getConnectionStats = async (connection: DataConnection) => {
    this.debug("getConnectionStats() - getting stats...");

    const connectionStats: PlayerConnectionStats = {
      remoteCandidate: {
        protocol: "-",
        type: "-",
      },
      localCandidate: {
        protocol: "-",
        networkType: "-",
        type: "-",
      },
    };

    await connection.peerConnection.getStats().then((stats) => {
      stats.forEach((row: { type: string; candidateType: string; protocol: string; networkType: string }) => {
        switch (row.type) {
          case "remote-candidate":
            connectionStats.remoteCandidate = {
              protocol: row.protocol,
              type: row.candidateType,
            };
            break;
          case "local-candidate":
            connectionStats.localCandidate = {
              protocol: row.protocol,
              networkType: row.networkType,
              type: row.candidateType,
            };
            break;
        }
      });
    });

    this.debug("getConnectionStats() - finished - stats: ", connectionStats);

    return connectionStats;
  };

  private updateConnectionStats = async () => {
    assert(this.reliableConnection, "reliableConnection expected");
    assert(this.unreliableConnection, "unreliableConnection expected");

    const reliableConnectionStats = await this.getConnectionStats(this.reliableConnection);
    const unreliableConnectionStats = await this.getConnectionStats(this.unreliableConnection);

    const stats: PlayerClientConnectionStats = {
      reliable: reliableConnectionStats,
      unreliable: unreliableConnectionStats,
    };

    this.dispatch("peer_connection_stats", stats);
  };

  private setupCommonConnectionEvents = (connection: DataConnection) => {
    this.debug("setupCommonConnectionEvents()");

    void connection.peerConnection.addEventListener(
      "iceconnectionstatechange",
      (event) => {
        const rtcPeerConnection = event.currentTarget as RTCPeerConnection;
        this.debug("iceconnectionstatechange - new state: ", rtcPeerConnection.iceConnectionState);
        if (rtcPeerConnection.iceConnectionState === "disconnected") {
          // PeerJS connection does not have a way to recover from ice disconnected state,
          // so lets try to swap new connections in place of the old ones with trySilentAutoReconnect.
          this.trySilentAutoReconnect();
        }
      },
      false,
    );

    void connection.peerConnection.addEventListener(
      "selectedcandidatepairchange",
      () => {
        void this.updateConnectionStats();
      },
      false,
    );
  };

  trySilentAutoReconnect() {
    this.debug("trySilentAutoReconnect()");

    // PeerJS connection does not have a way to recover from ice disconnected state.
    //
    // Close old connections by making sure they don't trigger events any more,
    // as we will replace them with new PeerJS connections on the fly "silently".
    // Anything outside PlayerClientConnection should not be aware of this reconnection
    // on the PlayerClient side. The HostClient side will of course see the connection as a new one,
    // and needs to handle it accordingly.

    this.reliableConnection?.removeAllListeners();
    this.reliableConnection?.close();
    this.reliableConnection = null;

    this.unreliableConnection?.removeAllListeners();
    this.unreliableConnection?.close();
    this.unreliableConnection = null;

    assert(this.prevConnectionParams, "prevConnectionParams expected");
    this.connect(this.prevConnectionParams.peerId, this.prevConnectionParams.options, true);
  }

  connect(peerId: string, options: { playerName: string }, isSilentAutoReconnect = false) {
    this.debug("connect() - peerId: ", peerId, "options: ", options);
    if (this.reliableConnection) {
      this.debug("connect() - can't connect: reliableConnection already exists");
      this.dispatch("error", new Error("Reliable connection already exists"));
      return;
    }

    this.prevConnectionParams = {
      peerId,
      options,
    };

    !isSilentAutoReconnect && this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTING);

    const connectMetadata: PlayerConnectMetadata = {
      type: isSilentAutoReconnect ? PLAYER_CONNECT_TYPE.SILENT_AUTO_RECONNECT : PLAYER_CONNECT_TYPE.JOIN,
      playerName: options.playerName,
    };

    const reliableConnection = this.peer.connect(peerId, {
      reliable: true,
      metadata: connectMetadata,
    });
    this.reliableConnection = reliableConnection;
    this.setupCommonConnectionCallbacks(reliableConnection);

    reliableConnection.on("open", () => {
      this.debug("connect() - reliableConnection open");

      this.setupCommonConnectionEvents(reliableConnection);

      const unreliableConnection = this.openUnreliableConnection(peerId, connectMetadata);
      assert(unreliableConnection, "unreliableConnection expected");

      unreliableConnection.on("open", () => {
        this.debug("connect() - unreliableConnection open");

        this.setupCommonConnectionEvents(unreliableConnection);

        if (!isSilentAutoReconnect) {
          this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE);
          this.dispatch("connected", {
            metadata: reliableConnection.metadata,
          });
        }

        void this.updateConnectionStats();
      });
    });
  }

  private openUnreliableConnection(peerId: string, connectMetadata: PlayerConnectMetadata) {
    this.debug("openUnreliableConnection() - peerId: ", peerId);

    if (this.unreliableConnection) {
      this.dispatch("error", new Error("Unreliable connection already exists"));
      return;
    }

    const connection = this.peer.connect(peerId, {
      reliable: false,
      metadata: connectMetadata,
    });
    this.unreliableConnection = connection;
    this.setupCommonConnectionCallbacks(connection);

    return connection;
  }

  getPeer() {
    return this.peer;
  }
}

export default PlayerClientConnection;
