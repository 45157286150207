import { H4 } from "../../../common/ui/heading/Heading";
import PauseOverlay from "../playerView/PauseOverlay";
import { usePlayerStore } from "../usePlayerStore";

const GameResultsView = () => {
  const hostState = usePlayerStore((state) => state.hostState);
  const isPaused = hostState?.playlist?.isPaused;

  if (isPaused) {
    return <PauseOverlay />;
  }

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black">
      <H4>Look up, you phone addict...</H4>
    </div>
  );
};

export default GameResultsView;
