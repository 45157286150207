import { SharedPartyState } from "@shared/src/gc/types";
import Party, { PartyEvents } from "../Party";
import { PlayerClient } from "./PlayerClient";

export type PlayerPartyEvents = PartyEvents & {
  left_party: () => void;
};

class PlayerParty extends Party<PlayerPartyEvents> {
  protected client: PlayerClient;

  constructor(client: PlayerClient) {
    super(client);
    this.client = client;
  }

  setJoinedParty(state: SharedPartyState) {
    this.writePartyState(state);
  }

  setLeftParty() {
    this.clearPartyState();
    this.dispatch("left_party");
    this.dispatchChange();
  }

  private writePartyState(state: SharedPartyState) {
    this.playerIds = state.playerIds;
    this.partyLeaderPlayerId = state.partyLeaderPlayerId;
  }

  private clearPartyState() {
    this.playerIds = [];
    this.partyLeaderPlayerId = null;
  }

  setPartyState(state: SharedPartyState) {
    this.writePartyState(state);
    this.dispatchChange();
  }

  getIsInParty() {
    const player = this.client.playersManager.getSelf();
    if (!player) {
      return false;
    }
    return this.playerIds.includes(player.getPlayerId());
  }

  getIsSelfPartyLeader() {
    return this.getPartyLeader() === this.client.playersManager.getSelf();
  }
}

export default PlayerParty;
