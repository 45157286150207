import { useParams } from "react-router-dom";
import { PLAYLIST_BLUEPRINT_DATA } from "../../../../common/client/plugins/playlist/constants";
import { assert } from "../../../../common/logger/assert";
import PlaylistBlueprint from "../../../../common/client/plugins/playlist/PlaylistBlueprint";
import PlaylistPage from "../../playlist/page/PlaylistPage";

const PlaylistStart = () => {
  const { playlistBlueprintId } = useParams();
  const playlistBlueprintData = PLAYLIST_BLUEPRINT_DATA.find((pb) => pb.id === playlistBlueprintId);
  assert(playlistBlueprintData, "PlaylistBlueprint not found");

  const playlistBlueprint = new PlaylistBlueprint(playlistBlueprintData);

  return <PlaylistPage playlistBlueprint={playlistBlueprint} />;
};

export default PlaylistStart;
