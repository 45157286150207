import EventEmitter from "@shared/src/gc/events/EventEmitter";
import { SharedPartyState } from "@shared/src/gc/types";
import { SharedHostState } from "../../../../client/src/common/client/host/HostClient";
import { SharedPlayersState } from "../../../../client/src/common/client/PlayersManager";
import { GameEntryData } from "../../../../client/src/common/client/GameEntry";

export type HostClientEvents = {
  setup_game: (data: { gameEntry: GameEntryData }) => void;
  unload_game: () => void;
  host_state_change: (data: { hostState: SharedHostState }) => void;
  joined_party: (data: {
    hostState: SharedHostState;
    playersState: SharedPlayersState;
    playerId: number;
    partyState: SharedPartyState;
    gameEntry?: GameEntryData;
  }) => void;
  players_change: (data: SharedPlayersState) => void;
  party_change: (data: SharedPartyState) => void;
  party_player_left: (data: { playerId: number }) => void;
  request_party_leader_switch: (data: { playerId: number }) => void;
  accept_party_leader_switch: (data: { playerId: number }) => void;
  pong: (id: number) => void;
};

export type HostClientEventName = keyof HostClientEvents;

export type HostClientEventData<
  T extends HostClientEventName = HostClientEventName
> = Parameters<HostClientEvents[T]>[0];

class HostClientEventEmitter extends EventEmitter<HostClientEvents> {}

export default HostClientEventEmitter;
