export const HUD_SCREEN_POINT_TYPE = {
  NAME: "name",
} as const;

export const PLAYERS_HUD_VALUE_TYPE = {
  POINTS_SMALL: "pointsSmall",
  STATUS: "status",
  LIVES: "lives",
  TEXT: "text",
} as const;

export const PLAYERS_HUD_VALUE_TYPE_STATUS = {
  NEUTRAL: "neutral",
  PENDING: "pending",
  SUCCESS: "success",
  FAILURE: "failure",
} as const;
