import { useEffect } from "react";
import Client from "../../common/client/player/PlayerClient";
import ConsoleFeedHook from "../../common/consoleFeed/ConsoleFeedHook";
import useWakeLock from "../../common/hook/useWakeLock";
import ErrorNotifications from "../ErrorNotifications";
import { IS_DEV } from "../constants";
import PlayerProvider from "./PlayerProvider";
import PlayerView from "./playerView/PlayerView";

const PlayerClient = () => {
  useWakeLock();

  if (!IS_DEV) {
    window.onbeforeunload = function () {
      return "Are you sure you want to leave?";
    };
  }

  useEffect(() => {
    document.documentElement.classList.add("player-client");

    return () => {
      document.documentElement.classList.remove("player-client");
    };
  }, []);

  return (
    <div id="player-client" className="theme-player h-full bg-zinc-800">
      <ConsoleFeedHook />
      <PlayerProvider>
        <PlayerView />
        <ErrorNotifications client={Client} />
      </PlayerProvider>
    </div>
  );
};

export default PlayerClient;
