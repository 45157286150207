import { useEffect } from "react";

const useHideCursorOnIdle = (timeout = 3000) => {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const handleMouseMove = () => {
      if (document.body.style.cursor === "none") {
        document.body.style.cursor = "auto";
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (document.body.style.cursor !== "none") {
          document.body.style.cursor = "none";
        }
      }, timeout);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timer);
    };
  }, [timeout]);
};

export default useHideCursorOnIdle;
