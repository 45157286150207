import { useFocusable, UseFocusableConfig } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import { OmitFields } from "../../../common/typeScript/utils";
import spatialNavigationActions from "./spatialNavigationActions";

interface Props extends OmitFields<UseFocusableConfig, "onEnterPress" | "onEnterRelease"> {
  onActionEnter?: () => void;
  onActionDirection?: (direction: string) => boolean;
}

const useFocusableWithAction = (props: Props) => {
  const result = useFocusable({
    ...props,
    onEnterRelease: props.onActionEnter,
    onArrowPress: props.onActionDirection,
  });

  useEffect(() => {
    if (!props.onActionEnter) {
      return;
    }

    spatialNavigationActions.register(result.focusKey, {
      onActionEnter: props.onActionEnter,
      onActionDirection: props.onActionDirection,
    });

    return () => {
      spatialNavigationActions.unregister(result.focusKey);
    };
  }, [result.focusKey, props.onActionEnter, props.onActionDirection]);

  return { ...result, action: props.onActionEnter };
};

export default useFocusableWithAction;
