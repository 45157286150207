import { FrameEventData, FrameEventMessage } from "@shared/src/gc/events/FrameEventEmitter";
import { GameEventMessage } from "@shared/src/gc/events/GameEventEmitter";
import HostClientEventEmitter from "@shared/src/gc/events/HostClientEventEmitter";
import { PlayerClientEventData, PlayerClientEventName } from "@shared/src/gc/events/PlayerClientEventEmitter";
import { EVENT_TYPE } from "@shared/src/gc/events/constants";
import { SharedPartyState } from "@shared/src/gc/types";
import { assert } from "../../logger/assert";
import BaseClient, { BaseClientEvents, UnknownFrameMessage } from "../BaseClient";
import { SharedPlayersState } from "../PlayersManager";
import { SharedHostState } from "../host/HostClient";
import LatencyPlayerPlugin from "../plugins/latency/LatencyPlayerPlugin";
import PlayerGameManager from "./PlayerGameManager";
import PlayerParty from "./PlayerParty";
import PlayerPeerManager from "./PlayerPeerManager";
import PlayerPlayersManager from "./PlayerPlayersManager";
import { GameEntryData } from "../GameEntry";

export type PlayerClientEventMessage = {
  type: "PLAYER_CLIENT_EVENT";
  eventName: PlayerClientEventName;
  playerId: number;
  data?: PlayerClientEventData<PlayerClientEventName>;
};

export type Events = BaseClientEvents & {
  party_change: (partyState: SharedPartyState) => void;
  left_party: () => void;
  joined_party: (data: {
    hostState: SharedHostState;
    playersState: SharedPlayersState;
    partyState: SharedPartyState;
    playerId: number;
    gameEntry?: GameEntryData;
  }) => void;
  party_player_left: (data: { playerId: number }) => void;
  request_party_leader_switch: (data: { playerId: number }) => void;
  accept_party_leader_switch: (data: { playerId: number }) => void;
  host_state_change: (hostState: SharedHostState) => void;
};

export class PlayerClient extends BaseClient<Events> {
  readonly playersManager: PlayerPlayersManager;
  readonly party: PlayerParty;
  readonly gameManager: PlayerGameManager;
  readonly peerManager: PlayerPeerManager;
  readonly hostClientEvents: HostClientEventEmitter;
  readonly latencyPlugin: LatencyPlayerPlugin;

  private hostState: SharedHostState | null = null;

  private writeHostState = (hostState: SharedHostState) => {
    this.hostState = hostState;
  };

  private setHostState = (hostState: SharedHostState) => {
    this.writeHostState(hostState);
    this.dispatchHostStateChange();
  };

  private dispatchHostStateChange = () => {
    if (!this.hostState) {
      throw new Error("Host state not set");
    }
    this.dispatch("host_state_change", this.hostState);
  };

  constructor() {
    super(false);
    this.playersManager = new PlayerPlayersManager();
    this.party = new PlayerParty(this);
    this.gameManager = new PlayerGameManager();
    this.peerManager = new PlayerPeerManager();
    this.hostClientEvents = new HostClientEventEmitter();
    this.latencyPlugin = new LatencyPlayerPlugin();
  }

  public setup(): void {
    super.setup();
    this.gameManager.setup(this);
    this.setupPeerManager();
    this.latencyPlugin.setup(this);
    this.addPlugin(this.latencyPlugin);

    window.addEventListener("beforeunload", () => {
      this.peerManager.disconnect();
    });

    this.setupPartyEvents();
    this.setupFrameEvents();
  }

  private setupPeerManager() {
    this.peerManager.onPeerReady(() => {
      this.setupPeerManagerEvents();
      this.setupHostClientEvents();
    });

    this.peerManager.setup();
  }

  private setupPeerManagerEvents() {
    this.peerManager.onGameEvent((event) => {
      this.gameEvents.dispatch(event.playerId, event.eventName, event.data);
    });
    this.peerManager.onHostClientEvent((event) => {
      this.hostClientEvents.dispatch(event.eventName, event.data);

      this.plugins.forEach((plugin) => {
        plugin.handleHostClientEvent(event);
      });
    });
    this.peerManager.onData((data) => {
      switch ((data as { type?: string }).type) {
        // TODO: Figure out how to pass errors around properly.
        // TODO: Add possible types for onData data, so it can then be narrowed and has proper type.
        case "error":
          this.dispatch("error", { message: (data as { message?: string }).message });
          break;
      }
    });
    this.peerManager.onDisconnected(() => {
      this.playersManager.reset();
      this.party.setLeftParty();
      this.latencyPlugin.stop();
    });
    this.peerManager.onClientConnectionError((error) => {
      console.error("PeerManager client connection error", error);
      this.dispatch("error", error);
    });
    this.peerManager.on("error", (error) => {
      console.error("PeerManager error", error);
      this.dispatch("error", error);
    });
    this.peerManager.onPeerError((error) => {
      this.dispatch("peer_error", error);
    });
  }

  private setupPartyEvents() {
    this.party.on("change", (partyState: SharedPartyState) => {
      this.dispatch("party_change", partyState);
      this.postEventToFrame("party_change", partyState, false);
    });

    this.party.on("left_party", () => {
      this.dispatch("left_party");
    });
  }

  private setupHostClientEvents() {
    this.hostClientEvents.on("host_state_change", (data) => {
      this.setHostState(data.hostState);
    });
    this.hostClientEvents.on("joined_party", (data) => {
      this.setHostState(data.hostState);
      if (data.gameEntry) {
        this.gameManager.setupGameEntryFromData(data.gameEntry);
      }
      this.playersManager.setFromSharedClientState(data.playersState);
      this.playersManager.setSelfPlayerId(data.playerId);
      this.party.setJoinedParty(data.partyState);
      this.latencyPlugin.start();
      this.dispatch("joined_party", data);
    });
    this.hostClientEvents.on("players_change", (data) => {
      this.playersManager.setFromSharedClientState(data);
    });
    this.hostClientEvents.on("party_change", (data) => {
      this.party.setPartyState(data);
    });
    this.hostClientEvents.on("party_player_left", (data) => {
      this.postEventToFrame("party_player_left", data, false);
      this.dispatch("party_player_left", data);
    });
    this.hostClientEvents.on("request_party_leader_switch", (data) => {
      this.dispatch("request_party_leader_switch", {
        playerId: data.playerId,
      });
    });
    this.hostClientEvents.on("accept_party_leader_switch", (data) => {
      this.dispatch("accept_party_leader_switch", {
        playerId: data.playerId,
      });
    });
  }

  private setupFrameEvents() {
    this.frameEvents.on("frame_script_ready", () => {
      const player = this.playersManager.getPlayerByPeerId(this.peerManager.getPeerId());
      assert(player, "expected player");

      const gameEntry = this.gameManager.getGameEntry();
      assert(gameEntry, "expected gameEntry");

      this.postEventToFrame("frame_init", {
        gameId: gameEntry.getGameId(),
        gameModeId: gameEntry.getGameModeId(),
        playerData: player.toGameData(),
        partyState: this.party.getSharedState(),
      });
    });
    this.frameEvents.on("setup_game_frame_done", () => {
      this.sendEventToHostClient("setup_game_frame_done");
    });
  }

  protected handleMessageFromFrame = (message: UnknownFrameMessage) => {
    switch ((message.data as { type?: string }).type) {
      case EVENT_TYPE.GAME_EVENT:
        {
          // This is game specific event, do not react to these here!
          const gameEvent = message.data as GameEventMessage;
          this.peerManager.sendMessage(gameEvent, gameEvent.reliable);
        }
        break;
      case EVENT_TYPE.FRAME_EVENT:
        {
          const frameEvent = message.data as FrameEventMessage;
          this.frameEvents.dispatch(frameEvent.eventName, frameEvent.data as FrameEventData);

          this.plugins.forEach((plugin) => {
            plugin.handleFrameEvent(frameEvent);
          });
        }
        break;
    }
  };

  createPlayerClientEventMessage(
    playerId: number,
    eventName: PlayerClientEventName,
    data?: PlayerClientEventData<PlayerClientEventName>,
  ): PlayerClientEventMessage {
    return {
      type: EVENT_TYPE.PLAYER_CLIENT_EVENT,
      eventName,
      data,
      playerId,
    };
  }

  sendEventToHostClient(
    eventName: PlayerClientEventName,
    data?: PlayerClientEventData<PlayerClientEventName>,
    reliable = true,
  ): void {
    const player = this.playersManager.getSelf();
    assert(player, "expected player");
    const message = this.createPlayerClientEventMessage(player.getPlayerId(), eventName, data);
    this.peerManager.sendMessage(message, reliable);
  }

  connect(hostPeerId: string, playerName: string): void {
    this.peerManager.connect(hostPeerId, { playerName });
  }

  requestSkipRound(): void {
    this.sendEventToHostClient("request_skip_round");
  }

  requestTogglePause(): void {
    this.sendEventToHostClient("request_toggle_pause");
  }

  requestPause(pause: boolean): void {
    this.sendEventToHostClient("request_pause", pause);
  }

  requestExitToLobby(): void {
    this.sendEventToHostClient("request_exit_to_lobby");
  }

  requestPartyLeaderSwitch(): void {
    this.sendEventToHostClient("request_party_leader_switch");
  }

  kickPlayer(playerId: number): void {
    this.sendEventToHostClient("kick_player", { playerId });
  }

  acceptPartyLeaderSwitch(playerId: number): void {
    this.sendEventToHostClient("accept_party_leader_switch", { playerId });
  }
}

export default new PlayerClient();
