import { PlayerClient } from "../player/PlayerClient";
import ClientPlugin from "./ClientPlugin";

export type PlayerPluginEvents = Record<string, never>;

class PlayerPlugin<T extends PlayerPluginEvents> extends ClientPlugin<T> {
  protected client: PlayerClient | null = null;

  protected getClient() {
    if (!this.client) {
      throw new Error("Client not set. Plugin setup not called?");
    }
    return this.client;
  }
}

export default PlayerPlugin;
