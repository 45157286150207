import { useEffect, useRef, useState } from "react";

const useCountdown = (seconds: number, callback: () => void, enabled = true, key?: number | string) => {
  const [currentKey, setCurrentKey] = useState(key);
  const [currentSeconds, setCurrentSeconds] = useState(seconds);
  const calledRef = useRef(false);

  useEffect(() => {
    if (enabled || currentKey !== key) {
      setCurrentSeconds(seconds);
      setCurrentKey(key);
      calledRef.current = false;
    }
  }, [seconds, key, currentKey, enabled]);

  useEffect(() => {
    if (!enabled || key !== currentKey) {
      return;
    }

    if (currentSeconds === 0) {
      if (calledRef.current) {
        return;
      }
      calledRef.current = true;
      callback();
      return;
    }

    const interval = setTimeout(() => {
      setCurrentSeconds((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [key, currentKey, seconds, currentSeconds, callback, enabled]);

  return currentSeconds;
};

export default useCountdown;
