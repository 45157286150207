import { PLAYER_COLORS_MUTED } from "@shared/src/gc/constants";
import Player from "../../../common/client/Player";

type Props = {
  player: Player;
};

const PlayerAvatar = ({ player }: Props) => {
  return (
    <div className="flex h-full w-full items-center justify-center text-center">
      <div
        className="flex h-36 w-36 items-center justify-center rounded-full border-2 border-gray-400 bg-red-600 text-center font-bold shadow-2xl"
        style={{
          backgroundColor: PLAYER_COLORS_MUTED[player.getColor()] as string,
        }}
      >
        {player.getName()}
      </div>
    </div>
  );
};

export default PlayerAvatar;
