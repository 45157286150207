import { useLongPress } from "react-use";
import { CLIENT_CONNECTION_STATUS } from "../../../common/client/constants";
import { H5 } from "../../../common/ui/heading/Heading";
import { usePlayerStore } from "../usePlayerStore";

const PlayerAutoReconnectOverlay = () => {
  const connectionStatus = usePlayerStore((state) => state.clientConnectionStatus);
  const onLongPress = useLongPress(
    () => {
      window.location.reload();
    },
    {
      delay: 1000,
      isPreventDefault: true,
    },
  );

  const showReconnectingOverlay =
    connectionStatus &&
    [CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE, CLIENT_CONNECTION_STATUS.CONNECTING].includes(connectionStatus);

  return showReconnectingOverlay ? (
    <div className="absolute inset-0">
      <div className="z-autoReconnectOverlay absolute inset-0 animate-pulse  bg-red-500 bg-opacity-90"></div>
      <div className="z-autoReconnectOverlay absolute inset-0 flex items-center justify-center">
        <div className="rounded-lg bg-violet-700 p-6 text-center text-white">
          <H5 className="pb-2 uppercase">Disconnected</H5>
          <div className="pb-2 text-center text-lg ">Hold on, reconnecting...</div>
          <button className="mt-4 rounded-lg bg-rose-500 px-4 py-2 uppercase text-white" {...onLongPress}>
            Long press to give up
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default PlayerAutoReconnectOverlay;
