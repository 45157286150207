import { useEffect, useState } from "react";
import { PlayerPlaylistStats } from "../../../common/client/host/PartyStats";
import Player from "../../../common/client/Player";
import useRollingNumber from "../../../common/hook/useRollingNumber";
import { toDecimalPlaces } from "../../../common/utils/number";

const ROW_HEIGHT = 48;
const SORT_ANIMATION_DURATION_MS = 1000;
const WIDTH_ANIMATION_DURATION_MS = 3000;

const ProgressArrow = ({ position, isLastRoundOfGame }: { position: number; isLastRoundOfGame: boolean }) => {
  return (
    <div
      className={`absolute bottom-0 right-0 z-[9] h-full w-0`}
      style={{
        left: `${position * 100}%`,
        transition: `left 0.5s ease`,
      }}
    >
      <div
        className={`absolute h-0 w-0 -translate-x-1/2 -translate-y-full border-l-[20px] border-r-[20px] border-t-[30px] border-white border-l-transparent border-r-transparent pb-1 ${isLastRoundOfGame ? "ml-[4px]" : "ml-[1px]"}`}
      />
    </div>
  );
};

const TimelineStartLine = () => {
  return <div className="absolute bottom-0 left-0 right-0 z-[9] h-full w-0 border-l-8 border-white" />;
};

const TimelineEndLine = () => {
  return (
    <div className="absolute bottom-0 left-full right-0 z-[11] h-full w-0 border-r-8 border-dashed border-white opacity-100" />
  );
};

const TimelineRoundLine = ({ position, isLastRoundOfGame }: { position: number; isLastRoundOfGame: boolean }) => {
  return (
    <div
      className={`absolute bottom-0 right-0 z-[9] h-full w-0 border-indigo-900 ${isLastRoundOfGame ? "border-l-8" : "border-l-[1px]"}`}
      style={{
        left: `${position * 100}%`,
      }}
    />
  );
};

const PlayerStats = ({ stats }: { stats: PlayerPlaylistStats }) => {
  const performance = useRollingNumber(stats.performance, { duration: WIDTH_ANIMATION_DURATION_MS });

  return (
    <div className="flex items-center justify-center gap-2">
      <div className="flex w-24 items-center gap-2 text-xl text-neutral-50">
        <img className="block h-[22px]" src="/wins.svg" alt="Game wins/played" />
        <span>
          {stats.numberOfGames === 0 ? (
            "0"
          ) : (
            <>
              {stats.firstPlaces}/{stats.numberOfGames}
            </>
          )}
        </span>
      </div>
      <div className="flex items-center gap-2 text-xl text-neutral-50">
        <img className="mt-[4px] block h-[18px]" src="/efficiency.svg" alt="Efficiency" />
        {stats.numberOfGames === 0 ? "0%" : `${toDecimalPlaces(performance * 100, 0)}%`}
      </div>
    </div>
  );
};

const PlayerRow = ({
  index,
  player,
  stats,
  maxPerformance,
}: {
  index: number;
  player: Player;
  stats: PlayerPlaylistStats;
  maxPerformance: number;
}) => {
  const relativePerformance = maxPerformance > 0 ? stats.performance / maxPerformance : 0;

  return (
    <div
      className={`absolute top-0 flex justify-end`}
      style={{
        transition: `top ${SORT_ANIMATION_DURATION_MS / 1000}s ease 0s, width ${WIDTH_ANIMATION_DURATION_MS / 1000}s`,
        width: `${relativePerformance * 100}%`,
        height: `${ROW_HEIGHT}px`,
        top: `${index * 48}px`,
        zIndex: 8 - index,
      }}
    >
      <div
        className={`flex w-auto items-center justify-end gap-4 bg-black bg-player-${player.getColor()}-dark pl-4 pr-6`}
      >
        <div className={`bg-player-${player.getColor()}-muted h-12 w-dvw`}>
          {/** extends bg to the left so that it can be revealed based on progress **/}
        </div>
        <div className="w-[126px] text-2xl font-semibold text-neutral-50">{player.getName()}</div>
        <PlayerStats stats={stats} />
      </div>
    </div>
  );
};

const RowBackground = ({ color }: { color: string }) => {
  return (
    <div
      className={`flex w-full bg-player-${color}-muted opacity-30`}
      style={{
        height: `${ROW_HEIGHT}px`,
        transition: "background-color 1s",
      }}
    />
  );
};

const sortPlayersByPerformance = (players: Player[], statsByPlayerId: Map<number, PlayerPlaylistStats>) => {
  const playersCopy = [...players];
  playersCopy.sort((a, b) => {
    const aStats = statsByPlayerId.get(a.getPlayerId());
    const bStats = statsByPlayerId.get(b.getPlayerId());

    if (!aStats || !bStats) {
      return 0;
    }

    return bStats.performance - aStats.performance;
  });

  return playersCopy;
};

type Props = {
  statsByPlayerId: Map<number, PlayerPlaylistStats>;
  players: Player[];
  totalRounds: number;
  roundsPlayed: number;
  roundsPerGame: number;
};

const PlaylistTimeline = ({ players, statsByPlayerId, totalRounds, roundsPlayed, roundsPerGame }: Props) => {
  const maxPerformance = Math.max(...Array.from(statsByPlayerId.values()).map((stats) => stats.performance));
  const playlistProgress = roundsPlayed / totalRounds;
  const isLastRoundOfGame = roundsPlayed % roundsPerGame == 0;

  const [playersSortedByPerformance, setPlayersSortedByPerformance] = useState(
    sortPlayersByPerformance(players, statsByPlayerId),
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPlayersSortedByPerformance(sortPlayersByPerformance(players, statsByPlayerId));
    }, WIDTH_ANIMATION_DURATION_MS + 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [players, statsByPlayerId]);

  return (
    <div className="pointer-events-none absolute inset-0 z-[10] flex overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 z-[8] flex bg-indigo-900 bg-opacity-100">
        <div className="flex w-full flex-col">
          {players.map((_, index) => {
            const player = playersSortedByPerformance[index];
            return player ? <RowBackground key={index} color={player.getColor()} /> : null;
          })}
        </div>
      </div>
      <div className="flex w-full">
        <div className="relative w-[360px]"></div>
        <div className="relative mr-10" style={{ width: `calc(100% - 360px)` }}>
          <div className="absolute bottom-0 left-0 right-0" style={{ height: players.length * ROW_HEIGHT }}>
            <ProgressArrow position={playlistProgress} isLastRoundOfGame={isLastRoundOfGame} />
            <TimelineStartLine />
            {new Array(totalRounds).fill(0).map((_, index) => {
              return (
                <TimelineRoundLine
                  key={index}
                  position={(index + 1) / totalRounds}
                  isLastRoundOfGame={(index + 1) % roundsPerGame == 0}
                />
              );
            })}
            <TimelineEndLine />
          </div>
          <div className="absolute bottom-0 left-0 right-0 z-[10]">
            <div
              className="relative flex flex-col"
              style={{
                width: `${playlistProgress * 100}%`,
                height: players.length * ROW_HEIGHT,
                transition: `width ${WIDTH_ANIMATION_DURATION_MS / 1000}s`,
              }}
            >
              {players.map((player) => {
                return (
                  <PlayerRow
                    key={player.getPlayerId()}
                    index={playersSortedByPerformance.findIndex((p) => p.getPlayerId() === player.getPlayerId())}
                    player={player}
                    stats={statsByPlayerId.get(player.getPlayerId())!}
                    maxPerformance={maxPerformance}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistTimeline;
