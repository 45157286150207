import EventEmitter from "@shared/src/gc/events/EventEmitter";
import { FrameEventMessage } from "@shared/src/gc/events/FrameEventEmitter";
import { PlayerClientEventMessage } from "../player/PlayerClient";
import { HostClientEventMessage } from "../host/HostClient";
import { BaseClientType } from "../BaseClient";

export type ClientPluginEvents = Record<string, never>;

class ClientPlugin<T extends ClientPluginEvents = ClientPluginEvents> extends EventEmitter<T> {
  private name: string;
  protected client: BaseClientType | null = null;

  constructor(name: string) {
    super();
    this.name = name;
  }

  setup(client: BaseClientType): void {
    this.client = client;
  }

  protected getClient() {
    if (!this.client) {
      throw new Error("Client not initialized");
    }
    return this.client;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleHostClientEvent = (_event: HostClientEventMessage) => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlePlayerClientEvent = (_event: PlayerClientEventMessage) => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleFrameEvent = (_event: FrameEventMessage) => {};

  getName(): string {
    return this.name;
  }

  destroy(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(_deltaTime: number, _time: number): void {}
}

export default ClientPlugin;
