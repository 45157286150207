import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { SharedPartyState } from "@shared/src/gc/types";
import { assert } from "../logger/assert";
import { BaseClientType } from "./BaseClient";
import Player from "./Player";

export interface PartyEvents extends DefaultListener {
  change: (partyState: SharedPartyState) => void;
}

class Party<T extends PartyEvents> extends EventEmitter<T> {
  protected partyLeaderPlayerId: number | null = null;
  protected playerIds: number[] = [];
  protected client: BaseClientType;

  constructor(client: BaseClientType) {
    super();
    this.client = client;
  }

  protected dispatchChange = (): void => {
    this.dispatch("change", this.getSharedState());
  };

  getPlayers(): Player[] {
    return this.playerIds.map((playerId) => {
      const player = this.client.playersManager.getPlayerByPlayerId(playerId);
      assert(player, "expected player");
      return player;
    });
  }

  getPlayerByPlayerId(playerId: number): Player | null {
    const partyPlayerId = this.playerIds.find((p) => p === playerId);
    return partyPlayerId ? this.client.playersManager.getPlayerByPlayerId(partyPlayerId) : null;
  }

  getSharedState(): SharedPartyState {
    return {
      partyLeaderPlayerId: this.partyLeaderPlayerId,
      playerIds: this.playerIds,
    };
  }

  getPartyLeader(): Player | null {
    if (!this.partyLeaderPlayerId) {
      return null;
    }
    return this.getPlayerByPlayerId(this.partyLeaderPlayerId);
  }

  isPartyLeader(player: Player): boolean {
    return this.partyLeaderPlayerId === player.getPlayerId();
  }

  getPlayerByName(name: string): Player | null {
    return this.getPlayers().find((p) => p.getName() === name) || null;
  }
}

export default Party;
