import { useLongPress } from "react-use";
import { usePlayerStore } from "../usePlayerStore";
import ConsoleFeedLogOverlay from "./ConsoleFeedLogOverlay";

const ConsoleFeedContainer = () => {
  const actions = usePlayerStore((state) => state.actions);
  const isConsoleLogOpen = usePlayerStore((state) => state.isConsoleLogOpen);
  const longPressEvent = useLongPress(
    () => {
      actions.setConsoleLogOpen(true);
    },
    {
      isPreventDefault: true,
      delay: 1000,
    },
  );

  return (
    <>
      {isConsoleLogOpen ? (
        <ConsoleFeedLogOverlay />
      ) : (
        <button {...longPressEvent} className="z-devConsole absolute left-0 top-0 h-14 w-14"></button>
      )}
    </>
  );
};

export default ConsoleFeedContainer;
