import { useEffect } from "react";
import GameEntry from "../../../common/client/GameEntry";
import { assert } from "../../../common/logger/assert";
import { H1, H2 } from "../../../common/ui/heading/Heading";
import { useHostActions } from "../useHostActions";
import { useHostStore } from "../useHostStore";

const NextGamePreview = () => {
  const actions = useHostActions();
  const hostState = useHostStore((state) => state.hostState);
  const playlist = hostState.playlist;
  assert(playlist, "expected playlist");
  assert(playlist.nextPlaylistGameEntry, "expected nextPlaylistGameEntry");

  const gameEntry = GameEntry.getGameEntryById(playlist.nextPlaylistGameEntry.gameEntryId);
  assert(gameEntry, "expected gameEntry");

  useEffect(
    function nextGameStartingTip() {
      const timeout = setTimeout(() => {
        actions.setTip({
          text: gameEntry.getDescriptionShort(),
          timeMs: null,
        });
      }, 750); // small delay to avoid overlapping with cover image

      return () => {
        clearTimeout(timeout);
      };
    },
    [actions, gameEntry],
  );

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center justify-center pb-32">
        <H2 className="pb-4">Next Game</H2>
        <H1 className="pb-10">
          {gameEntry.getGameName()} - {gameEntry.getGameModeName()}
        </H1>
        <div className="w-[680px] border-4 border-indigo-950">
          <img src={gameEntry.getCoverImage()} alt={gameEntry.getGameName()} />
        </div>
      </div>
    </div>
  );
};

export default NextGamePreview;
