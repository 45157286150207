import { useEffect, useState } from "react";
import HostClient from "../../../common/client/host/HostClient";
import { PLAYLIST_STATES } from "../../../common/client/plugins/playlist/PlaylistHostPlugin";
import useCountdown from "../../../common/hook/useCountdown";
import { assert } from "../../../common/logger/assert";
import { useHostActions } from "../useHostActions";
import { useHostStore } from "../useHostStore";
import GameSetupStatus from "./GameSetupStatus";
import { playSound, SOUNDS } from "../../sounds/ui/sounds";

const PRE_PLAY_COUNTER_DEFAULT = 5000;
const PRE_PLAY_COUNTER_MIN_WAIT_TIME = 3000;

const PrePlayOverlay = ({ setupDone }: { setupDone: boolean }) => {
  const actions = useHostActions();
  const [enterTime] = useState(Date.now());

  const hostState = useHostStore((state) => state.hostState);
  const playlistGameEntry = hostState.playlist?.playlistGameEntry;
  assert(playlistGameEntry, "expected playlistEntry");

  const isPaused = Boolean(hostState.playlist?.isPaused);

  const enableCountDown = setupDone && !isPaused;
  const countdownSeconds = useCountdown(
    Math.ceil(Math.max(PRE_PLAY_COUNTER_MIN_WAIT_TIME, PRE_PLAY_COUNTER_DEFAULT - (Date.now() - enterTime)) / 1000),
    () => {
      const playStateInterface = HostClient.getPlaylistStateInterface(PLAYLIST_STATES.PRE_PLAY);
      playStateInterface.actions.play();
    },
    enableCountDown,
  );

  useEffect(() => {
    if (!enableCountDown) return;
    playSound(SOUNDS.COUNTDOWN_TICK, { throttleMs: 50 });
  }, [enableCountDown, countdownSeconds]);

  const waitingGameSetupPlayerIds = useHostStore((state) => state.waitingGameSetupPlayerIds);
  const waitingForHost = waitingGameSetupPlayerIds.includes(0);

  const gameEntry = useHostStore((state) => state.gameEntry);
  assert(gameEntry, "expected gameEntry");

  useEffect(() => {
    if (isPaused) {
      actions.setTip({
        text: "PAUSED",
        timeMs: null,
        highlight: true,
      });
      return;
    }
    if (setupDone) {
      if (countdownSeconds > 0) {
        actions.setTip({
          text: `Game starts in ${countdownSeconds}`,
          timeMs: 1100,
        });
        return;
      }

      actions.setTip({
        text: "Banzaiiii!",
      });
      return;
    }

    return () => {
      actions.clearTip();
    };
  }, [actions, setupDone, countdownSeconds, isPaused]);

  return (
    <div
      className={`opacity-1 absolute inset-0 flex flex-col items-center space-y-32 pt-12 transition-all duration-1500 ${waitingForHost ? "bg-indigo-900" : "bg-indigo-900 bg-opacity-50"} ${setupDone && countdownSeconds < 2 ? "opacity-0" : ""}`}
    >
      <div className="opacity-1 absolute inset-0 z-prePlayOverlay animate-[fadeOut_1s_ease-out_forwards] bg-black transition-all" />
      <div className="absolute inset-0 top-[1%] flex flex-col items-center shadow-black text-shadow">
        <span className="text-8xl font-bold uppercase shadow-black text-shadow">{gameEntry.getGameName()}</span>
        <span className="text-4xl font-bold uppercase shadow-black text-shadow">{gameEntry.getGameModeName()}</span>
        {playlistGameEntry.isWarmup ? (
          <span className="pt-4 text-8xl font-bold uppercase shadow-black text-shadow">Warmup round</span>
        ) : (
          <span className="animate-pulse pt-6 text-4xl font-bold shadow-black text-shadow">
            Round {playlistGameEntry.roundNumber}/{playlistGameEntry.totalRounds}
          </span>
        )}
      </div>
      <div className="absolute inset-0 top-[30%]">
        <GameSetupStatus isPaused={isPaused} />
      </div>
    </div>
  );
};

export default PrePlayOverlay;
