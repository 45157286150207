import { useEffect, useMemo, useState } from "react";
import HostClient from "../../../common/client/host/HostClient";
import { assert } from "../../../common/logger/assert";
import { useHostStore } from "../useHostStore";
import PlaylistTimeline from "./PlaylistTimeline";

const PostPlayTimeline = () => {
  const hostState = useHostStore((state) => state.hostState);
  assert(hostState.playlist, "expected playlist");
  const playlistId = hostState.playlist.id;
  const latestStatsByPlayerId = useMemo(
    () => HostClient.party.partyStats.getPlaylistStatsByPlayerIdMap(playlistId),
    [playlistId],
  );
  const prevStatsByPlayerId = useMemo(
    () => HostClient.party.partyStats.getPlaylistStatsByPlayerIdMap(playlistId, -1),
    [playlistId],
  );

  const [statsByPlayerId, setStatsByPlayerId] = useState(prevStatsByPlayerId);
  const [currentRound, setCurrentRound] = useState(hostState.playlist.nonWarmupRoundsPlayed - 1);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStatsByPlayerId(latestStatsByPlayerId);
      setCurrentRound((prev) => prev + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [latestStatsByPlayerId]);

  const players = useMemo(() => {
    return Array.from(statsByPlayerId.keys()).map((playerId) => {
      const player = HostClient.playersManager.getPlayerByPlayerId(playerId);
      assert(player, "expected player");
      return player;
    });
  }, [statsByPlayerId]);

  if (!latestStatsByPlayerId || latestStatsByPlayerId.size === 0) {
    return null;
  }

  return (
    <PlaylistTimeline
      players={players}
      statsByPlayerId={statsByPlayerId}
      totalRounds={hostState.playlist.totalRounds}
      roundsPerGame={hostState.playlist.roundsPerGame}
      roundsPlayed={currentRound}
    />
  );
};

export default PostPlayTimeline;
