import { PLAYLIST_STATES } from "../../common/client/plugins/playlist/PlaylistHostPlugin";
import HeaderOverlay from "./HeaderOverlay";
import useDevAutoStartPlaylist from "./dev/useDevAutoStartPlaylist";
import HostFrame from "./frame/HostFrame";
import GameHud from "./gameHud/GameHud";
import Lobby from "./lobby/Lobby";
import PostPlayOverlay from "./playlist/PostPlayOverlay";
import PrePlayOverlay from "./playlist/PrePlayOverlay";
import { useHostStore } from "./useHostStore";

const HostView = () => {
  const hostState = useHostStore((state) => state.hostState);
  useDevAutoStartPlaylist();

  const renderView = () => {
    if (hostState.playlist) {
      const playlistState = hostState.playlist.state;
      return (
        <div className="absolute inset-0 overflow-hidden">
          {[PLAYLIST_STATES.SETUP_GAME, PLAYLIST_STATES.PRE_PLAY].includes(playlistState) && (
            <PrePlayOverlay setupDone={playlistState !== PLAYLIST_STATES.SETUP_GAME} />
          )}
          {[PLAYLIST_STATES.PLAY].includes(playlistState) && <GameHud />}
          {[
            PLAYLIST_STATES.POST_PLAY,
            PLAYLIST_STATES.GAME_END,
            PLAYLIST_STATES.NEXT_GAME_PREVIEW,
            PLAYLIST_STATES.PLAYLIST_END,
          ].includes(playlistState) && <PostPlayOverlay playlistState={playlistState} />}
          {[
            PLAYLIST_STATES.SETUP_GAME,
            PLAYLIST_STATES.PRE_PLAY,
            PLAYLIST_STATES.PLAY,
            PLAYLIST_STATES.POST_PLAY,
          ].includes(playlistState) && <HostFrame />}
          <HeaderOverlay showPartyId showMuteButton showFullScreenButton />
        </div>
      );
    }

    return <Lobby />;
  };

  return renderView();
};

export default HostView;
