import { useEffect } from "react";
import HostClient from "../../../common/client/host/HostClient";
import { PLAYLIST_STATES, PlaylistState } from "../../../common/client/plugins/playlist/PlaylistHostPlugin";
import { IS_DEV } from "../../constants";
import { useHostStore } from "../useHostStore";
import RoundPlacements from "./RoundPlacements";

import backdropPattern from "../../../assets/backdrop-pattern.png";
import useCountdown from "../../../common/hook/useCountdown";
import { assert } from "../../../common/logger/assert";
import { useHostActions } from "../useHostActions";
import NextGamePreview from "./NextGamePreview";
import PostPlayTimeline from "./PostPlayTimeline";

const POST_PLAY_COUNTDOWN_S = 2;
const WARMUP_COUNTDOWN_TIME_S = IS_DEV ? 3 : 3;
const NEXT_GAME_COUNTDOWN_TIME_S = IS_DEV ? 6 : 6;
const NEXT_GAME_PREVIEW_COUNTDOWN_TIME_S = IS_DEV ? 6 : 6;
const EXIT_PLAYLIST_COUNTDOWN_TIME_S = IS_DEV ? 3 : 20;

const Backdrop = ({ offset }: { offset: number }) => {
  return (
    <div className={`absolute inset-0 animate-[fadeIn_1.5s_ease-out_forwards] overflow-hidden opacity-0`}>
      <div
        className="duration-1500 absolute inset-0 h-[2160px] overflow-hidden bg-indigo-900 transition-all ease-in-out"
        style={{ transform: `translateY(-${offset}px)` }} // could scroll the bg position, but this is easier to animate
      >
        <div
          className="absolute inset-0 bg-[length:300px_300px] bg-repeat opacity-15"
          style={{ backgroundImage: `url(${backdropPattern})` }}
        />
      </div>
    </div>
  );
};

type Props = {
  playlistState: PlaylistState;
};

const PostPlayOverlay = ({ playlistState }: Props) => {
  const actions = useHostActions();
  const gameEntry = useHostStore((state) => state.gameEntry);
  const isWarmup = !!gameEntry?.getIsWarmup();
  const isPlaylistEnd = playlistState === PLAYLIST_STATES.PLAYLIST_END;
  const latestGameResults = HostClient.party.partyStats.getLatestGameResults();

  const hostState = useHostStore((state) => state.hostState);
  const playlistGameEntry = hostState.playlist?.playlistGameEntry;
  assert(playlistGameEntry, "expected playlistEntry");

  const isPaused = hostState.playlist?.isPaused;
  const isLastRoundOfGame = playlistGameEntry.roundNumber === playlistGameEntry.totalRounds;
  const fadeOut =
    (!isLastRoundOfGame && playlistState === PLAYLIST_STATES.GAME_END) ||
    (isLastRoundOfGame && playlistState === PLAYLIST_STATES.NEXT_GAME_PREVIEW) ||
    isPlaylistEnd;

  let countdownTime;
  let backdropOffset = 0;
  let scrollY = 0;

  if (isWarmup) {
    countdownTime = WARMUP_COUNTDOWN_TIME_S;
  } else {
    switch (playlistState) {
      case PLAYLIST_STATES.POST_PLAY:
        countdownTime = POST_PLAY_COUNTDOWN_S;
        break;
      case PLAYLIST_STATES.GAME_END:
        countdownTime = NEXT_GAME_COUNTDOWN_TIME_S;
        backdropOffset = 75;
        scrollY = 150;
        break;
      case PLAYLIST_STATES.NEXT_GAME_PREVIEW:
        countdownTime = NEXT_GAME_PREVIEW_COUNTDOWN_TIME_S;
        backdropOffset = 200;
        scrollY = 750;
        break;
      case PLAYLIST_STATES.PLAYLIST_END:
        countdownTime = EXIT_PLAYLIST_COUNTDOWN_TIME_S;
        backdropOffset = 75;
        scrollY = 150;
        break;
      default:
        throw new Error(`Unhandled playlistState: ${playlistState}`);
    }
  }

  const countdownSeconds = useCountdown(
    countdownTime,
    () => {
      switch (playlistState) {
        case PLAYLIST_STATES.POST_PLAY:
          HostClient.getPlaylistStateInterface(PLAYLIST_STATES.POST_PLAY).actions.next();
          break;
        case PLAYLIST_STATES.GAME_END:
          HostClient.getPlaylistStateInterface(PLAYLIST_STATES.GAME_END).actions.next();
          break;
        case PLAYLIST_STATES.NEXT_GAME_PREVIEW:
          HostClient.getPlaylistStateInterface(PLAYLIST_STATES.NEXT_GAME_PREVIEW).actions.next();
          break;
        case PLAYLIST_STATES.PLAYLIST_END:
          HostClient.getPlaylistStateInterface(PLAYLIST_STATES.PLAYLIST_END).actions.next();
          break;
        default:
          throw new Error(`Unhandled playlistState: ${playlistState}`);
      }
    },
    !isPaused,
    playlistState,
  );

  useEffect(
    function handlePause() {
      if (isPaused) {
        actions.setTip({
          text: `PAUSED`,
          timeMs: null,
          highlight: true,
        });

        return () => {
          actions.clearTip();
        };
      }
    },
    [actions, isPaused],
  );

  useEffect(
    function playlistEndTip() {
      if (isPlaylistEnd && countdownSeconds > 0) {
        actions.setTip({
          text: `Playlist end. Returning to lobby in ${countdownSeconds}`,
          timeMs: 1100,
        });
      }
    },
    [actions, isPlaylistEnd, countdownSeconds],
  );

  return (
    <div className="absolute inset-0 bg-indigo-900 bg-opacity-50 transition-all duration-1000">
      {fadeOut && (
        <div
          className={`duration-1500 absolute inset-0 z-postPlayOverlay bg-black opacity-0 transition-all ${countdownSeconds > 0 && countdownSeconds < 2 ? "opacity-100" : ""}`}
        />
      )}
      <Backdrop offset={backdropOffset} />
      <div
        className="absolute inset-0 flex flex-col items-center transition-all duration-1000"
        style={{
          top: `-${scrollY}px`,
        }}
      >
        {isWarmup ? (
          <div className="absolute top-[470px] flex animate-[fadeIn_.35s_ease-out_forwards] items-center opacity-0 transition-all">
            <span className="text-9xl font-bold uppercase">Warmup over</span>
          </div>
        ) : (
          <>
            <span className="absolute top-[200px] animate-pulse pt-6 text-4xl font-bold shadow-black text-shadow">
              End of round {playlistGameEntry.roundNumber}/{playlistGameEntry.totalRounds}
            </span>
            <div className="absolute top-[300px] flex animate-[fadeIn_.35s_ease-out_forwards] items-center opacity-0 transition-all">
              <RoundPlacements />
            </div>
            {[PLAYLIST_STATES.GAME_END, PLAYLIST_STATES.NEXT_GAME_PREVIEW, PLAYLIST_STATES.PLAYLIST_END].includes(
              playlistState,
            ) && (
              <>
                <div className="absolute inset-0 bottom-24 flex animate-[fadeIn_1s_ease-out_forwards] flex-col items-center opacity-0 transition-all">
                  {latestGameResults && latestGameResults.playerIdsByPlacement.length > 0 && <PostPlayTimeline />}
                </div>
                {[PLAYLIST_STATES.NEXT_GAME_PREVIEW].includes(playlistState) && (
                  <div className="absolute inset-0 bottom-24 top-[820px] flex animate-[fadeIn_1s_ease-out_forwards] flex-col items-center opacity-0 transition-all">
                    <NextGamePreview />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PostPlayOverlay;
