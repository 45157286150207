import React from "react";
import screenfull from "screenfull";

export const FullScreenContext = React.createContext<
  | {
      isAvailable: boolean;
      request: () => Promise<void>;
      exit: () => Promise<void>;
      toggle: () => Promise<void>;
      isFullscreen: boolean;
    }
  | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

const FullScreenProvider = ({ children }: Props) => {
  return (
    <FullScreenContext.Provider
      value={{
        isAvailable: screenfull.isEnabled,
        request: () => screenfull.request(document.getElementsByTagName("html")[0]),
        exit: () => screenfull.exit(),
        toggle: () => screenfull.toggle(),
        isFullscreen: screenfull.isFullscreen,
      }}
    >
      {children}
    </FullScreenContext.Provider>
  );
};

export default FullScreenProvider;
