import dsbLogo from "../../../assets/dsb-logo.jpeg";

type Props = {
  onClick: () => void;
};

const PlayerMenuButton = ({ onClick }: Props) => {
  return (
    <button
      style={{
        position: "absolute",
        zIndex: 99,
        top: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        display: "flex",
        width: "50px",
        height: "50px",
        backgroundColor: "#222222",
        border: "0 solid #222222",
        borderTop: 0,
        borderRadius: "0 0 10px 10px",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${dsbLogo})`,
        backgroundSize: "cover",
        boxShadow: "0 0 20px 0 black",
      }}
      onClick={onClick}
    ></button>
  );
};

export default PlayerMenuButton;
