import { HUD_SCREEN_POINT_TYPE } from "@shared/src/gc/hud/constants";
import HostClient from "../../../common/client/host/HostClient";
import { useHostStore } from "../useHostStore";

const NameBanner = ({ name, x, y }: { name: string; x: number; y: number }) => {
  return (
    <div
      className="absolute h-full w-full text-lg font-bold text-white shadow-black text-shadow"
      style={{
        transform: `translate(${x * 100}%, ${(1 - y) * 100}%)`,
      }}
    >
      <div className="absolute -translate-x-1/2 -translate-y-1/2">{name}</div>
    </div>
  );
};

const Points = () => {
  const hudScreenPointData = useHostStore((state) => state.hudScreenPointData);

  if (!hudScreenPointData) {
    return null;
  }

  return hudScreenPointData.points.map((data) => {
    switch (data.type) {
      case HUD_SCREEN_POINT_TYPE.NAME: {
        const player = HostClient.playersManager.getPlayerByPlayerId(data.playerId);
        if (!player) {
          return null;
        }

        return <NameBanner key={player.getPlayerId()} name={player.getName()} x={data.x} y={data.y} />;
      }
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unhandled hud point type: ${data.type}`);
    }
  });
};

const ScreenPointHud = () => {
  return (
    <div className="pointer-events-none absolute inset-0 z-[100] overflow-hidden">
      <Points />
    </div>
  );
};

export default ScreenPointHud;
