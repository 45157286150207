import PartyLeaderController from "../../partyLeaderController/PartyLeaderController";
import PlayerAvatar from "../PlayerAvatar";

import classes from "./PartyLeaderView.module.css";
import { usePlayerStore } from "../../usePlayerStore";
import PlayerStatusText from "../PlayerStatusText";
import { HOST_STATUS } from "../../../../common/client/host/HostClient";

const PartyLeaderView = () => {
  const hostState = usePlayerStore((state) => state.hostState);
  const player = usePlayerStore((state) => state.player);
  if (!player) {
    throw new Error("player expected");
  }
  return (
    <div className={classes.container}>
      <PlayerStatusText>YOU ARE THE PARTY LEADER</PlayerStatusText>
      <div style={{ flex: 1, paddingBottom: "26px" }}>
        <PlayerAvatar player={player} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {hostState?.status === HOST_STATUS.LOBBY && <PartyLeaderController />}
      </div>
    </div>
  );
};

export default PartyLeaderView;
