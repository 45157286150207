import { Route, Routes } from "react-router-dom";

import GameItems from "./GameItems";
import GameStart from "./GameStart";

const GameBrowser = () => {
  return (
    <Routes>
      <Route path="/" element={<GameItems />} />
      <Route path="/:gameEntryId" element={<GameStart />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
};

export default GameBrowser;
