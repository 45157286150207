import ClientEventEmitter, {
  DefaultListener,
} from "@shared/src/gc/events/ClientEventEmitter";

export type GameEvents = DefaultListener;

export type GameEventName = string;

class GameEventEmitter extends ClientEventEmitter<GameEvents> {}

export type GameEventData<T extends GameEventName = GameEventName> = Parameters<
  GameEvents[T]
>[1];

export type GameEventMessage = {
  type: "GAME_EVENT";
  eventName: string;
  playerId: number;
  reliable: boolean;
  data?: any;
};

export default GameEventEmitter;
