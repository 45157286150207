export function assertType<T>(condition: T | null | undefined | false | "", message: string): asserts condition is T {
  if (!condition) {
    throw new Error(message);
  }
}

export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
