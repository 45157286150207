import Player, { PlayerData } from "../Player";
import PlayersManager, { PlayersManagerEvents } from "../PlayersManager";

export type PlayerPlayersManagerEvents = PlayersManagerEvents;

class PlayerPlayersManager extends PlayersManager<PlayerPlayersManagerEvents> {
  private selfPlayerId: number | null = null;

  private setPlayersFromData(playersData: PlayerData[]): void {
    this.players = playersData.map((data) => {
      return Player.fromData(data);
    });
  }

  setSelfPlayerId(selfPlayerId: number): void {
    this.selfPlayerId = selfPlayerId;
    if (!this.getPlayerByPlayerId(this.selfPlayerId)) {
      throw new Error("Can't find self player in party");
    }
  }

  setFromSharedClientState(state: { playersData: PlayerData[] }): void {
    this.setPlayersFromData(state.playersData);
    this.dispatchChange();
  }

  reset(): void {
    this.players = [];
    this.selfPlayerId = null;
  }

  getSelf(): Player | null {
    if (!this.selfPlayerId) {
      return null;
    }

    return this.getPlayerByPlayerId(this.selfPlayerId);
  }
}

export default PlayerPlayersManager;
