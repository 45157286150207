import { Route, Routes, useNavigate } from "react-router-dom";
import HostClient from "../../../common/client/host/HostClient";
import { H4 } from "../../../common/ui/heading/Heading";
import HeaderOverlay from "../HeaderOverlay";
import GameBrowser from "./games/GameBrowser";
import LobbyHeader from "./header/LobbyHeader";
import PlaylistStart from "./playlistStart/PlaylistStart";
import PlaylistBrowser from "./startMenu/PlaylistBrowser";
import StartMenu from "./startMenu/StartMenu";

const LobbyMainRoute = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex-1 overflow-hidden">
      <StartMenu />
      <PlaylistBrowser
        onPlaylistBlueprintSelect={(playlistBlueprint) => {
          navigate(`/host/playlist-start/${playlistBlueprint.id}`);
        }}
      />
    </div>
  );
};

const Lobby = () => {
  const partyId = HostClient.getPartyId(true);

  return (
    <div className="flex h-full w-full flex-col bg-indigo-900">
      <HeaderOverlay showMuteButton showFullScreenButton />
      <LobbyHeader />
      <div className="flex flex-1 flex-col">
        <Routes>
          <Route path="/" element={<LobbyMainRoute />} />
          <Route path="/playlist-start/:playlistBlueprintId" element={<PlaylistStart />} />
          <Route path="/games/*" element={<GameBrowser />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </div>
      <div className="h-[86px] bg-indigo-950">
        {/* Space for possible button tips. Also serves as a space for the tip text overlay to appear. */}
        <H4 className="flex h-full items-center justify-center">
          Join with your phone! Go to gamingcouch.com and enter the Party ID: {partyId}
        </H4>
      </div>
    </div>
  );
};

export default Lobby;
