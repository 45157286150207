import { createContext, useRef } from "react";
import createHostStore, { HostStore } from "./createHostStore";

export const HostContext = createContext<HostStore | null>(null);

type HostProviderProps = React.PropsWithChildren;

function HostProvider({ children }: HostProviderProps) {
  const storeRef = useRef<HostStore>();
  if (!storeRef.current) {
    storeRef.current = createHostStore();
  }
  return <HostContext.Provider value={storeRef.current}>{children}</HostContext.Provider>;
}

export default HostProvider;
