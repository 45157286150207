import {
  HudConfig,
  HudScreenPointData,
  PlayersHudData,
} from "@shared/src/gc/hud/types";
import {
  PlayerGameData,
  PlaylistSharedGameState,
  ScreenOrientationMode,
  SharedPartyState,
} from "../types";
import EventEmitter from "./EventEmitter";

export type FrameEvents = {
  frame_init: (data: {
    gameId: string;
    gameModeId: string;
    partyState: SharedPartyState;
    playerData?: PlayerGameData;
  }) => void;
  party_change: (data: SharedPartyState) => void;
  frame_script_ready: () => void;
  setup_game_frame_done: () => void;
  playlist_host_game_end: (data: { playerIdsByPlacement: number[] }) => void;
  party_player_left: (data: { playerId: number }) => void;
  playlist_play: (data: PlaylistSharedGameState) => void;
  pause: (value: boolean) => void;
  hud_setup: (data: HudConfig) => void;
  hud_players_update: (data: PlayersHudData) => void;
  hud_screen_point_update: (data: HudScreenPointData) => void;
  hud_clear: () => void;
  force_screen_orientation: (data: ScreenOrientationMode) => void;
  initialized: () => void;
};

export type FrameEventData<T extends FrameEventName = FrameEventName> =
  Parameters<FrameEvents[T]>[0];

export type FrameEventName = keyof FrameEvents;

export type FrameEventMessage = {
  type: "FRAME_EVENT";
  eventName: FrameEventName;
  data?: unknown;
};

class FrameEventEmitter extends EventEmitter<FrameEvents> {}

export default FrameEventEmitter;
