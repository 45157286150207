import ClientEventEmitter from "@shared/src/gc/events/ClientEventEmitter";
import { NavigationButton } from "../../../../client/src/domain/host/spatialNavigation/types";

export interface PlayerClientEvents {
  request_skip_round: (playerId: number) => void;
  request_toggle_pause: () => void;
  request_pause: (playerId: number, pause: boolean) => void;
  request_exit_to_lobby: (playerId: number) => void;
  request_party_leader_switch: (
    playerId: number,
    data: { playerId: number }
  ) => void;
  accept_party_leader_switch: (
    playerId: number,
    data: { playerId: number }
  ) => void;
  kick_player: (playerId: number, data: { playerId: number }) => void;
  setup_game_frame_done: (playerId: number, data: { playerId: number }) => void;
  party_leader_input: (
    playerId: number,
    data: { button: NavigationButton }
  ) => void;
  ping: (playerId: number, id: number) => void;
}

export type PlayerClientEventName = keyof PlayerClientEvents;

export type PlayerClientEventData<
  T extends PlayerClientEventName = PlayerClientEventName
> = Parameters<PlayerClientEvents[T]>[1];

class PlayerClientEventEmitter extends ClientEventEmitter<PlayerClientEvents> {}

export default PlayerClientEventEmitter;
