import { useEffect, useRef, useState } from "react";

const useRollingNumber = (value: number, { duration }: { duration: number }) => {
  const startValue = useRef(value);
  const [counter, setCounter] = useState(value);

  useEffect(() => {
    const updateRate = 1000 / 60;
    let time = 0;
    const interval = setInterval(() => {
      time += updateRate;
      const t = Math.min(time / duration, 1);
      setCounter((1 - t) * startValue.current + t * value);

      if (time >= duration) {
        clearInterval(interval);
      }
    }, updateRate);

    return () => {
      clearInterval(interval);
    };
  }, [value, duration]);

  return counter;
};

export default useRollingNumber;
