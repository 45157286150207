import { useEffect } from "react";
import { useWakeLockControls } from "./useWakeLockControls";

const useWakeLock = () => {
  const { isSupported, request, release } = useWakeLockControls();

  useEffect(() => {
    if (isSupported) {
      void request();

      return () => {
        void release();
      };
    }
  }, [isSupported, request, release]);
};

export default useWakeLock;
