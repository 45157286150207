import { useEffect, useState } from "react";
import HostClient from "../../../common/client/host/HostClient";
import clientLocalStorage from "../../storage/clientLocalStorage";

/**
 * Hook for dev purposes only. Used to share party id between host and players in dev mode.
 * The host will write party ID to local storage and this hook will try to read it from there.
 */
const useDevAutofillPartyId = () => {
  const enabled = import.meta.env.DEV;
  const [storedDevPartyId, setStoredDevPartyId] = useState(
    enabled ? clientLocalStorage.getItem("devAutofillPartyId") : "",
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const timeout = setInterval(() => {
      const partyId = (import.meta.env.DEV && clientLocalStorage.getItem("devAutofillPartyId")) || "";
      setStoredDevPartyId(partyId);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [enabled]);

  return storedDevPartyId ? HostClient.formatPartyId(storedDevPartyId) : "";
};

export default useDevAutofillPartyId;
