import GameEntry from "../../GameEntry";
import { PlaylistBlueprintData } from "./types";

class PlaylistBlueprint {
  private data: PlaylistBlueprintData;

  private minPlayers: number;
  private maxPlayers: number;

  constructor(data: PlaylistBlueprintData) {
    this.data = data;

    const { minPlayers, maxPlayers } = data.gameEntryIds.reduce(
      (acc, value) => {
        const gameEntry = GameEntry.getGameEntryDataById(value);
        acc.minPlayers = Math.max(acc.minPlayers, gameEntry?.minPlayers ?? 0);
        acc.maxPlayers = Math.min(acc.maxPlayers, gameEntry?.maxPlayers ?? 0);
        return acc;
      },
      { minPlayers: 0, maxPlayers: Number.POSITIVE_INFINITY },
    );

    this.minPlayers = minPlayers;
    this.maxPlayers = maxPlayers;
  }

  public getId() {
    return this.data.id;
  }

  public getName() {
    return this.data.name;
  }

  public getDescriptionShort() {
    return this.data.descriptionShort;
  }

  public getDescriptionLong() {
    return this.data.descriptionLong;
  }

  public getGameEntryIds() {
    return this.data.gameEntryIds;
  }

  public getNumberOfRounds() {
    return this.data.numberOfRounds;
  }

  public getMinPlayers() {
    return this.minPlayers;
  }

  public getMaxPlayers() {
    return this.maxPlayers;
  }

  public isWarmupEnabled() {
    return this.data.isWarmupEnabled;
  }

  public isRandomizedGameOrder() {
    return this.data.isRandomizedGameOrder;
  }

  public toData() {
    return this.data;
  }
}

export default PlaylistBlueprint;
