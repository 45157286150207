import { useEffect, useRef } from "react";

/**
 * useAutoScroll is a hook that will scroll the container to the bottom if it was already in the bottom when the content was updated.
 * @param containerRef The container to be scrolled.
 * @param contentUpdateKey For example, if this would be chat messages, you would pass the count of the messages array or message id etc.
 */
const useAutoScroll = (containerRef: React.RefObject<HTMLDivElement>, contentUpdateKey: unknown) => {
  const isScrolledToTheBottom = useRef(true);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      if (isScrolledToTheBottom.current) {
        container.scrollTop = container.scrollHeight;
      }

      const handleScroll = () => {
        const scrolledToBottom = Math.abs(container.scrollHeight - container.clientHeight - container.scrollTop) <= 1;
        isScrolledToTheBottom.current = scrolledToBottom;
      };

      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [containerRef, contentUpdateKey]);
};

export default useAutoScroll;
