import "@total-typescript/ts-reset";
import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./domain/sentry/sentry";
import "./index.css";

import FullScreenProvider from "./common/fullScreen/FullScreenProvider";
import { HOST_ROOT_PATH } from "./domain/host/constants";
import HostClient from "./domain/host/HostClient";
import PlayerClient from "./domain/player/PlayerClient";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: `${HOST_ROOT_PATH}/*`,
    element: <HostClient />,
  },
  {
    path: "/player",
    element: <PlayerClient />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FullScreenProvider>
      <RouterProvider router={router} />
    </FullScreenProvider>
  </React.StrictMode>,
);
