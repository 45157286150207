import React from "react";

const Heading = ({
  variant = "h1",
  className,
  children,
  ...rest
}: React.ComponentProps<"h1"> & {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}) => {
  const Component = variant;

  const variantStyle = {
    h1: "text-6xl",
    h2: "text-5xl",
    h3: "text-4xl",
    h4: "text-3xl",
    h5: "text-2xl",
    h6: "text-xl",
  }[variant];

  return (
    <Component className={["text-center font-bold", variantStyle, className].join(" ")} {...rest}>
      {children}
    </Component>
  );
};

export default Heading;

export const H1 = (props: React.ComponentProps<"h1">) => <Heading variant="h1" {...props} />;
export const H2 = (props: React.ComponentProps<"h2">) => <Heading variant="h2" {...props} />;
export const H3 = (props: React.ComponentProps<"h3">) => <Heading variant="h3" {...props} />;
export const H4 = (props: React.ComponentProps<"h4">) => <Heading variant="h4" {...props} />;
export const H5 = (props: React.ComponentProps<"h5">) => <Heading variant="h5" {...props} />;
export const H6 = (props: React.ComponentProps<"h6">) => <Heading variant="h6" {...props} />;
