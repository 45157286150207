import { H5 } from "../../../../common/ui/heading/Heading";
import useFocusableWithAction from "../useFocusableWithAction";

import chevronLeft from "../../../../assets/chevron-left.svg";
import chevronRight from "../../../../assets/chevron-right.svg";
import { playSound, SOUNDS } from "../../../sounds/ui/sounds";

function Spinbox<T extends string | number>({
  label,
  value,
  values,
  onChange,
  onFocus,
}: {
  label: string;
  value: T;
  values: T[];
  onChange: (value: T) => void;
  onFocus?: () => void;
}) {
  const { ref, focused } = useFocusableWithAction({
    onActionEnter: () => {},
    onActionDirection: (direction) => {
      switch (direction) {
        case "right": {
          const currentIndex = values.indexOf(value);
          const nextIndex = (currentIndex + 1) % values.length;
          onChange(values[nextIndex]);
          playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });
          return false;
        }
        case "left": {
          const currentIndex = values.indexOf(value);
          const nextIndex = (currentIndex - 1 + values.length) % values.length;
          onChange(values[nextIndex]);
          playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });
          return false;
        }
      }

      return true;
    },
    onFocus: () => {
      playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });

      onFocus && onFocus();
    },
  });

  return (
    <div
      className={`flex min-h-[134px] flex-col justify-center align-middle ${focused ? "rounded-md border-4 border-fuchsia-500 shadow-2xl" : ""}`}
    >
      <H5 className="pb-1 uppercase">{label}</H5>
      <div className="flex items-center justify-center">
        <img src={chevronLeft} alt="chevron-left" className="h-[70px] w-[30px]" />
        <div ref={ref} className="flex min-w-[120px] justify-center text-5xl font-bold uppercase">
          {value}
        </div>
        <img src={chevronRight} alt="chevron-right" className="h-[70px] w-[30px]" />
      </div>
    </div>
  );
}

export default Spinbox;
