import PlayerAvatar from "../PlayerAvatar";

import { HOST_STATUS } from "../../../../common/client/host/HostClient";
import PlayerClient from "../../../../common/client/player/PlayerClient";
import { usePlayerStore } from "../../usePlayerStore";
import PlayerStatusText from "../PlayerStatusText";
import classes from "./PartyMemberView.module.css";
import Button from "../../../../common/ui/button/Button";

const PartyMemberView = () => {
  const hostState = usePlayerStore((state) => state.hostState);
  const player = usePlayerStore((state) => state.player);
  if (!player) {
    throw new Error("player expected");
  }
  return (
    <div className={classes.container}>
      <PlayerStatusText>
        {hostState?.status === HOST_STATUS.PLAYING ? "JOINING THE NEXT GAME" : "WAITING FOR PARTY LEADER"}
      </PlayerStatusText>
      <div style={{ flex: 1, paddingBottom: "26px" }}>
        <PlayerAvatar player={player} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            PlayerClient.requestPartyLeaderSwitch();
          }}
        >
          Take Party Lead
        </Button>
      </div>
    </div>
  );
};

export default PartyMemberView;
