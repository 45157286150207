import { PlayerColor } from "@shared/src/gc/types";
import blueBat from "../../../assets/avatars/blue-bat.svg";
import redDog from "../../../assets/avatars/red-dog.svg";
import greenCat from "../../../assets/avatars/green-cat.svg";
import pinkRabbit from "../../../assets/avatars/pink-rabbit.svg";
import cyanBear from "../../../assets/avatars/cyan-bear.svg";
import purpleFish from "../../../assets/avatars/purple-fish.svg";
import yellowFaun from "../../../assets/avatars/yellow-faun.svg";
import brownCrow from "../../../assets/avatars/brown-crow.svg";

export const AVATAR_BY_PLAYER_COLOR: Record<PlayerColor, string> = {
  white: "",
  blue: blueBat,
  red: redDog,
  green: greenCat,
  pink: pinkRabbit,
  cyan: cyanBear,
  purple: purpleFish,
  yellow: yellowFaun,
  brown: brownCrow,
} as const;
