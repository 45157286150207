import React from "react";

interface Props {
  className?: string;
  label: React.ReactNode;
  value: React.ReactNode;
  isChild?: boolean;
}

const DetailsBox = ({ className, label, value, isChild = true }: Props) => (
  <div
    className={[
      className,
      `flex w-[104px] h-[104px] flex-col items-center justify-center border-solid border-neutral-50 ${isChild ? "border-r-2" : "border-2"}`,
    ]
      .filter(Boolean)
      .join(" ")}
  >
    <span className="block text-[46px] font-extrabold leading-[46px]">{value}</span>
    <span className="block text-[20px] font-semibold uppercase leading-[23px]">{label}</span>
  </div>
);

export default DetailsBox;
