import { useEffect, useState } from "react";
import { GAME_UNPAUSE_DELAY_SECONDS } from "../../../common/client/host/constants";
import useCountdown from "../../../common/hook/useCountdown";
import { playSound, SOUNDS } from "../../sounds/ui/sounds";

const STATE = {
  PAUSED: "paused",
  RESUMING: "resuming",
  UNPAUSED: "unpaused",
} as const;

type State = (typeof STATE)[keyof typeof STATE];

type Props = {
  isPaused: boolean;
};

const PauseHud = ({ isPaused }: Props) => {
  const [pauseState, setPauseState] = useState<State>(isPaused ? STATE.PAUSED : STATE.UNPAUSED);

  useEffect(() => {
    if (!isPaused && pauseState === STATE.PAUSED) {
      setPauseState(STATE.RESUMING);
    }

    if (isPaused && pauseState === STATE.UNPAUSED) {
      setPauseState(STATE.PAUSED);
    }
  }, [isPaused, pauseState]);

  const enableCountDown = pauseState === STATE.RESUMING && !isPaused;
  const countdownSeconds = useCountdown(
    GAME_UNPAUSE_DELAY_SECONDS,
    () => {
      setPauseState(STATE.UNPAUSED);
    },
    enableCountDown,
    `${isPaused ? 0 : 1} ${pauseState}`,
  );

  useEffect(() => {
    if (!enableCountDown) return;
    if (countdownSeconds <= 0) return;
    playSound(SOUNDS.COUNTDOWN_TICK, { throttleMs: 50 });
  }, [enableCountDown, countdownSeconds]);

  if (pauseState === STATE.UNPAUSED) {
    return null;
  }

  return (
    <div
      id="pause-overlay"
      className={`pointer-events-none absolute inset-0 z-hostPauseOverlay flex items-center justify-center bg-black transition-all duration-3000 ${isPaused ? "bg-opacity-50" : "bg-opacity-0"}`}
    >
      <div
        key={countdownSeconds}
        className={`text-6xl font-bold uppercase text-white ${isPaused ? "animate-pulse text-6xl" : "animate-ping text-9xl"}`}
      >
        {isPaused ? "Paused" : countdownSeconds}
      </div>
    </div>
  );
};

export default PauseHud;
