import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { IS_DEV } from "../../constants";

const useDevAutoConnect = (onConnect: () => void) => {
  const [params] = useSearchParams();
  useEffect(() => {
    if (!IS_DEV || !params.has("autoconnect")) {
      return;
    }

    const timeout = setTimeout(() => {
      console.debug("DEV AUTO CONNECT");
      onConnect();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [onConnect, params]);
};

export default useDevAutoConnect;
