import { useHostStore } from "./useHostStore";
import classes from "./HostStats.module.css";

const HostStats = () => {
  const playerCount = useHostStore((state) => state.playerCount);
  const connectionCount = useHostStore((state) => state.connectionCount);

  return (
    <div className={classes.container}>
      <div className={classes.stats}>
        Players: {playerCount} - Connections: {connectionCount}
      </div>
    </div>
  );
};

export default HostStats;
