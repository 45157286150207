export type ListenerSignature<L> = {
  [E in keyof L]: (playerId: number, data: any) => any;
};

export type DefaultListener = {
  [k: string]: (playerId: number, data: any) => any;
};

class ClientEventEmitter<T extends ListenerSignature<T>> {
  events = {} as Record<keyof T, Record<number, T[keyof T]>>;
  eventIdCounter = 0;

  on<K extends keyof T>(eventName: K, handler: T[K], context?: object) {
    if (context) {
      handler.bind(context);
    }

    const eventId = ++this.eventIdCounter;

    this.events[eventName] = this.events[eventName] || {};
    this.events[eventName][eventId] = this.events[eventName][eventId] || {};
    this.events[eventName][eventId] = handler;

    return eventId;
  }

  off(eventId: number) {
    for (const eventName in this.events) {
      if (this.events[eventName][eventId]) {
        delete this.events[eventName][eventId];
        return;
      }
    }

    console.warn(`Event by id ${eventId} not found.`);
  }

  dispatch<K extends keyof T>(
    playerId: number,
    eventName: K,
    data?: Parameters<T[K]>[1]
  ) {
    if (this.events[eventName]) {
      for (const eventId in this.events[eventName]) {
        this.events[eventName][eventId](playerId, data);
      }
    }
  }
}

export default ClientEventEmitter;
