export function splitStringIntoParts(string: string, partLength: number): string[] {
  const parts: string[] = [];

  while (string.length > 0) {
    parts.push(string.substring(0, partLength));
    string = string.substring(partLength);
  }

  return parts;
}
