import Client, { HOST_STATUS } from "../../common/client/host/HostClient";
import useHideCursorOnIdle from "../../common/hook/useHideCursorOnIdle";
import useWakeLock from "../../common/hook/useWakeLock";
import ErrorNotifications from "../ErrorNotifications";
import { IS_DEV } from "../constants";
import FixedAspectRatioScale from "./FixedAspectRatioContainer";
import FixedAspectRatioResize from "./FixedAspectRatioResize";
import HostProvider from "./HostProvider";
import HostStats from "./HostStats";
import HostView from "./HostView";
import { SCREEN_ASPECT_RATIO, SCREEN_HEIGHT, SCREEN_WIDTH } from "./constants";
import Notifications from "./notification/Notifications";
import RadioProvider from "./radio/RadioProvider";
import SpatialNavigation from "./spatialNavigation/SpatialNavigation";
import TipOverlay from "./tip/TipOverlay";
import { useHostStore } from "./useHostStore";

const HostInitPeerServerConnection = ({ children }: React.PropsWithChildren) => {
  const hostState = useHostStore((state) => state.hostState);

  if (hostState.status === HOST_STATUS.CONNECTING_TO_PEER_SERVER) {
    return <div>Connecting to peer server</div>;
  }

  if (hostState.status === HOST_STATUS.CONNECTING_TO_PEER_SERVER_FAILED) {
    return <div>Could not connect to peer server.</div>;
  }

  return children;
};

const HostClient = () => {
  useWakeLock();
  useHideCursorOnIdle();

  if (!IS_DEV) {
    window.onbeforeunload = function () {
      return "Are you sure you want to leave?";
    };
  }

  return (
    <RadioProvider isEnabled={false}>
      <FixedAspectRatioScale width={SCREEN_WIDTH} height={SCREEN_HEIGHT}>
        <div id="host-client" className="theme-host flex h-full w-full flex-col overflow-hidden">
          <HostProvider>
            <SpatialNavigation>
              <HostInitPeerServerConnection>
                <TipOverlay />
                <Notifications />
                <HostView />
                <HostStats />
                <ErrorNotifications client={Client} />
              </HostInitPeerServerConnection>
            </SpatialNavigation>
          </HostProvider>
        </div>
      </FixedAspectRatioScale>
      <div className="theme-host pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-0 flex items-center justify-center">
        <FixedAspectRatioResize ratio={SCREEN_ASPECT_RATIO}>
          <div id="host-frame-container" className="h-full w-full"></div>
        </FixedAspectRatioResize>
      </div>
    </RadioProvider>
  );
};

export default HostClient;
