import { playSound, SOUNDS } from "../../../sounds/ui/sounds";
import useFocusableWithAction from "../../spatialNavigation/useFocusableWithAction";

interface Props extends React.HTMLProps<HTMLDivElement> {
  onActionEnter: () => void;
  onFocus?: () => void;
  focusKey?: string;
}

const StartButton = ({ onActionEnter, onFocus, focusKey }: Props) => {
  const { ref, focused, action } = useFocusableWithAction({
    focusKey,
    forceFocus: true,
    onActionEnter,
    onFocus: () => {
      playSound(SOUNDS.BUTTON_FOCUS, { throttleMs: 50 });

      onFocus && onFocus();
    },
  });
  return (
    <button
      ref={ref}
      className={`relative h-[134px] rounded-2xl ${focused ? "bg-blue-400" : "bg-blue-900"} pressed:border-b pressed:border-l-[3px] pressed:border-r pressed:border-t-[3px] pressed:bg-violet-700 box-border rounded border-b-[3px] border-l border-r-[3px] border-t border-solid border-b-violet-700 border-l-black border-r-violet-700 border-t-black p-2.5 text-center font-semibold text-white [text-shadow:_0_0_10px_gray] focus:outline-none active:border-b active:border-l-[3px] active:border-r active:border-t-[3px] active:bg-violet-700 disabled:border-[gray] disabled:border-r-[gray] disabled:bg-[#3a3a3a] disabled:text-[#a0a0a0]`}
      onClick={action}
    >
      <span className="absolute left-[90px] top-[16px]">
        <span className="absolute text-[28px] font-bold">Let’s</span>
        <span className="absolute top-[10px] text-[70px] font-bold">GO</span>
        <span className="absolute left-[70px] top-[-35px] text-[112px] font-bold">!</span>
      </span>
    </button>
  );
};

export default StartButton;
