import PlaylistBlueprint from "../../../common/client/plugins/playlist/PlaylistBlueprint";
import DetailsBox from "./DetailsBox";

interface Props {
  playlistBlueprint: PlaylistBlueprint;
}

const DetailsBoxes = ({ playlistBlueprint }: Props) => (
  <div className="flex border-2 border-r-0 border-solid border-neutral-50">
    <DetailsBox label="Players" value={`${playlistBlueprint.getMinPlayers()}-${playlistBlueprint.getMaxPlayers()}`} />
    <DetailsBox label="Games" value={playlistBlueprint.getGameEntryIds().length} />
    <DetailsBox label="Mins" value="20" />
  </div>
);

export default DetailsBoxes;
