import { useEffect, useRef } from "react";

const useFixedAspectRatioResize = (ref: React.RefObject<HTMLDivElement>, ratio: number) => {
  useEffect(() => {
    const handleResize = () => {
      if (!ref.current) return;

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      let width: number;
      let height: number;
      if (screenWidth / screenHeight > ratio) {
        width = screenHeight * ratio;
        height = screenHeight;
      } else {
        width = screenWidth;
        height = screenWidth * (1 / ratio);
      }

      ref.current.style.width = `${width}px`;
      ref.current.style.height = `${height}px`;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ratio, ref]);
};

const FixedAspectRatioResize = ({ children, ratio = 16 / 9 }: React.PropsWithChildren<{ ratio?: number }>) => {
  const ref = useRef<HTMLDivElement>(null);
  useFixedAspectRatioResize(ref, ratio);

  return <div ref={ref}>{children}</div>;
};

export default FixedAspectRatioResize;
