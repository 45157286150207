import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { Peer } from "peerjs";
import { assert } from "../logger/assert";

export const PEER_STATUS = {
  NONE: "none",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  RECONNECTING: "reconnecting",
  RECONNECTING_FAILED: "reconnecting_failed",
  DISCONNECTED: "disconnected",
  CLOSED: "closed",
} as const;

export type PeerStatus = (typeof PEER_STATUS)[keyof typeof PEER_STATUS];

export interface PeerManagerEvents extends DefaultListener {
  peer_created: (peer: Peer) => void;
  peer_status_change: (data: { status: PeerStatus }) => void;
  peer_error: (error: unknown) => void;
}

class PeerManager<T extends PeerManagerEvents> extends EventEmitter<T> {
  protected peer: Peer | null = null;
  private peerId: string | null = null;
  private peerStatus: PeerStatus = PEER_STATUS.NONE;
  private reconnectTimeoutHandle: ReturnType<typeof setTimeout> | null = null;
  private reconnectAttempts = 0;

  constructor() {
    super();
  }

  protected debug(...args: unknown[]) {
    console.debug("PeerManager -", ...args);
  }

  public setup() {
    this.debug("setup()");
  }

  private setPeerStatus(status: PeerStatus) {
    this.debug(`setPeerStatus() - status: ${this.peerStatus} -> ${status}`);

    this.peerStatus = status;
    this.dispatch("peer_status_change", { status });
  }

  private tryReconnect() {
    this.debug("Trying to reconnect...");

    if (this.peerStatus !== PEER_STATUS.RECONNECTING) {
      this.setPeerStatus(PEER_STATUS.RECONNECTING);
    }

    let timeout;

    if (this.reconnectAttempts < 5) {
      timeout = 5000;
    } else if (this.reconnectAttempts < 10) {
      timeout = 10000;
    } else if (this.reconnectAttempts < 20) {
      timeout = 30000;
    } else if (this.reconnectAttempts < 30) {
      timeout = 60000;
    } else if (this.reconnectAttempts < 60) {
      this.giveUpReconnecting();
      return;
    }

    this.reconnectTimeoutHandle = setTimeout(() => {
      assert(this.peer, "peer expected");
      this.peer.reconnect();
    }, timeout);

    this.reconnectAttempts++;
  }

  private giveUpReconnecting() {
    this.debug("Giving up reconnecting!");

    // TODO: This would require manual reconnect attempt from the user...
    this.setPeerStatus(PEER_STATUS.RECONNECTING_FAILED);
  }

  createNewPeer(peerId: string) {
    this.debug("Create peer with ID: " + peerId);

    if (this.reconnectTimeoutHandle) {
      clearTimeout(this.reconnectTimeoutHandle);
    }

    const getTurnServer = () => {
      if (
        import.meta.env.VITE_PEER_SERVER_TURN_URL !== "" &&
        import.meta.env.VITE_PEER_SERVER_TURN_USERNAME !== "" &&
        import.meta.env.VITE_PEER_SERVER_TURN_PASSWORD !== ""
      ) {
        return {
          url: import.meta.env.VITE_PEER_SERVER_TURN_URL,
          username: import.meta.env.VITE_PEER_SERVER_TURN_USERNAME,
          credential: import.meta.env.VITE_PEER_SERVER_TURN_PASSWORD,
        };
      }

      return null;
    };

    const peerOptions = {
      host: import.meta.env.VITE_PEER_SERVER_HOST,
      port: import.meta.env.VITE_PEER_SERVER_PORT,
      path: import.meta.env.VITE_PEER_SERVER_PATH,
      secure: import.meta.env.VITE_PEER_SERVER_SECURE === "true",
      debug: Number(import.meta.env.VITE_PEERJS_DEBUG),
      config: {
        iceServers: [{ url: import.meta.env.VITE_PEER_SERVER_STUN_URL }, getTurnServer()].filter(Boolean),
      },
    };

    // Register with the peer server (signaling server)
    const peer = new Peer(peerId, peerOptions);
    this.peerId = peerId; // store peerId as PeerJS might hide it during network error, but we still want to show it in the UI etc.

    this.setPeerStatus(PEER_STATUS.CONNECTING);

    peer.on("open", () => {
      this.debug("peer open");

      this.reconnectAttempts = 0;
      this.setPeerStatus(PEER_STATUS.CONNECTED);
    });

    peer.on("close", () => {
      this.debug("peer close");

      this.setPeerStatus(PEER_STATUS.CLOSED);
    });

    peer.on("error", (error) => {
      console.error("Peer error", error);

      switch (error.type) {
        case "network": // handle "no connection to server"
          if (this.peerStatus === PEER_STATUS.CONNECTED || this.peerStatus === PEER_STATUS.RECONNECTING) {
            this.tryReconnect();
            return;
          } else {
            this.setPeerStatus(PEER_STATUS.DISCONNECTED);
          }
          break;
      }

      this.dispatch("peer_error", error);
    });

    this.peer = peer;

    this.dispatch("peer_created", peer);
  }

  onPeerCreated(callback: (peer: Peer) => void) {
    this.on("peer_created", callback);
  }

  onPeerStatusChange(callback: (data: { status: PeerStatus }) => void) {
    return this.on("peer_status_change", callback);
  }

  onPeerError(callback: (error: unknown) => void): void {
    this.on("peer_error", (error) => {
      callback(error);
    });
  }

  getPeer(): Peer {
    if (!this.peer) {
      throw new Error("Peer not initialized");
    }
    return this.peer;
  }

  getPeerId(): string {
    return this.getPeer().id || this.peerId || "";
  }

  getPeerStatus(): PeerStatus {
    return this.peerStatus;
  }
}

export default PeerManager;
