import { useParams } from "react-router-dom";
import GameEntry from "../../../../common/client/GameEntry";
import PlaylistBlueprint from "../../../../common/client/plugins/playlist/PlaylistBlueprint";
import { PlaylistBlueprintData } from "../../../../common/client/plugins/playlist/types";
import { assert } from "../../../../common/logger/assert";
import PlaylistPage from "../../playlist/page/PlaylistPage";

const GameStart = () => {
  const { gameEntryId } = useParams();
  assert(gameEntryId, "gameEntryId not found");

  const gameEntryData = GameEntry.getGameEntryDataById(gameEntryId);
  assert(gameEntryData, "GameEntryData not found");

  const playlistBlueprintData = {
    id: "one_game_playlist",
    name: "One Game On Loop",
    descriptionShort: "Play the selected game on loop.",
    descriptionLong: "Play the selected game on loop.",
    gameEntryIds: [gameEntryData.id],
    numberOfRounds: 5,
  } as PlaylistBlueprintData;
  const playlistBlueprint = new PlaylistBlueprint(playlistBlueprintData);

  return <PlaylistPage playlistBlueprint={playlistBlueprint} />;
};

export default GameStart;
