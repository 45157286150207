import { useEffect, useState } from "react";
import GameEntry from "../../../common/client/GameEntry";
import PlaylistBlueprint from "../../../common/client/plugins/playlist/PlaylistBlueprint";
import { assert } from "../../../common/logger/assert";

const useGameEntryCarousel = ({
  enabled,
  playlistBlueprint,
  intervalMs,
}: {
  enabled: boolean;
  playlistBlueprint: PlaylistBlueprint;
  intervalMs: number;
}) => {
  const [gameEntryIndex, setGameEntryIndex] = useState(0);

  const gameEntryId = playlistBlueprint.getGameEntryIds()[gameEntryIndex];
  assert(gameEntryId, "expected gameEntryId");
  const gameEntry = GameEntry.getGameEntryById(gameEntryId);
  assert(gameEntry, "GameEntry not found");

  useEffect(
    function loopGameEntries() {
      if (!enabled) return;
      const interval = setInterval(() => {
        setGameEntryIndex((prev) => (prev + 1) % playlistBlueprint.getGameEntryIds().length);
      }, intervalMs);

      return () => {
        clearInterval(interval);
      };
    },
    [playlistBlueprint, enabled, intervalMs],
  );

  return gameEntry;
};

export default useGameEntryCarousel;
