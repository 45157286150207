import { PlaylistBlueprintData } from "../../../common/client/plugins/playlist/types";
import PlaylistItem from "./PlaylistItem";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
  playlistBlueprints: PlaylistBlueprintData[];
  onSelect: (playlistBlueprint: PlaylistBlueprintData) => void;
}

const PlaylistItems = ({ playlistBlueprints, onSelect }: Props) => {
  return (
    <div className="relative flex h-full w-full overflow-hidden">
      <Swiper
        className="absolute bottom-0 left-[155px] right-[155px] top-0"
        allowTouchMove={false}
        slidesPerView="auto"
        centeredSlides
        spaceBetween={50}
        direction="vertical"
      >
        {playlistBlueprints.map((blueprint, i) => {
          return (
            <SwiperSlide key={blueprint.id}>
              <PlaylistItem
                swiperIndex={i}
                playlistBlueprintData={blueprint}
                forceFocus={i === 0}
                onSelect={() => {
                  onSelect(blueprint);
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default PlaylistItems;
