type ActionHandlers = {
  onActionEnter?: () => void;
  onActionBack?: () => boolean;
  onActionDirection?: (direction: string) => boolean;
};

const actionRegistry = () => {
  const actions = new Map<
    string,
    {
      onActionEnter?: () => void;
      onActionBack?: () => boolean;
      onActionDirection?: (direction: string) => boolean;
    }
  >();

  const register = (focusKey: string, actionHandlers: ActionHandlers) => {
    actions.set(focusKey, actionHandlers);
  };

  const unregister = (focusKey: string) => {
    actions.delete(focusKey);
  };

  return {
    register,
    unregister,
    getActionHandlers: (focusKey: string) => actions.get(focusKey),
  };
};

export default actionRegistry();
